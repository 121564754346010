import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../styles/Buttons';
import LoadingIndicator from '../LoadingIndicator';

const ButtonWithLoader = ({ children, isLoading, ...rest }) => {
  return (
    <Button {...rest}>{isLoading ? <LoadingIndicator /> : children}</Button>
  );
};

ButtonWithLoader.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ButtonWithLoader;
