import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Utils
import { updateUser } from '../../../actions';

// Components
import Input from '../../utils/Input';
import Modal from '../../Modal';
import ModalContent from '../../styles/ModalContent';

// Styled Components
import Divider from '../../styles/Divider';

// *********************************************** //

const UpdateSiteTitleModal = ({
  afterSave,
  cancelFunc,
  open,
  updateSubtitle,
  user,
}) => {
  const [subtitle, setSubtitle] = useState(user.tagline);

  const onClickSaveChanges = () => {
    updateSubtitle(subtitle);
    afterSave();
  };

  return (
    <Modal cancelFunc={cancelFunc} ctaFunc={onClickSaveChanges} open={open}>
      <h1 className="modal-title">Update Your Subtitle</h1>
      <Divider />
      <ModalContent>
        <Input
          onChange={e => setSubtitle(e.target.value)}
          placeholder="Pursuing wellness. Chasing freedom. Building community."
          fieldType="text"
          value={subtitle}
        />
      </ModalContent>
    </Modal>
  );
};

UpdateSiteTitleModal.defaultProps = {
  afterSave: null,
};

UpdateSiteTitleModal.propTypes = {
  afterSave: PropTypes.func,
  cancelFunc: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updateSubtitle: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = dispatch => ({
  updateSubtitle: subtitle => dispatch(updateUser({ tagline: subtitle })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateSiteTitleModal);
