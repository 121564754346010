import React from 'react';
import { CheckSquare } from 'react-feather';
import map from 'lodash/map';
import PropTypes from 'prop-types';

// Styled Components
import { FlexWrapper, Feature, Icon } from './styles';
import theme from '../styles/themes';

const { colors } = theme;

// *********************************************** //

const ProFeatureLinks = ({ singleColumnOnMobile }) => {
  // TODO - get these from somewhere else
  const features = [
    {
      title: 'Professional Website',
    },
    {
      title: 'PRO Courses & Resources',
    },
    {
      title: 'Contact Management',
    },
    {
      title: 'Automated Tasks',
    },
    {
      title: 'Social Media Photo Packs',
    },
    {
      title: 'Content Sharing',
    },
    {
      title: 'Statistics & Leaderboard',
    },
    {
      title: 'In / Out of Stock Notifications',
    },
  ];

  return (
    <FlexWrapper singleColumnOnMobile={singleColumnOnMobile}>
      {map(features, feature => (
        <Feature
          singleColumnOnMobile={singleColumnOnMobile}
          key={feature.title}
        >
          <Icon>
            <CheckSquare color={colors.plum} />
          </Icon>
          <div className="text">{feature.title}</div>
        </Feature>
      ))}
      <div className="learn-more-link">
        <a
          href="https://help.lifestepsapp.com/content/what-do-i-get-with-pro"
          target="_blank"
          rel="noreferrer noopener"
        >
          Learn more about these PRO Features
        </a>
      </div>
    </FlexWrapper>
  );
};

ProFeatureLinks.propTypes = {
  singleColumnOnMobile: PropTypes.bool,
};

ProFeatureLinks.defaultProps = {
  singleColumnOnMobile: false,
};

export default ProFeatureLinks;
