import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

// Utils
import { updateUser } from '../../../actions';

// Components
import Modal from '../../Modal';
import RenderInput from '../../utils/RenderInput';

// Styled Components
import Divider from '../../styles/Divider';
import theme from '../../styles/themes';

// *********************************************** //

const UpdateProfileModal = ({
  afterSave,
  cancelFunc,
  open,
  triggerUserUpdate,
  user,
}) => {
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);

  const onClickSaveChanges = () => {
    triggerUserUpdate({
      firstName,
      lastName,
      email,
      phone,
    });
    afterSave();
  };

  return (
    <ModalContainer>
      <Modal
        padding="2rem"
        cancelFunc={cancelFunc}
        ctaFunc={onClickSaveChanges}
        open={open}
      >
        <h1 className="modal-title">Update Account Information</h1>
        <Divider />
        <FormContent>
          <div className="row">
            <div className="col-sm-12 col-md-6 pl-0">
              {RenderInput({
                fieldType: 'text',
                labelText: 'First Name',
                name: 'firstName',
                onChange: e => setFirstName(e.target.value),
                placeholder: 'Jane',
                value: firstName,
              })}
            </div>
            <div className="col-sm-12 col-md-6 pr-0">
              {RenderInput({
                fieldType: 'text',
                labelText: 'Last Name',
                name: 'lastName',
                onChange: e => setLastName(e.target.value),
                placeholder: 'Smith',
                value: lastName,
              })}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 px-0">
              {RenderInput({
                fieldType: 'email',
                labelText: 'Email Address',
                name: 'email',
                onChange: e => setEmail(e.target.value),
                placeholder: 'jane.smith@gmail.com',
                value: email,
              })}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 px-0">
              {RenderInput({
                fieldType: 'tel',
                labelText: 'Phone Number',
                name: 'phone',
                onChange: e => setPhone(e.target.value),
                placeholder: 'xxx-xxx-xxxx',
                value: phone,
              })}
            </div>
          </div>
        </FormContent>
      </Modal>
    </ModalContainer>
  );
};

UpdateProfileModal.defaultProps = {
  afterSave: null,
  user: {},
};

UpdateProfileModal.propTypes = {
  afterSave: PropTypes.func,
  cancelFunc: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  triggerUserUpdate: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  triggerUserUpdate: body => dispatch(updateUser(body)),
});

export default connect(null, mapDispatchToProps)(UpdateProfileModal);

// Styles
const { breakpoints, fonts, colors } = theme;

const ModalContainer = styled.div`
  .actions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;

    button:first-of-type {
      margin-bottom: 0.5rem;
    }
  }
`;

const FormContent = styled.div`
  padding: 0 0 1.5rem;

  .row {
    margin: 0;
    margin-top: 0.75rem;

    @media (max-width: ${breakpoints.mobile}) {
      margin: 0;
    }
  }

  .form-control {
    display: flex;
    text-align: left;
    flex-direction: column;

    label {
      margin: 0.5rem 0;
      padding: 0;
      color: ${colors.text.darkGray};
      font-weight: ${fonts.weight.regular};
      font-size: ${fonts.size.xs};
      line-height: 1.4;
      text-transform: uppercase;
    }

    input {
      padding: 1rem;
      font-size: ${fonts.size.s};
      border: 1px solid ${colors.darkerDividerGray};
      border-radius: 4px;
    }
  }
`;
