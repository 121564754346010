import React, { useState } from 'react';
import { AlertCircle } from 'react-feather';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

// Assets
import giftSubTitle from '../../../images/sub-gift-sub.svg';

// Utils
import LifeSteps from '../../../modules/LifeStepsAPI';

// Components
import Input from '../../utils/Input';
import LoadingIndicator from '../../utils/LoadingIndicator';
import SubscriptionModal from '../SubscriptionModal';

// Styles
import { Button, ModalCancelButton } from '../../styles/Buttons';
import {
  ButtonWrapper,
  Instructions,
  LightPlumBox,
} from '../SubscriptionModal/styles';
import Error from '../../styles/Error';
import { GiftRecipientStyles } from './styles';

// *********************************************** //

function FindMemberModal({
  giftRecipient,
  goToNextStep,
  handleClose,
  setGiftRecipient,
  user,
}) {
  const [inputValue, setInputValue] = useState('');
  const [giftRecipientError, setGiftRecipientError] = useState(null);
  const [loading, setLoading] = useState(false);

  function updateValue({ target: { value } }) {
    setInputValue(value);
  }

  async function handleClickFindMember() {
    // 1. Show loading indicator
    setLoading(true);
    // 2. Instantiate a new LifeSteps class
    const lsapi = new LifeSteps();

    try {
      // 3. Fetch the gift recipient
      const { data } = await lsapi.get({
        url: `${process.env.REACT_APP_API_URL_V3}/members/${inputValue}`,
        headers: {
          'Content-Type': 'application/json',
          'Authentication-Token': user.authToken,
        },
      });

      setGiftRecipient(data);
    } catch (error) {
      setGiftRecipientError(error.response.data.message);
    } finally {
      // 4. In either case, hide the loading indicator
      setLoading(false);
    }
  }

  function onHitEnter(e) {
    if (e.key === 'Enter') {
      handleClickFindMember();
    }
  }

  function clearRecipient() {
    setInputValue('');
    setGiftRecipient(null);
  }

  return (
    <SubscriptionModal title="Gift Subscription" titleImageSrc={giftSubTitle}>
      {giftRecipient ? (
        <>
          <LightPlumBox>
            <GiftRecipientStyles data-testid="gift-recipient">
              <p>You are starting a new gift subscription for</p>
              <h1 data-testid="gift-recipient-name">{giftRecipient.name}</h1>
              <div
                className="member-id"
                data-testid="gift-recipient-member-id"
              >{`Member No. ${giftRecipient.memberId}`}</div>
            </GiftRecipientStyles>
          </LightPlumBox>
          <ButtonWrapper>
            <Button className="pro" onClick={goToNextStep}>
              Continue
            </Button>
            <ModalCancelButton onClick={clearRecipient}>
              Go Back
            </ModalCancelButton>
          </ButtonWrapper>
        </>
      ) : (
        <>
          <Instructions>
            <p>
              You can purchase a gift subscription for any member in your
              downline by searching for them by their Young Living Member
              Number.
            </p>
          </Instructions>
          <div>
            {giftRecipientError && (
              <Error style={{ paddingTop: 0 }}>
                <AlertCircle size={20} />
                {giftRecipientError}
              </Error>
            )}
            <Input
              fieldType="tel"
              labelText="Member No."
              onChange={updateValue}
              placeholder="Enter a Member Number"
              value={inputValue}
              withLabel
              onKeyDown={onHitEnter}
            />
            <ButtonWrapper style={{ paddingTop: '2rem' }}>
              <Button
                style={{ width: 200 }}
                onClick={handleClickFindMember}
                disabled={isEmpty(inputValue)}
                className="pro"
              >
                {loading ? <LoadingIndicator /> : 'Find Member'}
              </Button>
              <ModalCancelButton style={{ width: 200 }} onClick={handleClose}>
                Cancel
              </ModalCancelButton>
            </ButtonWrapper>
          </div>
        </>
      )}
    </SubscriptionModal>
  );
}

FindMemberModal.propTypes = {
  giftRecipient: PropTypes.object,
  goToNextStep: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setGiftRecipient: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

function mapStateToProps({ user }) {
  return {
    user,
  };
}

export default connect(mapStateToProps)(FindMemberModal);
