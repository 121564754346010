import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '../styles/Buttons';
import theme from '../styles/themes';

// Images
import phone from '../../images/phone.png';
import leaves from '../../images/leaves.svg';

const UpgradeAd = ({ handleUpgrade }) => (
  <Wrapper>
    <div className="container">
      <h1>Upgrade to Pro</h1>
      <p>Get all the tools you need with a Pro Account.</p>
      <div className="button-container">
        <Button className="white" onClick={handleUpgrade}>
          Upgrade Now
        </Button>
      </div>
    </div>
    <img src={leaves} alt="logo" className="leaves" />
    <img src={phone} alt="phone" className="phone-mock" />
  </Wrapper>
);

UpgradeAd.propTypes = {
  handleUpgrade: PropTypes.func.isRequired,
};

export default UpgradeAd;

// Styles
const { breakpoints, colors } = theme;

const Wrapper = styled.div`
  position: relative;
  justify-content: center;
  padding: 2rem;
  background: ${colors.plum};
  color: ${colors.white};
  box-shadow: 0 4px 30px -15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border-radius: 10px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 1;

    h1 {
      text-align: center;
      font-size: 2.5rem;
      font-weight: 900;
      line-height: 1em;
      letter-spacing: 1px;
    }

    p {
      color: ${colors.white};
    }
  }

  img.phone-mock {
    position: absolute;
    top: 30%;
    left: 60%;
    max-width: 380px;
  }

  img.leaves {
    position: absolute;
    top: -40%;
    right: 65%;
  }

  @media (max-width: ${breakpoints.tablet}) {
    img.phone-mock {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 2rem;

    .container {
      padding: 0;

      p {
        margin: 0 auto 1rem;
        max-width: 200px;
        font-size: 1rem;
      }
    }

    img.leaves {
      opacity: 0.8;
    }
  }
`;
