import { combineReducers } from 'redux';
import userReducer from './user';
import subscriptionsReducer from './subscriptions';

const rootReducer = combineReducers({
  user: userReducer,
  subscriptions: subscriptionsReducer,
});

export default rootReducer;
