import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { QueryClient, QueryClientProvider } from 'react-query';

// Utils
import { MainContextProvider } from './MainContext';
import ScrollToTop from './components/utils/ScrollToTop';

// Components
// import MaintenancePage from './containers/MaintenancePage';
import MemberDashboard from './containers/MemberDashboard';
import PrivateRoute from './components/utils/PrivateRoute';
import SignInContainer from './containers/SignIn';

// Styled Components
import theme from './components/styles/themes';

const { GlobalStyle } = theme;

// Create a query client
const queryClient = new QueryClient();

const App = () => {
  const { location } = useHistory();

  // Google Analytics
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  }, []);

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        <MainContextProvider>
          <QueryClientProvider client={queryClient}>
            <GlobalStyle />
            <Switch>
              {/* <Route path="/">
                <MaintenancePage />
              </Route> */}
              <Route path="/signin">
                <SignInContainer />
              </Route>
              <PrivateRoute path="/">
                <MemberDashboard />
              </PrivateRoute>
            </Switch>
          </QueryClientProvider>
        </MainContextProvider>
      </ThemeProvider>
    </ScrollToTop>
  );
};

export default App;
