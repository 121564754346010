import styled from 'styled-components/macro';
import theme from '../themes';

const { colors } = theme;

export const BaseButton = styled.button`
  background: none;
  color: ${colors.darkGreen};
  border: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:visited,
  &:link,
  &:active {
    outline: none;
  }
`;

// Main Action Button (Can have icon after text)
export const ResourceActionButton = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  background: ${colors.darkGreen};
  color: ${colors.white};
  border-radius: 10px;

  img,
  svg {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
  }

  &.inverse {
    background: transparent;
    color: ${colors.darkGreen};
    border: 2px solid ${colors.green};
  }

  &:active {
    opacity: 0.7;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const Button = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width || null};
  padding: 0.6rem 2rem 0.75rem;
  height: 48px;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  background: ${props =>
    props.danger ? colors.roles.danger : colors.darkGreen};
  color: ${colors.white};
  border: none;
  border-radius: 10px;
  outline: 0;

  &.inverse {
    font-weight: 600;
    background: ${colors.white};
    color: ${props => (props.danger ? colors.roles.danger : colors.darkGreen)};
    border: 2px solid
      ${props => (props.danger ? colors.roles.danger : colors.darkGreen)};
  }

  &.white {
    background: ${colors.white};
    color: ${colors.black};
    box-shadow: 0 2px 20px -4px rgba(0, 0, 0, 0.15);

    &:hover {
      opacity: 0.9;
    }
  }

  &.pro {
    background: ${colors.plum};
    color: ${colors.white};
  }

  &.inverse-pro {
    font-weight: 600;
    background: ${colors.white};
    color: ${props => (props.danger ? colors.roles.danger : colors.plum)};
    border: 2px solid
      ${props => (props.danger ? colors.roles.danger : colors.plum)};
  }

  svg {
    margin-left: 0.5rem;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &:active {
    opacity: 0.9;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const TextButton = styled.button`
  margin: 0;
  padding: 0;
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  text-align: left;
  text-decoration: ${props => props.textDecoration || 'underline'};
  text-decoration-color: ${colors.green};
  background: none;
  color: ${colors.green};
  border: none;
  cursor: pointer;
`;

export const DangerTextButton = styled.button`
  padding: 0.6rem 2rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  background: none;
  color: ${colors.roles.danger};
  border: none;
`;

export const ModalCancelButton = styled.button`
  padding: 0.6rem 2rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  background: none;
  color: ${colors.text.gray};
  border: none;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &:active {
    opacity: 0.9;
    outline: none;
  }
`;

export const ImageButton = styled(BaseButton)`
  width: 100%;
  padding: 0;
`;
