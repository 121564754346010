import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { SentryErrorHandler } from './SentryErrorHandler';

class ExampleBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch = (error, errorInfo) => {
    SentryErrorHandler(error, errorInfo);
  };

  render() {
    const { eventId, hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // render fallback UI
      return (
        <button
          onClick={() => Sentry.showReportDialog({ eventId })}
          type="button"
        >
          Report feedback
        </button>
      );
    }

    // when there's not an error, render children untouched
    return children;
  }
}

export default ExampleBoundary;
