import { useQueryClient, useMutation } from 'react-query';

// Utils
import LifeSteps from '../../../modules/LifeStepsAPI';

export default function useUserEvents() {
  // Get API
  const lsapi = new LifeSteps();

  // Get Query Client
  const queryClient = useQueryClient();

  // Create Event
  const createEventMutation = useMutation(body => lsapi.createUserEvent(body), {
    onSuccess: res => {
      queryClient.setQueryData(['event', res.id], res);
      queryClient.invalidateQueries('user events');
    },
  });

  // Update Event
  const updateEventMutation = useMutation(
    ({ id, body }) => lsapi.updateUserEvent(id, body),
    {
      onSuccess: res => {
        queryClient.setQueryData(['event', res.id], res);
        queryClient.invalidateQueries('user events');
      },
    }
  );

  // Delete Event
  const deleteEventMutation = useMutation(id => lsapi.deleteUserEvent(id), {
    onSuccess: res => {
      queryClient.setQueryData(['event', res.id], res);
      queryClient.invalidateQueries('user events');
    },
  });

  return {
    createEventMutation,
    deleteEventMutation,
    updateEventMutation,
  };
}
