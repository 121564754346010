import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import moment from 'moment';
import { Heart } from 'react-feather';
import theme from '../styles/themes';
import { whiteLogo } from '../../images';

const Footer = ({ noLove }) => {
  return (
    <FooterContainer>
      <div className="row">
        <div className="footer-content">
          <img src={whiteLogo} alt="Life Steps" />
          <div className="copyright">
            Copyright &copy; {moment().format('YYYY')} | All Rights Reserved
          </div>
          <div className="links">
            <a
              className="footer-link"
              href="https://www.lifestepsapp.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
            <a
              className="footer-link"
              href="https://www.lifestepsapp.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
      {!noLove && (
        <div className="row">
          <div className="with-love">
            Made with <Heart fill="#CB0B5C" color="#CB0B5C" height="12px" /> By
            Support Stream, Inc.
          </div>
        </div>
      )}
    </FooterContainer>
  );
};

Footer.propTypes = {
  noLove: PropTypes.bool,
};

Footer.defaultProps = {
  noLove: false,
};

export default Footer;

// Styles
const { breakpoints, colors, fonts } = theme;

const FooterContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 1rem;
  font-size: ${fonts.size.s};
  font-weight: ${fonts.weight.regular};
  background-color: ${colors.black};
  color: ${colors.white};
  text-shadow: none;

  .footer-content {
    width: 100%;
    padding: 3rem;
    text-transform: uppercase;
    text-align: center;

    img {
      margin-bottom: 1rem;
    }
  }

  .links {
    padding-top: 1rem;

    a.footer-link {
      padding: 0.5rem;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        text-decoration-color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .with-love {
    display: flex;
    align-items: flex-end;
    margin: 0 0 0 auto;
  }

  @media (max-width: ${breakpoints.mobile}) {
    .footer-content {
      padding: 2rem 1rem 3rem;
    }

    .with-love {
      width: 100%;
      justify-content: center;
    }
  }
`;
