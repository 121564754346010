import styled from 'styled-components';
import theme from '../themes';

// Styles
const { colors, fonts } = theme;

const Error = styled.div`
  font-size: 0.875rem;
  font-weight: ${fonts.weight.regular};
  color: ${colors.roles.danger};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;

  svg {
    fill: ${colors.roles.danger};
    stroke: ${colors.white};
    margin-right: 0.5rem;
  }
`;

export default Error;
