import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import styled from 'styled-components';
import { useLocation } from 'react-router';

// Modules & Utils
import { clearSubscriptionError, fetchSubscriptions } from '../../actions';

// Components
import CancelSubcriptionFlow from '../Flows/CancelSubscriptionFlow';
import CatchModal from '../CatchModal';
import GiftSubscriptionsCard from './GiftSubscriptionsCard';
import NewSubscriptionFlow from '../Flows/NewSubscriptionFlow';
import UpdateBillingCycleFlow from '../Flows/UpdateBillingCycleFlow';
import UpdatePaymentInfoFlow from '../Flows/UpdatePaymentInfoFlow';
import UserSubscriptionCard from './UserSubscriptionCard';

// Styles
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// *********************************************** //

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

const AccountSubscriptions = ({ getSubscriptions, subscriptions }) => {
  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  /**
   * Handle flows
   */
  const [
    isCancelSubscriptionFlowVisible,
    setIsCancelSubscriptionFlowVisible,
  ] = useState(false);

  const [
    isNewSubscriptionFlowVisible,
    setIsNewSubscriptionFlowVisible,
  ] = useState(false);

  const [
    isUpdateBillingCycleFlowVisible,
    setIsUpdateBillingCycleFlowVisible,
  ] = useState(false);

  const [isUpdatePaymentFlowVisible, setIsUpdatePaymentFlowVisible] = useState(
    false
  );

  const showCancelSubscriptionFlow = () => {
    setIsCancelSubscriptionFlowVisible(true);
  };

  const hideCancelSubscriptionFlow = () => {
    setIsCancelSubscriptionFlowVisible(false);
  };

  const showNewSubscriptionFlow = () => {
    setIsNewSubscriptionFlowVisible(true);
  };

  const hideNewSubscriptionFlow = () => {
    setIsNewSubscriptionFlowVisible(false);
  };

  const showUpdatePaymentInfoFlow = () => {
    setIsUpdatePaymentFlowVisible(true);
  };

  const hideUpdatePaymentInfoFlow = () => {
    setIsUpdatePaymentFlowVisible(false);
  };

  const showUpdateBillingCycleFlow = () => {
    setIsUpdateBillingCycleFlowVisible(true);
  };

  const hideUpdateBillingCycleFlow = () => {
    setIsUpdateBillingCycleFlowVisible(false);
  };

  /**
   * Get and handle referrers
   */
  const { search } = useLocation();
  const { referrer } = queryString.parse(search);
  const [isCatchModalVisible, setIsCatchModalVisible] = useState(false);

  useEffect(() => {
    if (referrer) {
      if (referrer === 'new_subscription' || referrer === 'upgrade_modal') {
        showNewSubscriptionFlow();
      } else {
        setIsCatchModalVisible(true);
      }
    }
  }, [referrer]);

  // Call to action function for catch modal
  function switchModals() {
    setIsCatchModalVisible(false);
    showNewSubscriptionFlow();
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [{ cssSrc: 'https://use.typekit.net/wya8swp.css' }],
      }}
    >
      <Wrapper data-testid="account-subscriptions-wrapper">
        <UserSubscriptionCard
          handleShowCancelModal={showCancelSubscriptionFlow}
          handleShowSubscriptionModal={showNewSubscriptionFlow}
          handleShowUpdatePaymentInfo={showUpdatePaymentInfoFlow}
          handleShowUpdateBillingCycle={showUpdateBillingCycleFlow}
          isLoading={subscriptions.isLoading}
        />
        <GiftSubscriptionsCard subscriptions={subscriptions.giftSubs} />
      </Wrapper>
      {referrer && (
        <CatchModal
          referrer={referrer}
          open={isCatchModalVisible}
          ctaFunc={switchModals}
          cancelFunc={() => setIsCatchModalVisible(false)}
        />
      )}
      {isCancelSubscriptionFlowVisible && (
        <CancelSubcriptionFlow handleClose={hideCancelSubscriptionFlow} />
      )}
      {isNewSubscriptionFlowVisible && (
        <NewSubscriptionFlow handleClose={hideNewSubscriptionFlow} />
      )}
      {isUpdateBillingCycleFlowVisible && (
        <UpdateBillingCycleFlow handleClose={hideUpdateBillingCycleFlow} />
      )}
      {isUpdatePaymentFlowVisible && (
        <UpdatePaymentInfoFlow handleClose={hideUpdatePaymentInfoFlow} />
      )}
    </Elements>
  );
};

const mapStateToProps = ({ subscriptions, user }) => ({
  hasSubscription: user.hasSubscription,
  subscriptions,
});

const mapDispatchToProps = dispatch => ({
  clearError: () => dispatch(clearSubscriptionError()),
  getSubscriptions: () => dispatch(fetchSubscriptions()),
});

AccountSubscriptions.propTypes = {
  getSubscriptions: PropTypes.func.isRequired,
  subscriptions: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSubscriptions);
