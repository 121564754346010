import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

// *********************************************** //

const SubscriptionLockedRoute = ({
  children,
  hasSubscription,
  isOnBabySteps,
  prevPath,
  ...rest
}) => (
  // eslint-disable-next-line
  <Route {...rest}>
    {hasSubscription || isOnBabySteps ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: prevPath,
        }}
      />
    )}
  </Route>
);

SubscriptionLockedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  isOnBabySteps: PropTypes.bool.isRequired,
  prevPath: PropTypes.string.isRequired,
};

const mapStateToProps = ({ user }) => ({
  hasSubscription: user.hasSubscription,
  isOnBabySteps: user.onBabyStepsTeam,
});

export default connect(mapStateToProps)(SubscriptionLockedRoute);
