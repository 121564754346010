import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Switch } from 'react-router-dom';

// Utils
import { hydrateUser } from '../../actions';

// Components
import AccountProfile from '../../components/AccountProfile';
import AccountSite from '../../components/AccountSite';
import AccountSubscriptions from '../../components/AccountSubscriptions';
import PrivateRoute from '../../components/utils/PrivateRoute';
import Sidebar from '../../components/Sidebar';
import SubscriptionLockedRoute from '../../components/utils/SubscriptionLockedRoute';

// Styled Components
import { SidebarContainer } from '../../components/styles/Sidebar';
import theme from '../../components/styles/themes';

// *********************************************** //

const MyAccountContainer = ({
  fetchUser,
  hasSubscription,
  isOnBabySteps,
  id,
}) => {
  useEffect(() => {
    fetchUser(id);
  }, [fetchUser, id]);

  const sidebarItems = (() => {
    if (hasSubscription || isOnBabySteps) {
      return [
        {
          title: 'Manage Account Information',
          url: '/account/profile',
        },
        {
          title: 'Manage Subscriptions',
          url: '/account/subscriptions',
        },
        { title: 'Manage Website', url: '/account/site' },
      ];
    }

    return [
      {
        title: 'Manage Account Information',
        url: '/account/profile',
      },
      {
        title: 'Manage Subscriptions',
        url: '/account/subscriptions',
      },
    ];
  })();

  return (
    <Container>
      <div className="row">
        <section className="col-sm-12 col-lg-3 sidebar">
          <SidebarContainer>
            <Sidebar title="My Account" items={sidebarItems} />
          </SidebarContainer>
        </section>
        <section className="col-sm-12 col-lg-9 main-content">
          <Switch>
            <PrivateRoute path="/account/profile">
              <AccountProfile />
            </PrivateRoute>
            <SubscriptionLockedRoute
              path="/account/site"
              prevPath="/account/profile"
            >
              <AccountSite />
            </SubscriptionLockedRoute>
            <PrivateRoute path="/account/subscriptions">
              <AccountSubscriptions />
            </PrivateRoute>
          </Switch>
        </section>
      </div>
    </Container>
  );
};

MyAccountContainer.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  isOnBabySteps: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};

const mapStateToProps = ({ user }) => ({
  hasSubscription: user.hasSubscription,
  isOnBabySteps: user.onBabyStepsTeam,
  id: user.id,
});

const mapDispatchToProps = dispatch => ({
  fetchUser: id => dispatch(hydrateUser(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountContainer);

// Styles
const { breakpoints } = theme;

const Container = styled.main`
  padding-bottom: 4rem;

  .sidebar {
    padding: 0 1rem 0 0;
  }

  section.main-content {
    display: flex;
    flex-direction: column;
    padding: 0;

    .row {
      flex-direction: column;
    }
  }

  .search-bar-container {
    width: 100%;
    margin: 0 auto 1.5rem;
  }

  @media (max-width: ${breakpoints.desktop}) {
    .sidebar {
      padding: 0;
    }

    padding-bottom: 2rem;
  }
`;
