import React from 'react';
import PropTypes from 'prop-types';

// Assets
import successTitle from '../../../images/sub-success.svg';

// Components
import SubscriptionModal from '../SubscriptionModal';

// Styled Components
import { Button } from '../../styles/Buttons';
import { ButtonWrapper, Instructions } from '../SubscriptionModal/styles';

// *********************************************** //

const SuccessModal = ({ children, handleClose }) => (
  <SubscriptionModal title="Success!" titleImageSrc={successTitle}>
    <Instructions>{children}</Instructions>
    <ButtonWrapper>
      <Button className="pro" onClick={handleClose} type="button">
        Close
      </Button>
    </ButtonWrapper>
  </SubscriptionModal>
);

SuccessModal.propTypes = {
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SuccessModal;
