import * as Sentry from '@sentry/browser';

export const notifySentry = ({
  account = null, // Just putting this here for later
  error, // Error is required
  subscription = null, // Just putting this here for later
}) => {
  // Only set the account data if it exists
  if (account) {
    Sentry.setTag('memberId', account.memberId);
    Sentry.setUser({
      memberId: account.memberId,
      name: account.name,
      email: account.email,
      hasSubscription: account.hasSubscription,
    });
  }

  if (subscription) {
    Sentry.setUser({
      subscription: JSON.stringify(subscription),
    });
  }

  // Send error to Sentry
  return Sentry.captureException(error);
};

export const SentryErrorHandler = (error, errorInfo = null) => {
  Sentry.withScope(scope => {
    if (errorInfo) {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    }

    notifySentry({
      error,
    });
  });
};
