import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styled Components
import theme from '../../styles/themes';
import { TextButton, BaseButton } from '../../styles/Buttons';

// Images
import tagIcon from '../../../images/tag.svg';

// Theme
const { colors } = theme;

// Styles
const Wrapper = styled.div`
  display: flex;
  margin: auto;
  margin-bottom: 1.5rem;

  img {
    max-height: 20px;
    max-width: 20px;
    margin-right: 1rem;
    margin-top: 2px;
  }

  .flex {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;

    .button-wrapper {
      position: absolute;
      right: 0;
      bottom: ${props => props.showingAll && '0'};
      padding-left: 30px;
      margin: 0 0 0 auto;

      .fade {
        display: ${props =>
          props.showingAll === true ? 'none' : 'inline-block'};
        position: absolute;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(
          to right,
          rgba(245, 245, 245, 0),
          rgba(245, 245, 245, 1)
        );
      }

      button {
        padding: 0 1rem;
        background: ${colors.lightestGray};
      }

      &.white-background {
        .fade {
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1)
          );
        }

        button {
          background: ${colors.white};
        }
      }
    }
  }

  .tags {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    &.truncate-tags {
      height: 24px;
      overflow-y: hidden;
    }
  }
`;

const TagButton = styled(BaseButton)`
  padding: 0;
  background: none;
  border: none;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  text-decoration: underline;
  color: ${colors.coral};
  font-size: 1rem;
  line-height: 1.2;
  width: unset;

  &:last-child {
    margin-bottom: ${props => (props.showingAll ? '3rem' : '')};
  }

  &:active {
    opacity: 0.7;
  }
`;

// *********************************************** //

const Tags = ({ handleClick, onCard, tags }) => {
  const [showAllTags, setShowAllTags] = useState(false);

  /**
   * If tags take up more than one line,
   * truncate them and display "show all" button
   */
  const tagsElement = useRef(null);
  const [tagsAreOverflowing, setTagsAreOverflowing] = useState(false);

  useEffect(() => {
    setTagsAreOverflowing(tagsElement.current.scrollHeight > 37);
  }, [tagsElement]);

  return (
    <Wrapper showingAll={showAllTags}>
      <img src={tagIcon} alt="Tags" />
      <div className="flex">
        <div
          ref={tagsElement}
          className={`tags ${!showAllTags && 'truncate-tags'}`}
        >
          {tags.map(tag => (
            <TagButton
              key={tag.slug}
              type="button"
              onClick={() => handleClick(tag)}
              showingAll={showAllTags}
            >
              {tag.title}
            </TagButton>
          ))}
        </div>
        {tagsAreOverflowing && (
          <div className={`button-wrapper ${onCard && 'white-background'}`}>
            <div className="fade" />
            <TextButton
              onClick={() => setShowAllTags(!showAllTags)}
              style={{ fontSize: '1rem', lineHeight: 1.2 }}
            >
              {showAllTags ? 'See Less' : 'See More'}
            </TextButton>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

Tags.propTypes = {
  handleClick: PropTypes.func,
  onCard: PropTypes.bool,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  ).isRequired,
};

Tags.defaultProps = {
  handleClick: null,
  onCard: false,
};

export default Tags;
