import styled from 'styled-components';

// Styled Components
import theme from '../styles/themes';

const { breakpoints, colors, fonts } = theme;

// Styles
export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  .learn-more-link {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: ${fonts.weight.medium};
    padding: 1.5rem 1rem 0rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: ${props => props.singleColumnOnMobile && 'column'};
  }
`;

export const Feature = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 1rem;

  div.text {
    margin: 0 0 0 0.5rem;
    font-weight: 500;
    text-decoration: none;
    line-height: 1.2;
    color: ${colors.black};
  }

  @media (max-width: ${breakpoints.mobile}) {
    text-align: left;
    width: ${props => props.singleColumnOnMobile && '100%'};
  }
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
`;
