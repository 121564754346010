import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUser } from '../../../actions';
import Modal from '../../Modal';
import Divider from '../../styles/Divider';
import ModalContent from '../../styles/ModalContent';

const UpdateBioModal = ({ afterSave, cancelFunc, open, updateBio, user }) => {
  const [bio, setBio] = useState(user.bio);

  const onClickSaveChanges = () => {
    updateBio(bio);
    afterSave();
  };

  return (
    <Modal cancelFunc={cancelFunc} ctaFunc={onClickSaveChanges} open={open}>
      <h1 className="modal-title">Update Your Bio</h1>
      <Divider />
      <ModalContent>
        <div className="text-area-wrapper">
          <textarea
            id="bio"
            name="bio"
            rows={8}
            placeholder="Enter your bio here."
            onChange={e => setBio(e.target.value)}
            value={bio}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

UpdateBioModal.defaultProps = {
  afterSave: null,
};

UpdateBioModal.propTypes = {
  afterSave: PropTypes.func,
  cancelFunc: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updateBio: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = dispatch => ({
  updateBio: bio => dispatch(updateUser({ bio })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBioModal);
