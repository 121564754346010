import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import last from 'lodash/last';

export const showName = (member, skipSiteTitle) => {
  if (!skipSiteTitle && member.siteTitle) return member.siteTitle;
  if (member.firstName && member.lastName)
    return `${member.firstName} ${member.lastName}`;
  if (member.name) return member.name;
  return null;
};

export const titleCase = string =>
  string
    .toLowerCase()
    .trim()
    .split(' ')
    .map(word => (isEmpty(word) ? null : word[0].toUpperCase() + word.slice(1)))
    .join(' ');

export const constructButtonUrl = ({
  replacements,
  shortKey,
  subType,
  url,
}) => {
  const regex = /{{([^?:{{+}}]*)}}/gi;
  const interpolate = str => obj =>
    str.replace(regex, oldStr => {
      const key = oldStr.split('').slice(2, -2).join('');
      const replace = obj[key];

      return typeof replace === 'string' || typeof replace === 'number'
        ? replace
        : oldStr;
    });

  const buttonUrlOptions = reduce(
    Object.keys(replacements),
    (result, value) => ({
      ...result,
      [value]: interpolate(url)(replacements),
    }),
    {}
  );
  const buttonUrl = {
    ...buttonUrlOptions,
    'landing-url': join([url, shortKey], '/'),
    url,
    // eslint-disable-next-line
  }[subType || 'url'];

  return buttonUrl;
};

async function downloadImage(image) {
  return new Promise((resolve, reject) => {
    function downloadHandler(err, data) {
      if (err) {
        reject(new Error(err));
      } else {
        resolve(data);
      }
    }

    JSZipUtils.getBinaryContent(image.fullUrl, downloadHandler);
  });
}

export function downloadSingleFile(file, title) {
  saveAs(file, title);
}

export async function downloadZipFile({
  folderName,
  fileUrls,
  handleError,
  handleProgress,
}) {
  // Create a new JSZip instance
  const zip = new JSZip();
  // Set the folder name
  zip.folder(`${folderName}`);
  // Loop over the file urls download the contents from the url,
  // then add them to the zip folder
  forEach(fileUrls, async image => {
    zip
      .folder(`${folderName}`)
      .file(last(image.fullUrl.split('/')), downloadImage(image), {
        binary: true,
      });
  });

  try {
    // Generate the zip file
    const blob = await zip.generateAsync({ type: 'blob' }, handleProgress);
    // use file saver to save to the device
    await saveAs(blob, `${folderName}.zip`);
  } catch (e) {
    // Handle any errors
    handleError(e);
  }
}
