import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import moment from 'moment';
import styled from 'styled-components/macro';
import { useQuery } from 'react-query';

// Utils
import LifeSteps from '../../../modules/LifeStepsAPI';
import { MainContext } from '../../../MainContext';
import useUserEvents from '../../utils/Hooks/useUserEvents';

// Components
import AddEventModal from '../AddEventModal';
import Card from '../../Card';
import Modal from '../../Modal';

// Styled Components
import { Button, ModalCancelButton } from '../../styles/Buttons';
import theme from '../../styles/themes';

const EventsCard = () => {
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [eventToEdit, setEventToEdit] = useState({
    title: '',
    location: '',
    date: '',
    time: '',
    eventLink: '',
  });

  const { dispatch: contextDispatch } = useContext(MainContext);

  // Get events and delete mutation
  const { deleteEventMutation } = useUserEvents();

  // Get API
  const lsapi = new LifeSteps();

  // Get Events
  const { data } = useQuery('user events', lsapi.getUserEvents, {
    staleTime: 30000,
  });

  // Unlock when add event modal is closed
  useEffect(() => {
    if (!showAddEventModal) {
      contextDispatch({ type: 'UPDATE_MODAL_IS_OPEN', payload: false });
    }
  }, [contextDispatch, showAddEventModal]);

  // Unlock when delete event modal is closed
  useEffect(() => {
    if (!showDeleteEventModal) {
      contextDispatch({ type: 'UPDATE_MODAL_IS_OPEN', payload: false });
    }
  }, [contextDispatch, showDeleteEventModal]);

  const onClickShowEventModal = useCallback(
    () => setShowAddEventModal(true),
    []
  );

  const onClickEdit = event => {
    setEventToEdit(find(data?.data?.userEvents, ev => ev.id === event));
    setEditMode(true);
    onClickShowEventModal();
  };

  const onClickDelete = useCallback(
    event => {
      deleteEventMutation.mutate(event);
      setShowDeleteEventModal(false);
    },
    [deleteEventMutation, setShowDeleteEventModal]
  );

  const onCloseModal = () => {
    setShowAddEventModal(false);
    setEventToEdit({
      title: '',
      location: '',
      date: '',
      time: '',
      eventLink: '',
    });
    setEditMode(false);
  };

  const DeleteEventModal = useMemo(
    () => (
      <Modal
        cancelFunc={() => setShowDeleteEventModal(false)}
        open={showDeleteEventModal}
        padding="32px"
        showActions={false}
        width="400px"
      >
        <p style={{ fontWeight: 300, fontSize: '24px', lineHeight: '1.4' }}>
          Are you sure you want to delete this event?
        </p>
        <Button danger onClick={() => onClickDelete(eventToDelete)}>
          Delete Event
        </Button>
        <ModalCancelButton
          style={{ marginTop: '8px' }}
          onClick={() => setShowDeleteEventModal(false)}
        >
          Nevermind
        </ModalCancelButton>
      </Modal>
    ),
    [eventToDelete, onClickDelete, showDeleteEventModal]
  );

  return (
    <>
      {showDeleteEventModal && DeleteEventModal}
      {showAddEventModal && (
        <AddEventModal
          open={showAddEventModal}
          cancelFunc={onCloseModal}
          eventToEdit={eventToEdit}
          editMode={editMode}
        />
      )}
      <Card width="100%" padding="32px">
        <CardContent>
          <h4 className="section-title">Event Schedule</h4>
          <h5 className="subtitle">
            Let people know when and where you are hosting events. These display
            on the About page of your website.
          </h5>
          <div className="divider" />
          <div className="events">
            {!isEmpty(data?.data?.userEvents) ? (
              map(data?.data?.userEvents, (event, idx) => (
                <Event key={idx}>
                  <div className="column">
                    <div>
                      <h4>{moment(event.eventAt).format('MMM DD, YYYY')}</h4>
                      <p>{moment(event.eventAt).format('h:mmA')}</p>
                    </div>
                  </div>
                  <div className="column">
                    <div>
                      <h4 className="title">{event.title}</h4>
                      <p>{event.location}</p>
                    </div>
                    <div className="buttons">
                      <RowButton onClick={() => onClickEdit(event.id)}>
                        Edit
                      </RowButton>
                      <RowButton
                        danger
                        onClick={() => {
                          setEventToDelete(event.id);
                          setShowDeleteEventModal(true);
                        }}
                      >
                        Remove
                      </RowButton>
                    </div>
                  </div>
                </Event>
              ))
            ) : (
              <p className="no-events">
                You currently have no events scheduled.
              </p>
            )}
          </div>
          <div className="divider margin-bottom" />
          <Button width="200px" onClick={onClickShowEventModal}>
            Add Event
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

export default EventsCard;

const { breakpoints, colors, fonts } = theme;

const CardContent = styled.section`
  text-align: left;
  word-break: break-word;

  h4 {
    margin: 0;
    text-transform: uppercase;
    font-weight: ${fonts.weight.demi};
    font-size: 14px;
  }

  h5.subtitle {
    margin: 0.5rem 0 1.5rem;
    padding: 0;
    color: ${colors.text.darkGray};
    font-weight: ${fonts.weight.lighter};
    font-size: ${fonts.size.lg};
    line-height: 1.4;
  }

  .divider {
    background-color: ${colors.dividerGray};
    height: 1px;

    &.margin-bottom {
      margin-bottom: 1.5rem;
    }
  }

  .info-label {
    display: block;
    text-transform: uppercase;
    font-size: ${fonts.size.xs};
    font-weight: ${fonts.weight.medium};
    color: ${colors.text.darkGray};
    padding: 1rem 0 0.75rem;
  }

  .profile-info {
    display: flex;
    flex-direction: column;

    img {
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 0.5rem;
    }

    .info-value {
      font-size: ${fonts.size.lg};
      font-weight: ${fonts.weight.medium};

      .domain-name {
        font-weight: ${fonts.weight.regular};
        color: ${colors.text.gray};
      }
    }

    .custom-url {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }

    .bio {
      line-height: 1.4;
      font-size: ${fonts.size.md};
      font-weight: ${fonts.weight.regular};

      p {
        margin: 0;
      }
    }
  }

  .social-profiles {
    .username-block {
      .social-username {
        display: flex;
        align-items: center;
        border: 1px solid ${colors.darkerDividerGray};
        border-radius: 4px;
        padding: 1rem;
        font-size: ${fonts.size.md};
        font-weight: ${fonts.weight.medium};

        .value {
          margin-left: 1rem;
        }
      }
    }
  }

  .events {
    margin: 1.5rem 0;

    p.no-events {
      margin: 2rem 0;
      text-align: center;
      font-weight: ${fonts.weight.lighter};
      color: ${colors.text.gray};
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    button {
      width: 100%;
    }
  }
`;

const Event = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 1.5rem;
  background: ${colors.nearlyWhite};
  border: 1px solid ${colors.darkerDividerGray};
  border-radius: 4px;

  .column {
    width: 20%;

    &:last-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      padding-right: 1rem;
    }
  }

  h4 {
    margin: 0 0 0.5rem;
    padding-right: 1.5rem;
    font-size: ${fonts.size.md};
    line-height: 1.4;
  }

  p {
    margin: 0;
    font-size: ${fonts.size.md};
  }

  .buttons {
    display: flex;
    min-width: 146px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    text-align: center;
    padding: 1.5rem 1rem;

    .column {
      width: 100%;

      &:last-child {
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;
        padding: 0;
      }
    }

    h4 {
      margin: 0;
      padding-right: 0;
      font-size: ${fonts.size.s};

      &.title {
        font-size: ${fonts.size.lg};
        margin-bottom: 0.25rem;
      }
    }

    p {
      margin: 0;
      font-size: ${fonts.size.md};
    }

    .buttons {
      width: 100%;
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
    }
  }
`;

const RowButton = styled.button`
  margin-right: 1rem;
  font-size: ${fonts.size.md};
  font-weight: ${fonts.weight.medium};
  background-color: transparent;
  color: ${props => (props.danger ? colors.roles.danger : colors.green)};
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${props =>
      props.danger ? colors.roles.danger : colors.green};
    opacity: 0.7;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    margin: 0;
    padding: 0.6rem 2rem 0.75rem;
    border: 1px solid
      ${props => (props.danger ? colors.roles.danger : colors.green)};
    border-radius: 4px;

    &:first-child {
      margin-bottom: 0.5rem;
    }
  }
`;
