import React from 'react';
import map from 'lodash/map';
import styled from 'styled-components/macro';

// Styles Components
import { Button } from '../styles/Buttons';
import theme from '../styles/themes';

const HelpSection = () => {
  const articles = [
    {
      title: `What's New with Life Steps`,
      url: 'https://help.lifestepsapp.com/content/app-updates',
    },
    {
      title: 'Start Here - How To Videos',
      url:
        'https://help.lifestepsapp.com/content/start-here:-how-to-video-guides',
    },
    {
      title: 'Life Steps Basics',
      url: 'https://help.lifestepsapp.com/content/life-steps-basics',
    },
    {
      title: 'Learn all about PRO',
      url: 'https://help.lifestepsapp.com/content/what-do-i-get-with-pro',
    },
  ];

  return (
    <HelpContainer>
      <div className="container">
        <Header>
          <h1>We&apos;re here to help.</h1>
          <p>
            Find information about the tools and services available to you
            through Life Steps.
          </p>
        </Header>
        <Flex>
          <div className="articles">
            <h5>Popular Articles</h5>
            <ul>
              {map(articles, article => (
                <li key={article.title}>
                  <a
                    href={article.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {article.title}
                  </a>
                </li>
              ))}
            </ul>
            <p>
              For more questions & articles, visit our{' '}
              <a
                href="https://help.lifestepsapp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Help Center
              </a>
              .
            </p>
          </div>
          <div className="help">
            <h5>Need Something Else?</h5>
            <p>
              Our team is ready and waiting to get you the information that you
              need. If you don’t see what you are looking for, please reach out
              and we’ll help in any way we can.
            </p>
            <Button id="launchIntercom" onClick={() => window.Intercom('show')}>
              Ask A Question
            </Button>
          </div>
        </Flex>
      </div>
    </HelpContainer>
  );
};

export default HelpSection;

// Styles
const { breakpoints, colors } = theme;

const HelpContainer = styled.section`
  width: 100%;
  padding: 4rem 0;
  background-color: ${colors.white};

  .container {
    max-width: 1024px;
    padding: 0;
  }

  @media (max-width: ${breakpoints.desktop}) {
    padding: 4rem 2rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    max-width: 100%;
    padding: 3rem 1rem;
  }
`;

const Header = styled.div`
  margin: 0 auto;
  text-align: center;

  h1 {
    margin: 0 0 1rem;
    font-size: 2rem;
    font-weight: 900;
    line-height: 1em;
  }

  p {
    margin: 0 0 2rem;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;

  h5 {
    margin: 0 0 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .articles {
    width: 60%;

    a {
      text-decoration: underline;
      text-decoration-color: ${colors.darkGreen};
      font-weight: 600;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin-bottom: 0.5rem;
        padding: 0;
        font-weight: 400;
        line-height: 1.4;
      }
    }
  }

  .help {
    flex: 1;

    p {
      margin-bottom: 1.5rem;
    }
  }

  @media (max-width: ${breakpoints.bigScreen}) {
    padding: 0 2rem;
  }

  @media (max-width: ${breakpoints.desktop}) {
    padding: 0;
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .articles {
      margin-bottom: 1rem;

      p {
        margin-top: 2rem;
        padding: 1rem;
        border: 1px solid ${colors.lightGray};
      }
    }

    button {
      margin: auto;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .articles {
      width: 100%;

      p {
        padding: 1rem 2rem;
      }
    }
  }
`;
