import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { getFromLocalStorage } from '../../../modules/localStorage';

const PrivateRoute = ({ children, ...rest }) => {
  const location = useLocation();
  const accessToken = getFromLocalStorage('accessToken');

  return (
    <Route {...rest}>
      {accessToken ? (
        children
      ) : (
        <Redirect
          to={{
            ...location,
            pathname: `/signin`,
            state: { from: location },
          }}
        />
      )}
    </Route>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
};

export default PrivateRoute;
