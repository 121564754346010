import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

// Utils
import useSubscriptionFlow from '../../utils/Hooks/useSubscriptionFlow';

// Components
import ChooseBillingCycleModal from '../../SubscriptionModals/ChooseBillingCycleModal';
import EnterCreditCardInfoModal from '../../SubscriptionModals/EnterCreditCardInfoModal';
import SuccessModal from '../../SubscriptionModals/SuccessModal';

// Styled Components
import Scrim from '../../styles/Scrim';

// *********************************************** //

const UpdateBillingCycleFlow = ({ handleClose }) => {
  const {
    billingCycle,
    continueToPaymentInfo,
    incrementStep,
    setBillingCycle,
    step,
  } = useSubscriptionFlow();

  return (
    <>
      <Scrim onClick={handleClose} />
      {step === 0 && (
        <ChooseBillingCycleModal
          handleClose={handleClose}
          onSelectPlan={setBillingCycle}
          nextStep={continueToPaymentInfo}
        />
      )}
      {step === 1 && (
        <EnterCreditCardInfoModal
          handleClose={handleClose}
          nextStep={incrementStep}
          selectedPlan={billingCycle}
        />
      )}
      {step === 2 && (
        <SuccessModal handleClose={handleClose}>
          <p>
            You have successfully updated the billing cycle for your{' '}
            <span>PRO</span> subscription to{' '}
            <span>
              {capitalize(billingCycle)}ly{' '}
              {billingCycle === 'month' ? `($8.99/mo)` : `($24.00/quarter)`}.
            </span>
          </p>
        </SuccessModal>
      )}
    </>
  );
};

UpdateBillingCycleFlow.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default UpdateBillingCycleFlow;
