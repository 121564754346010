import styled from 'styled-components/macro';

// Styled Components
import defaultTheme from '../styles/themes';

const { breakpoints, colors } = defaultTheme;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  button:first-child {
    margin-bottom: 0.5rem;
  }
`;

export const GetHelp = styled.div`
  margin: 2rem 0 0;
  text-align: center;

  a {
    font-weight: 500;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;

export const Instructions = styled.p`
  margin: 1rem 0;
  font-size: 1.125rem;
  text-align: left;
  color: ${colors.darkGray};

  a {
    font-weight: 500;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &.center {
    text-align: center;
  }

  @media (max-width: ${breakpoints.mobile}) {
    margin: 1rem auto;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-height: calc(100% - 2rem);
  padding: 2rem 2rem 3rem;
  background: ${colors.white};
  border-radius: 10px;
  box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.15);
  z-index: 1001;
  overflow-y: scroll;

  h1 {
    margin: 2rem 0;
    text-align: center;
  }

  button.close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    outline: none;
    border: none;
    box-shadow: none;

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: calc(100% - 2rem);
    padding: 1rem 1rem 2rem;

    h1 {
      margin-top: 3rem;
    }
  }
`;

export const Scrim = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #272727;
  z-index: 1000;
  opacity: 0.7;
  cursor: pointer;
`;
