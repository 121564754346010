import React from 'react';
import PropTypes from 'prop-types';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import Select from 'react-select';

// Assets
import countrylist from './countrylist';
import customStyles from './react-select-styles';

// Utils
import RenderInput from '../../utils/RenderInput';

// Styled Components
import { InputRow } from './styles';

export default function NewCreditCardForm({ state, updateValue }) {
  const { firstName, lastName, zipCode, country } = state;

  return (
    <>
      <InputRow>
        <div className="col-sm-12 col-md-6 pl-0">
          {RenderInput({
            labelText: 'First Name',
            name: 'firstName',
            onChange: e => updateValue('firstName', e.target.value),
            fieldType: 'text',
            placeholder: 'Jane',
            value: firstName,
          })}
        </div>
        <div className="col-sm-12 col-md-6 pr-0">
          {RenderInput({
            labelText: 'Last Name',
            name: 'lastName',
            onChange: e => updateValue('lastName', e.target.value),
            fieldType: 'text',
            placeholder: 'Smith',
            value: lastName,
          })}
        </div>
      </InputRow>
      <InputRow>
        <div className="col-sm-12 px-0">
          <div className="form-control">
            <label>Card Number</label>
            <CardNumberElement
              className="payment-info"
              options={{
                style: {
                  base: {
                    fontFamily: 'sofia-pro',
                    fontSize: '14px',
                    fontWeight: '300',
                    '::placeholder': {
                      color: '#969696',
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </InputRow>
      <InputRow>
        <div className="col-sm-12 col-md-6 pl-0">
          <div className="form-control">
            <label>Expiration Date</label>
            <CardExpiryElement
              className="payment-info"
              options={{
                style: {
                  base: {
                    fontFamily: 'sofia-pro',
                    fontSize: '14px',
                    fontWeight: '300',
                    '::placeholder': {
                      color: '#969696',
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-6 pr-0">
          <div className="form-control">
            <label>CVV</label>
            <CardCvcElement
              className="payment-info"
              options={{
                style: {
                  base: {
                    fontFamily: 'sofia-pro',
                    fontSize: '14px',
                    fontWeight: '300',
                    '::placeholder': {
                      color: '#969696',
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </InputRow>
      <InputRow>
        <div className="col-sm-12 col-md-6 pl-0">
          {RenderInput({
            fieldType: 'text',
            labelText: 'Billing Zip',
            name: 'zip',
            onChange: e => updateValue('zipCode', e.target.value),
            placeholder: 'Zip Code',
            value: zipCode,
          })}
        </div>
        <div className="col-sm-12 col-md-6 pr-0">
          <div className="form-control">
            <label htmlFor="country">Billing Country</label>
            <Select
              id="country"
              onChange={value => updateValue('country', value)}
              options={countrylist}
              styles={customStyles}
              value={country}
            />
          </div>
        </div>
      </InputRow>
    </>
  );
}

NewCreditCardForm.propTypes = {
  state: PropTypes.object.isRequired,
  updateValue: PropTypes.func.isRequired,
};
