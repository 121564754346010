import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import theme from '../../styles/themes';

const SocialMediaInput = ({
  icon,
  id,
  label,
  onChange,
  placeholder,
  value,
}) => (
  <SocialMediaInputWrapper>
    <label htmlFor={id}>
      {label}
      <InputWrapper>
        <div className="social-icon">
          <img src={icon} alt={`${label} icon`} />
        </div>
        <input
          autoCapitalize="false"
          autoComplete="false"
          autoCorrect="false"
          type="text"
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
        />
      </InputWrapper>
    </label>
  </SocialMediaInputWrapper>
);

SocialMediaInput.defaultProps = {
  label: null,
  value: '',
};

SocialMediaInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default SocialMediaInput;

// Styles
const { colors, fonts } = theme;

const SocialMediaInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.75rem;
  text-align: left;
  border: 1px solid ${colors.darkerDividerGray};
  border-radius: 4px;

  .social-icon {
    margin-right: 1rem;

    img {
      margin: 0;
    }
  }

  .domain {
    font-size: ${fonts.size.s};
    font-weight: ${fonts.weight.light};
    color: ${colors.text.gray};
  }

  input {
    width: 100%;
    padding: 0;
    font-size: ${fonts.size.s};
    font-weight: ${fonts.weight.regular};
    color: ${colors.black};
    border: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${colors.text.gray};
      font-weight: ${fonts.weight.light};
    }
  }
`;
