import styled from 'styled-components';

// Styled Components
import theme from '../../styles/themes';

const { breakpoints, colors, fonts } = theme;

export const BillingCycleOption = styled.button`
  position: relative;
  width: 49%;
  padding: 3.5rem 0;
  background: ${colors.white};
  border: 3px solid ${colors.lightGray};
  border-radius: 5px;
  opacity: 0.3;
  transition: all 100ms ease-in-out;
  font-size: 1rem;

  h1 {
    margin: 0;
    font-size: 2rem;
    font-family: ${fonts.family.sans};
  }

  p {
    margin: 0;
    font-size: 1.1rem;
    color: ${colors.plum};

    &.pro {
      color: ${colors.plum};
    }
  }

  &:disabled {
    h1 {
      color: ${colors.black};
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 0.5;
    border-color: ${colors.plum};
  }

  &.active {
    opacity: 1;
    border: 3px solid ${colors.plum};
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    padding: 1rem 0 1.5rem;

    div {
      height: auto;
    }

    &:first-child {
      margin-bottom: 0.5rem;
    }
  }
`;

export const ButtonRow = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoints.mobile}) {
    display: block;
  }
`;
