import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Utils
import { updateUser } from '../../../actions';

// Components
import Input from '../../utils/Input';
import Modal from '../../Modal';
import ModalContent from '../../styles/ModalContent';

// Styled Components
import Divider from '../../styles/Divider';

// *********************************************** //

const UpdateSiteTitleModal = ({
  afterSave,
  cancelFunc,
  open,
  updateSiteTitle,
  user,
}) => {
  const [siteTitle, setSiteTitle] = useState(user.siteTitle);

  const onClickSaveChanges = () => {
    updateSiteTitle(siteTitle);
    afterSave();
  };

  return (
    <Modal cancelFunc={cancelFunc} ctaFunc={onClickSaveChanges} open={open}>
      <h1 className="modal-title">Update Your Site Title</h1>
      <Divider />
      <ModalContent>
        <Input
          onChange={e => setSiteTitle(e.target.value)}
          placeholder="Enter a site title (optional)"
          fieldType="text"
          value={siteTitle || ''}
        />
      </ModalContent>
    </Modal>
  );
};

UpdateSiteTitleModal.defaultProps = {
  afterSave: null,
  user: {},
};

UpdateSiteTitleModal.propTypes = {
  afterSave: PropTypes.func,
  cancelFunc: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updateSiteTitle: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = dispatch => ({
  updateSiteTitle: title => dispatch(updateUser({ siteTitle: title })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateSiteTitleModal);
