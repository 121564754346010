import React, { useEffect, useReducer, useState } from 'react';
import { AlertCircle } from 'react-feather';
import axios from 'axios';
import { connect } from 'react-redux';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router';

// Assets
import blobGroup from '../../images/blob-group.svg';
import logo from '../../images/ls-mark-wordmark.svg';

// Utils
import { signInUser, signInError } from '../../actions';

// Components
import ButtonWithLoader from '../../components/utils/ButtonWithLoader';
import Input from '../../components/utils/Input';
import PasswordResetModal from '../../components/PasswordResetModal';

// Styled Components
import theme from '../../components/styles/themes';

// *********************************************** //

const initialState = {
  memberId: '',
  password: '',
  showCatchModal: false,
  imageUrl: '',
  referrers: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'TYPE_MEMBER_ID':
      return {
        ...state,
        memberId: action.memberId,
      };

    case 'TYPE_PASSWORD':
      return {
        ...state,
        password: action.password,
      };

    case 'SHOW_CATCH_MODAL':
      return {
        ...state,
        showCatchModal: action.payload,
      };

    case 'SET_REFERRER_CONTENT':
      return {
        ...state,
        image: action.payload.image,
        text: action.payload.text,
      };

    case 'SET_REFERRERS':
      return {
        ...state,
        referrers: action.payload,
      };

    default:
      throw new Error();
  }
};

const SignInContainer = ({
  clearSigninError,
  error,
  isAuthenticated,
  isLoading,
  login,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  const { state: { from } = {}, search } = location;
  const { referrer } = queryString.parse(search);

  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);

  useEffect(() => {
    const fetchHeaderImage = async () => {
      const {
        data: { signin },
      } = await axios.get(process.env.REACT_APP_S3_URL);

      if (typeof signin === 'undefined') {
        dispatch({
          type: 'SET_REFERRER_CONTENT',
          payload: {
            image: null,
            text: null,
          },
        });
      }

      if (referrer && !!signin && !isEmpty(signin[referrer])) {
        dispatch({
          type: 'SET_REFERRER_CONTENT',
          payload: {
            image: signin[referrer].image,
            text: signin[referrer].text,
          },
        });
      }
      dispatch({
        type: 'SET_REFERRERS',
        payload: signin ? Object.keys(signin) : [],
      });
    };
    fetchHeaderImage();
  }, [referrer]);

  useEffect(() => {
    if (includes(state.referrers, referrer)) {
      dispatch({ type: 'SHOW_CATCH_MODAL', payload: true });
    }
  }, [referrer, state.referrers]);

  const onClickSignin = e => {
    e.preventDefault();
    login({ memberId: state.memberId, password: state.password });
  };

  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <Logo>
        <img src={logo} alt="Life Steps" />
      </Logo>
      <SigninStyles>
        <img src={blobGroup} alt="decorative" className="blob-group" />
        <div className="signin-container">
          <SignInCard>
            {referrer && !isEmpty(state.image) ? (
              <img src={state.image} alt="header title" />
            ) : (
              <>
                <h3>Sign in to the Life Steps</h3>
                <h1>Member&rsquo;s Dashboard</h1>
              </>
            )}
            <form className="signin-form" onSubmit={onClickSignin}>
              <p>
                {referrer && !isEmpty(state.text) ? (
                  state.text
                ) : (
                  <span>
                    Sign into your Life Steps Account using your Young Living
                    Account Info.
                  </span>
                )}
              </p>
              {error && (
                <div className="error-message">
                  <AlertCircle />
                  <div>{error}</div>
                </div>
              )}
              <Input
                withLabel
                labelText="YL Member No."
                fieldType="tel"
                onChange={e => {
                  dispatch({
                    type: 'TYPE_MEMBER_ID',
                    memberId: e.target.value,
                  });
                  if (!isEmpty(error)) {
                    clearSigninError();
                  }
                }}
                placeholder="Enter your YL Member Number."
                value={state.memberId}
              />
              <Input
                withLabel
                labelText="YL Password"
                fieldType="password"
                onChange={e => {
                  dispatch({ type: 'TYPE_PASSWORD', password: e.target.value });
                  if (!isEmpty(error)) {
                    clearSigninError();
                  }
                }}
                placeholder="Enter your YL password."
                value={state.password}
              />
              <ButtonWithLoader
                disabled={!state.memberId || !state.password}
                isLoading={isLoading}
                width="100%"
                type="submit"
              >
                Sign In
              </ButtonWithLoader>
            </form>
            <GetHelp>
              <button
                type="button"
                onClick={() => setShowPasswordResetModal(true)}
              >
                Need Help Signing In?
              </button>
            </GetHelp>
          </SignInCard>
        </div>
      </SigninStyles>
      <Footer>
        <div className="box" />
        <div className="box">
          Copyright &copy; {moment().format('YYYY')} | All Rights Reserved
        </div>
        <div className="box">
          <div className="links">
            <a
              href="https://www.lifestepsapp.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
            <a
              href="https://www.lifestepsapp.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </Footer>
      {showPasswordResetModal && (
        <PasswordResetModal
          handleClose={() => setShowPasswordResetModal(false)}
          memberNumber={state.memberId}
        />
      )}
    </>
  );
};

SignInContainer.propTypes = {
  clearSigninError: PropTypes.func.isRequired,
  error: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
};

SignInContainer.defaultProps = {
  error: null,
};

const mapStateToProps = ({ user }) => ({
  error: user.error,
  isAuthenticated: user.authenticated,
  isLoading: user.isLoading,
});

const mapDispatchToProps = dispatch => ({
  login: body => dispatch(signInUser(body)),
  clearSigninError: () => dispatch(signInError(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInContainer);

// Styles
const { breakpoints, colors, fonts } = theme;

const Logo = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  z-index: 1;

  img {
    max-width: 8.5rem;
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 1rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    justify-content: center;
  }
`;

const SignInCard = styled.div`
  padding: 2rem;
  background: ${colors.white};
  box-shadow: 0 14px 30px rgba(0, 0, 0, 0.12);
  border-radius: 10px;

  @media (max-width: ${breakpoints.mobile}) {
    box-shadow: none;
  }
`;

const SigninStyles = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px);
  width: 100vw;
  text-align: center;
  background-color: ${colors.white};
  overflow-x: hidden;

  .blob-group {
    position: absolute;
    width: 1700px;
    transform: translateY(-15%);
    z-index: 0;

    @media (max-width: ${breakpoints.mobile}) {
      /* transform: translate(28%, -13%); */
      display: none;
    }
  }

  .signin-container {
    width: calc(100% - 1rem);
    max-width: 450px;
    z-index: 1;

    .signin-form {
      padding: 0 2rem 2rem;

      .error-message {
        text-align: left;
        font-size: 0.875rem;
        font-weight: ${fonts.weight.regular};
        color: ${colors.roles.danger};
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        svg {
          fill: ${colors.roles.danger};
          stroke: ${colors.white};
          margin-right: 1.5rem;
        }

        div {
          flex: 1;
        }
      }

      p {
        margin: 0 0 2rem;
        font-weight: ${fonts.weight.lighter};
        line-height: 140%;
        color: ${colors.text.darkGray};
      }

      input {
        margin-bottom: 0.5rem;

        ::placeholder {
          font-weight: ${fonts.weight.light};
        }
      }

      button {
        margin-top: 1rem;
      }

      @media (max-width: ${breakpoints.mobile}) {
        padding: 0 0 2rem;
      }
    }

    h3 {
      margin-block-end: 0;
      font-size: ${fonts.size.s};
      font-weight: ${fonts.weight.medium};
      line-height: 1.4;
      color: ${colors.darkGray};
    }

    h1 {
      font-family: freight-display-pro;
      font-size: ${fonts.size.xxl};
      font-weight: ${fonts.weight.black};
      color: ${colors.black};
      line-height: 1.4;
      margin: 0 0 1rem;
    }
  }

  @media (max-width: ${breakpoints.desktop}) {
    height: calc(100vh - 88px);
  }

  @media (max-width: ${breakpoints.mobile}) {
    height: 100vh;
    /* padding-top: 3rem; */
  }
`;

const GetHelp = styled.div`
  margin: 0 0 2rem;

  button {
    background: none;
    border: none;
    outline: none;
    color: ${colors.darkGreen};
    font-weight: 500;
    text-decoration: underline;
    font-size: 1rem;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  padding: 1.5rem 2rem;
  font-size: ${fonts.size.s};
  text-align: center;
  text-transform: uppercase;
  background-color: ${colors.black};
  color: ${colors.white};

  .box {
    width: calc(100% / 3);
    .links {
      display: flex;
      justify-content: flex-end;

      a {
        margin: 0 1rem;
        color: ${colors.white};
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          text-decoration-color: rgba(255, 255, 255, 0.7);
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.desktop}) {
    flex-direction: column;

    .box {
      width: 100%;
      .links {
        justify-content: center;
        padding-top: 1rem;
      }
    }
  }
`;
