import React from 'react';
import { AlertCircle } from 'react-feather';
import PropTypes from 'prop-types';

// Styled Components
import { ErrorMessageWrapper } from './styles';

// *********************************************** //

const ErrorMessage = ({ message }) => (
  <ErrorMessageWrapper>
    <AlertCircle />
    <p>{message}</p>
  </ErrorMessageWrapper>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorMessage;
