import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router';
import styled from 'styled-components/macro';

// Utils
import { MainContext } from '../../MainContext';

// Components
import DashboardContainer from '../Dashboard';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HelpSection from '../../components/HelpSection';
import MyAccountContainer from '../MyAccount';
import PrivateRoute from '../../components/utils/PrivateRoute';
import ResourcesContainer from '../Resources';

// Styles
import theme from '../../components/styles/themes';

// *********************************************** //

const MemberDashboard = ({ user }) => {
  const { state } = useContext(MainContext);

  // Init Intercom
  useEffect(() => {
    // Check for intercom before launching or tests fail
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: 'bguua2pn',
        custom_launcher_selector: '#launchIntercom',
        email: user.email,
        has_subscription: user.hasSubscription,
        name: user.name,
        phone: user.phone,
        user_id: user.memberId, // YL Member ID
      });
    }
  }, [user]);

  // Lock body on scroll
  useEffect(() => {
    if (state.modalIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [state.modalIsOpen]);

  return (
    <>
      <Header />
      <FlexWrapper>
        <div className="content">
          <MainContentWrapper>
            <Switch>
              <Redirect from="/account" exact to="/account/profile" />
              <PrivateRoute path="/" exact>
                <DashboardContainer />
              </PrivateRoute>
              <PrivateRoute path="/resources">
                <ResourcesContainer />
              </PrivateRoute>
              <PrivateRoute path="/account">
                <MyAccountContainer />
              </PrivateRoute>
            </Switch>
          </MainContentWrapper>
        </div>
        <HelpSection />
        <Footer />
      </FlexWrapper>
    </>
  );
};

MemberDashboard.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(MemberDashboard);

// Styles
const { breakpoints } = theme;

const MainContentWrapper = styled.main`
  position: relative;
  max-width: 1024px;
  margin: 6rem auto 0;
  padding: 0 2rem;

  @media (min-width: ${breakpoints.bigScreen}) {
    padding: 0;
  }

  @media (max-width: ${breakpoints.desktop}) {
    max-width: 100%;
  }

  @media (max-width: ${breakpoints.mobile}) {
    max-width: 100%;
    padding: 0 1rem;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content {
    flex: 1 0 auto;
  }
`;
