import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styled Components
import { TextButton } from '../../styles/Buttons';
import theme from '../../styles/themes';

// Theme
const { colors } = theme;

// Styles
const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 999999999;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 2rem;

    img {
      max-height: 80vh;
      margin-bottom: 2rem;
      margin-top: auto;
    }
  }
`;

const Button = styled(TextButton)`
  width: 100%;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.8px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  color: ${colors.white};
  cursor: pointer;
  margin: auto;
  text-align: center;
  text-decoration-color: ${colors.white};

  &.close {
    margin-top: auto;
  }
`;

const Scrim = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: -1;
`;

// *********************************************** //

const ImageViewer = ({ imgSrc, handleClose, handleDownload }) =>
  ReactDOM.createPortal(
    <Wrapper>
      <Scrim onClick={handleClose} />
      <div className="content">
        <img src={imgSrc} alt="Tap close to close viewer." />
        {handleDownload && (
          <Button onClick={handleDownload}>Download Image</Button>
        )}
        <Button className="close" onClick={handleClose}>
          Close
        </Button>
      </div>
    </Wrapper>,
    document.body
  );

ImageViewer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleDownload: PropTypes.func,
  imgSrc: PropTypes.string.isRequired,
};

ImageViewer.defaultProps = {
  handleDownload: null,
};

export default ImageViewer;
