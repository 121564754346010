import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import CancellationFeedbackModal from '../../SubscriptionModals/CancellationFeedbackModal';
import CancelSubscriptionModal from '../../SubscriptionModals/CancelSubscriptionModal';

// Styled Components
import Scrim from '../../styles/Scrim';

// *********************************************** //

const CancelSubscriptionFlow = ({ handleClose }) => {
  const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);

  return (
    <>
      <Scrim onClick={handleClose} />
      {!subscriptionCancelled ? (
        <CancelSubscriptionModal
          handleClose={handleClose}
          nextStep={() => setSubscriptionCancelled(true)}
        />
      ) : (
        <CancellationFeedbackModal handleClose={handleClose} />
      )}
    </>
  );
};

CancelSubscriptionFlow.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CancelSubscriptionFlow;
