import styled from 'styled-components/macro';

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props =>
    props.darker
      ? props.theme.colors.darkerDividerGray
      : props.theme.colors.dividerGray};
`;

export default Divider;
