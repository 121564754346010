import styled from 'styled-components';
import theme from '../../styles/themes';

const { breakpoints, colors, fonts } = theme;

export const FormContent = styled.div`
  padding: 0 0 1.5rem;

  .existing-card-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 0.5rem;
    }
  }

  .row {
    margin: 0;
    margin-top: 0.75rem;
  }

  .form-control {
    display: flex;
    text-align: left;
    flex-direction: column;

    label {
      margin: 0.5rem 0;
      padding: 0;
      color: ${colors.text.darkGray};
      font-weight: ${fonts.weight.regular};
      font-size: ${fonts.size.xs};
      line-height: 1.4;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .payment-info {
    padding: 1rem;
    font-size: ${fonts.size.s};
    font-weight: ${fonts.weight.regular};
    border: 2px solid #e4e4e4;
    border-radius: 4px;
    color: ${colors.text.black};

    input {
      font-family: ${fonts.family.sans};
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .row {
      margin: 0;
    }
  }
`;

export const InputRow = styled.div`
  display: flex;
  margin-top: 1rem;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const Error = styled.div`
  font-size: 0.875rem;
  font-weight: ${fonts.weight.regular};
  color: ${colors.roles.danger};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  svg {
    fill: ${colors.roles.danger};
    stroke: ${colors.white};
    margin-right: 0.5rem;
  }
`;

export const CardListStyles = styled.div`
  display: grid;
  grid-template: rows;
  row-gap: 0.5rem;
`;
