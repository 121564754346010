import { createGlobalStyle } from 'styled-components/macro';
import '../../../utils/bootstrap-grid.min.css';

const breakpoints = {
  mobile: '450px' /* newest large phones */,
  tablet: '768px' /* tablet */,
  desktop: '992px' /* small desktop */,
  bigScreen: '1025px' /* large desktop */,
};

// Color Palette
const colors = {
  black: '#272727',
  darkGray: '#404040',
  gray: '#A8A8A8',
  lightGray: '#E4E4E4',
  lightestGray: '#F5F5F5',
  white: '#FFFFFF',
  darkGreen: '#377864',
  green: '#5E9584', // Brand Color
  lightGreen: '#AECAC1',
  lightestGreen: '#EFF5F3',
  lightPlum: '#FAF7F8',
  plum: '#7B2B51',
  coral: '#F98474',
  peach: '#FFAB8C',
  yellow: '#F4CF74',
  gold: '#DAA866',
  darkerDividerGray: '#E4E4E4',
  dividerGray: '#F5F5F5',
  nearlyWhite: '#FAFAFA',
  text: {
    gray: '#969696',
    darkGray: '#575757',
    lightGray: '#f5f5f5',
  },
  roles: {
    success: '#4AAD52',
    warning: '#FCBF49',
    danger: '#DB222A',
    divider: '#f4f4f4',
  },
};

const fonts = {
  family: {
    serif: `freight-display-pro, serif`,
    sans: `sofia-pro, sans-serif`,
  },
  size: {
    xs: '0.75rem', // 12px
    s: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.5rem', // 24px
    xxl: '2rem', // 32px
    xxxl: '3rem', // 48px
  },
  weight: {
    lightest: '100',
    lighter: '200',
    light: '300',
    regular: '400',
    medium: '500',
    demi: '600',
    bold: '700',
    black: '900',
  },
};

const spacing = {
  xs: '4px',
  s: '8px',
  smd: '12px',
  md: '16px',
  lg: '24px',
  xl: '32px',
  xxl: '48px',
  xxxl: '60px',
};

const shadows = {
  light: '0 1px 8px rgba(0, 0, 0, 0.1)',
  modal: '0 10px 40px -15px rgba(0, 0, 0, 0.3)',
};

// Main Theme
const theme = {
  breakpoints,
  colors,
  fonts,
  spacing,
  shadows,
  GlobalStyle: createGlobalStyle`
    @import url("https://use.typekit.net/wya8swp.css");

    html {
      box-sizing: border-box;
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    body {
      margin: 0;
      padding: 0;
      font-family: sofia-pro, san-serif, freight-display-pro, serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 1rem;
      background: ${colors.nearlyWhite};
      font-smoothing: subpixel-antialiased;
      text-rendering: optimizeLegibility;
      color: #2c2d30;

    }

    input, select, textarea, button {
      font-family: inherit;
    }

    h1 {
      font-family: freight-display-pro, serif;
    }

    a {
      color: ${colors.darkGreen};
      text-decoration-color: ${colors.darkGreen};
    }

    p {
      line-height: 1.4;
    }

    button:focus {
      outline: none;
    }

    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
      padding-right: 8px;
      padding-left: 8px;

      @media (max-width: ${breakpoints.mobile}) {
        margin: 0.5rem 0;
        padding: 0;
      }
    }

    .row {
      margin-right: 0;
      margin-left: 0;
    }
  `,
};

export { theme as default };
