import React, { useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import theme from '../styles/themes';

import Card from '../Card';
import { ModalCancelButton } from '../styles/Buttons';
import ButtonWithLoader from '../utils/ButtonWithLoader';
import Divider from '../styles/Divider';
import { MainContext } from '../../MainContext';

const Modal = ({
  buttonClassName,
  cancelFunc,
  cancelText,
  children,
  ctaText,
  ctaFunc,
  disabled,
  danger,
  displayCancel,
  isLoading,
  open,
  padding,
  showActions,
  showDivider,
  width,
}) => {
  const { dispatch } = useContext(MainContext);

  useEffect(() => {
    dispatch({ type: 'UPDATE_MODAL_IS_OPEN', payload: open });
  }, [dispatch, open]);

  return open
    ? ReactDOM.createPortal(
        <ModalContainer width={width} data-testid="modal-container">
          <div className="modal-card-container">
            <Card padding={padding}>
              {children}
              {showActions && (
                <div className="actions">
                  {showDivider && <Divider />}
                  <ButtonWithLoader
                    width="200px"
                    className={buttonClassName}
                    danger={danger}
                    isLoading={isLoading}
                    onClick={ctaFunc}
                    disabled={disabled || isLoading}
                    data-testid="modal-cta"
                  >
                    {ctaText}
                  </ButtonWithLoader>
                  {displayCancel && (
                    <ModalCancelButton fontSize="16px" onClick={cancelFunc}>
                      {cancelText}
                    </ModalCancelButton>
                  )}
                </div>
              )}
            </Card>
          </div>
        </ModalContainer>,
        document.body
      )
    : null;
};

Modal.defaultProps = {
  buttonClassName: null,
  cancelText: 'Cancel',
  ctaText: 'Save Changes',
  danger: false,
  disabled: false,
  displayCancel: true,
  isLoading: false,
  padding: null,
  showActions: true,
  showDivider: true,
};

Modal.propTypes = {
  buttonClassName: PropTypes.string,
  cancelFunc: ({ displayCancel, ...rest }, propName) => {
    if (
      displayCancel === true &&
      (rest[propName] === undefined || typeof rest[propName] !== 'function')
    ) {
      return new Error(
        '`displayCancel` is true, but no `cancelFunc` was provided. Please provide a function for the call to action button.'
      );
    }

    return null;
  },
  cancelText: PropTypes.string,
  children: PropTypes.node.isRequired,
  ctaFunc: ({ showActions, ...rest }, propName) => {
    if (
      showActions === true &&
      (rest[propName] === undefined || typeof rest[propName] !== 'function')
    ) {
      return new Error(
        '`showActions` is true, but no `ctaFunc` was provided. Please provide a function for the call to action button.'
      );
    }

    return null;
  },
  ctaText: PropTypes.string,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  displayCancel: PropTypes.bool,
  isLoading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  padding: PropTypes.string,
  showActions: PropTypes.bool,
  showDivider: PropTypes.bool,
};

export default Modal;

// Styles
const { breakpoints, fonts, colors } = theme;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  /* overflow-y: scroll; */

  .rw-widget-picker.rw-widget-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: unset;
    border: 1px solid ${colors.darkerDividerGray};

    & > .rw-select.rw-select-bordered {
      width: 25%;
      padding: 1rem;
      border-left: none;

      button svg {
        color: ${colors.green};
      }

      &:hover {
        background-color: ${colors.white};
      }
    }

    input.rw-widget-input {
      padding: 1rem;
      box-shadow: none;
    }
  }

  .modal-card-container {
    width: ${props => props.width || '565px'};
    margin: auto;
    max-height: calc(100vh - 2rem);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;


    @media (max-width: ${breakpoints.mobile}) {
      display: flex;
      align-items: flex-start;
      width: calc(100vw - 1rem);
      padding-top: 0.5rem;
      padding-bottom: 4rem;
    }
  }

  .modal-title {
    margin-bottom: 1.5rem;
    font-family: sofia-pro;
    font-size: ${fonts.size.xl};
    font-weight: ${fonts.weight.medium};
    line-height: 1.2em;
  }

  .serif-modal-title {
    margin: 0.25rem auto;
    font-size: ${fonts.size.xxl};
    font-family: freight-display-pro;
    font-weight: ${fonts.weight.black};
    color: ${colors.darkGreen};
    line-height: 1.4;
  }

  h4 {
    margin: 0.25rem auto;
    font-size: ${fonts.size.lg};
    font-weight: ${fonts.weight.medium};
  }

  .actions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    & > div {
      margin-bottom: 1.5rem;
    }

    button:first-of-type {
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    overflow-y: scroll;

    .actions {
      button {
        width: 100%;

        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
`;
