import React from 'react';
import PropTypes from 'prop-types';

// Assets
import leaves from '../../../images/leaves.svg';

// Styled Components
import { ModalWrapper, TitleGraphic } from './styles';

// *********************************************** //

const SubscriptionModal = ({ title, titleImageSrc, children }) => {
  return (
    <>
      <ModalWrapper>
        <TitleGraphic>
          <img src={leaves} alt="logo" className="leaves" />
          <img
            src={titleImageSrc}
            alt={`Modal Title: ${title}`}
            className="title"
          />
        </TitleGraphic>
        {children}
      </ModalWrapper>
    </>
  );
};

SubscriptionModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  titleImageSrc: PropTypes.string.isRequired,
};

export default SubscriptionModal;
