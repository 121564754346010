import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

// Components
import AppStoreLinks from '../../AppStoreLinks';
import ProFeatureLinks from '../../ProFeatureLinks';
import SubscriptionModal from '../SubscriptionModal';

// Styled Components
import { Button } from '../../styles/Buttons';
import {
  ButtonWrapper,
  Instructions,
  LightPlumBox,
} from '../SubscriptionModal/styles';

// Assets
import subscriptionSuccessTitle from '../../../images/sub-look-at-you-go.svg';

// *********************************************** //

const SubscriptionSuccessModal = ({ handleClose }) => {
  useEffect(() => {
    ReactGA.event({
      category: 'Conversion',
      action: 'Subscription Completed',
    });
  }, []);

  return (
    <SubscriptionModal
      title="Subscription Successful!"
      titleImageSrc={subscriptionSuccessTitle}
    >
      <Instructions>
        You now have <span>UNLIMITED ACCESS</span> to all Life Steps tools.
      </Instructions>
      <ProFeatureLinks />
      <LightPlumBox>
        <p>Download the Life Steps App</p>
        <AppStoreLinks />
      </LightPlumBox>
      <ButtonWrapper>
        <Button className="pro" onClick={handleClose} type="button">
          Close
        </Button>
      </ButtonWrapper>
    </SubscriptionModal>
  );
};

SubscriptionSuccessModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default SubscriptionSuccessModal;
