import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import uuidv4 from 'uuid';

import ListCard from '../ListCard';

const CardGrid = ({
  cardsInRow,
  cardType,
  explainProContent,
  hasSubscription,
  items,
  maxWidth,
  openViewResourceModal,
}) => {
  const cards = useMemo(
    () =>
      items.map(item => (
        <ListCard
          cardsInRow={cardsInRow}
          cardType={cardType}
          hasSubscription={hasSubscription}
          key={uuidv4()}
          padding="0"
          maxWidth={maxWidth}
          openViewResourceModal={openViewResourceModal}
          explainProContent={explainProContent}
          {...item}
        />
      )),
    [
      items,
      cardsInRow,
      cardType,
      explainProContent,
      hasSubscription,
      openViewResourceModal,
      maxWidth,
    ]
  );
  return <GridContainer>{cards}</GridContainer>;
};

CardGrid.defaultProps = {
  cardsInRow: 2,
  cardType: 'box',
  items: [{}],
  maxWidth: null,
  openViewResourceModal: () => {},
  explainProContent: () => {},
};

CardGrid.propTypes = {
  cardsInRow: PropTypes.number,
  cardType: PropTypes.oneOf(['resource', 'box']),
  hasSubscription: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  maxWidth: PropTypes.string,
  openViewResourceModal: PropTypes.func,
  explainProContent: PropTypes.func,
};

export default CardGrid;

const GridContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 100%;

  ::after {
    content: '';
    flex: 0 0 32%;
  }
`;
