import React, { useState } from 'react';
import { AlertCircle } from 'react-feather';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

// Utils
import RenderInput from '../utils/RenderInput';
import { updateUser } from '../../actions';

// Component
import Modal from '../Modal';

// Styled Components
import Divider from '../styles/Divider';
import theme from '../styles/themes';

// *********************************************** //

const VerifyInfoModal = ({ error, open, submitInfoVerification, user }) => {
  // Keep state for inputs
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [email, setEmail] = useState(user?.email || '');

  // Keep state for whether the user wants to get emails
  const [getEmails, setGetEmails] = useState(true);

  const onClick = () => {
    const body = {
      firstName,
      infoVerified: 'true',
      lastName,
      email,
      marketingEmails: getEmails,
    };

    submitInfoVerification(body);
  };

  return (
    <Modal
      ctaFunc={onClick}
      ctaText="Confirm Info"
      displayCancel={false}
      open={open}
      padding="2rem"
    >
      <VerifyInfoModalStyles>
        <h4>Welcome to the Life Steps</h4>
        <h1 className="serif-modal-title">Member&rsquo;s Dashboard</h1>
        <p>Please verify the following information and we’ll get you set up.</p>
        {error && (
          <Error data-testid="error-container">
            <AlertCircle />
            <div data-testid="error-message">{error}</div>
          </Error>
        )}
        <Divider />
        <div className="verify-info-form">
          <div className="col-sm-12 px-0 input-wrapper">
            {RenderInput({
              fieldType: 'text',
              id: 'firstName',
              labelText: 'First Name',
              onChange: e => setFirstName(e.target.value),
              value: firstName,
            })}
          </div>
          <div className="col-sm-12 px-0 input-wrapper">
            {RenderInput({
              fieldType: 'text',
              id: 'lastName',
              labelText: 'Last Name',
              onChange: e => setLastName(e.target.value),
              value: lastName,
            })}
          </div>
          <div className="col-sm-12 px-0 input-wrapper">
            {RenderInput({
              fieldType: 'email',
              id: 'email',
              labelText: 'Email Address',
              onChange: e => setEmail(e.target.value),
              value: email,
            })}
          </div>
          <div className="row col-sm-12 px-0 checkbox-wrapper">
            <input
              type="checkbox"
              checked={getEmails}
              onChange={() => setGetEmails(!getEmails)}
            />
            <p>Yes, send me emails.</p>
          </div>
        </div>
      </VerifyInfoModalStyles>
    </Modal>
  );
};

VerifyInfoModal.propTypes = {
  error: PropTypes.string,
  open: PropTypes.bool.isRequired,
  submitInfoVerification: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

VerifyInfoModal.defaultProps = {
  error: null,
};

const mapStateToProps = ({ user }) => ({
  error: user.error,
  user,
});

const mapDispatchToProps = dispatch => ({
  submitInfoVerification: body => dispatch(updateUser(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyInfoModal);

// Styles
const { colors, fonts } = theme;

const VerifyInfoModalStyles = styled.div`
  h1.serif-modal-title {
    margin-bottom: 1rem;
  }
  .verify-info-form {
    padding: 1rem 0 1.5rem;

    .input-wrapper {
      margin-bottom: 1rem;
    }

    .checkbox-wrapper {
      justify-content: center;
      align-items: center;

      input {
        margin-right: 0.5rem;
      }
    }
  }
`;

const Error = styled.div`
  font-size: 0.875rem;
  font-weight: ${fonts.weight.regular};
  color: ${colors.roles.danger};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;

  svg {
    fill: ${colors.roles.danger};
    stroke: ${colors.white};
    margin-right: 0.5rem;
  }
`;
