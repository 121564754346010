/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import Card from '../Card';
import { Button } from '../styles/Buttons';
import UpdateProfileModal from './UpdateProfileModal';
import theme from '../styles/themes';

const AccountProfile = ({ user }) => {
  const [showModal, setShowModal] = useState(false);
  const { email, firstName, lastName, memberId, phone } = user;

  return (
    <Wrapper data-testid="account-profile-wrapper">
      <UpdateProfileModal
        afterSave={() => setShowModal(false)}
        cancelFunc={() => setShowModal(false)}
        open={showModal}
        user={user}
      />
      <Card width="100%" padding="32px">
        <CardContent>
          <h4 className="section-title">Account Information</h4>
          <div className="divider" />
          <h2>{`${firstName} ${lastName}`}</h2>
          <div className="info">
            <div className="info-block">
              <span className="label">Member No.</span>
              <span className="value">{memberId}</span>
            </div>
            <div className="info-block">
              <span className="label">Email Address</span>
              <span className="value">{email}</span>
            </div>
            <div className="info-block">
              <span className="label">Phone Number</span>
              <span className="value">{phone}</span>
            </div>
          </div>
          <div className="actions">
            <Button onClick={() => setShowModal(true)}>Update Info</Button>
          </div>
        </CardContent>
      </Card>
    </Wrapper>
  );
};

AccountProfile.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(AccountProfile);

// Styles
const { breakpoints, colors, fonts } = theme;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContent = styled.section`
  text-align: left;

  h4 {
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
  }

  .divider {
    margin: 0.5rem 0 1.5rem;
    background-color: ${colors.dividerGray};
    height: 1px;
  }

  h2 {
    margin: 0 0 1.5rem;
    font-size: ${fonts.size.xl};
    font-weight: ${fonts.weight.medium};
    line-height: 1em;
    color: ${colors.black};
  }

  h5.subtitle {
    margin: 0.5rem 0;
    padding: 0;
    color: ${colors.text.gray};
    font-weight: ${fonts.weight.regular};
    font-size: ${fonts.size.s};
    line-height: 1.4;
  }

  .info {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 1.5rem;
    font-weight: ${fonts.weight.medium};

    div.info-block {
      display: flex;
      flex-direction: column;
      margin-right: 4rem;

      .label {
        color: ${colors.text.gray};
        font-size: ${fonts.size.s};
        font-weight: ${fonts.weight.light};
        margin-bottom: 0.5rem;
      }

      .value {
        font-weight: ${fonts.weight.regular};
        margin-bottom: 0.5rem;
        line-height: 1.4;
      }

      @media (max-width: ${breakpoints.mobile}) {
        flex-direction: column;
        margin: 0 0 1rem 0;
      }
    }

    @media (max-width: ${breakpoints.mobile}) {
      flex-direction: column;
      margin-bottom: 1rem;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .actions {
      width: 100%;
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }
`;
