export const DELETE_USER_EVENT = 'DELETE_USER_EVENT';
export const DELETE_USER_EVENT_LINK = 'DELETE_USER_EVENT_LINK';
export const DELETE_USER_EVENT_SUCCESS = 'DELETE_USER_EVENT_SUCCESS';
export const EDIT_USER_EVENT_SUCCESS = 'EDIT_USER_EVENT_SUCCESS';
export const HYDRATE_USER = 'HYDRATE_USER';
export const RESET_IN_PROGRESS_CHANGES = 'RESET_IN_PROGRESS_CHANGES';
export const SET_USER = 'SET_USER';
export const SET_USER_AUTHENTICATION = 'SET_USER_AUTHENTICATION';
export const LOG_OUT = 'LOG_OUT';
export const SET_USER_SITE_INFO = 'SET_USER_SITE_INFO';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_IN_USER = 'SIGN_IN_USER';
export const SUBMIT_NEW_USER_EVENT = 'SUBMIT_NEW_USER_EVENT';
export const SUBMIT_NEW_USER_EVENT_SUCCESS = 'SUBMIT_NEW_USER_EVENT_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const USER_IS_LOADING = 'USER_IS_LOADING';
