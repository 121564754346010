import React from 'react';
import PropTypes from 'prop-types';

// Components
import EnterCreditCardInfoModal from '../../SubscriptionModals/EnterCreditCardInfoModal';
import SuccessModal from '../../SubscriptionModals/SuccessModal';

// Styled Components
import Scrim from '../../styles/Scrim';
import useSubscriptionFlow from '../../utils/Hooks/useSubscriptionFlow';

// *********************************************** //

const UpdatePaymentInfoFlow = ({ handleClose }) => {
  const { billingCycle, incrementStep, step } = useSubscriptionFlow();

  return (
    <>
      <Scrim onClick={handleClose} />
      {step === 0 && (
        <EnterCreditCardInfoModal
          handleClose={handleClose}
          nextStep={incrementStep}
          selectedPlan={billingCycle}
        />
      )}
      {step === 1 && (
        <SuccessModal handleClose={handleClose}>
          <p>You have successfully updated your payment information.</p>
        </SuccessModal>
      )}
    </>
  );
};

UpdatePaymentInfoFlow.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default UpdatePaymentInfoFlow;
