import styled from 'styled-components/macro';
import theme from '../themes';

const { breakpoints, colors, fonts } = theme;

export const SidebarContainer = styled.div`
  position: sticky;
  top: 6rem;
  padding-bottom: 3rem;

  @media (max-width: ${breakpoints.desktop}) {
    position: static;
    padding: 0;
  }
`;

export const StyledSidebar = styled.section`
  font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  text-shadow: #fff 0 1px 1px;

  h1 {
    margin: 0 0 2rem;
    font-family: freight-display-pro;
    font-size: ${fonts.size.xxl};
    font-weight: ${fonts.weight.black};
    line-height: 1em;

    @media (max-width: ${breakpoints.desktop}) {
      text-align: center;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: ${fonts.size.md};
    font-weight: ${fonts.weight.regular};

    li {
      padding: 0.75rem 0;
      line-height: 1rem;
      border-bottom: 1px solid ${colors.darkerDividerGray};

      a {
        text-decoration: none;
        color: ${colors.text.darkGray};
      }

      a:hover {
        color: ${colors.green};
      }

      a.active {
        font-weight: ${fonts.weight.bold};
        color: ${colors.green};
      }

      &:hover {
        color: ${colors.text.blue};
      }
    }

    li:first-of-type {
      border-top: 1px solid ${colors.darkerDividerGray};
    }

    li:last-of-type {
      border-bottom: none;
    }
  }
`;
