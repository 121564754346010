import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

// Styled Components
import theme from '../styles/themes';

// Theme
const { colors } = theme;

// Styles
const StyleWrapper = styled.div`
  h1,
  h2,
  h3 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2em;
  }

  ul,
  ol {
    padding-left: 2rem;
  }

  li {
    margin: 0 0 0.5rem;
    font-size: 1.125rem;
    font-weight: 200;
    line-height: 1.4;
    color: ${colors.darkGray};
  }

  p {
    margin: 0 0 1rem;
    font-size: 1.125rem;
    font-weight: 200;
    line-height: 1.4;
    color: ${colors.darkGray};
  }

  a {
    font-weight: 500;
    text-decoration: underline;
    text-decoration-color: ${colors.darkGreen};
    color: ${colors.darkGreen};
  }

  img {
    max-width: 100%;
  }
`;

// *********************************************** //

const Markdown = ({ input }) => (
  <StyleWrapper>
    <ReactMarkdown source={input} />
  </StyleWrapper>
);

Markdown.propTypes = {
  input: PropTypes.string.isRequired,
};

export default Markdown;
