import React, { useEffect, useState } from 'react';
import { AlertCircle } from 'react-feather';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

// Components
import Card from '../../Card';
import LoadingIndicator from '../../utils/LoadingIndicator';
import ProFeatureLinks from '../../ProFeatureLinks';

// Styled Components
import { TextButton, Button, DangerTextButton } from '../../styles/Buttons';
import theme from '../../styles/themes';

// Styles
const { breakpoints, fonts, colors } = theme;

const CardContent = styled.section`
  text-align: left;

  h4 {
    margin: 0;
    font-size: ${fonts.size.s};
    font-weight: ${fonts.weight.demi};
    text-transform: uppercase;
  }

  .divider {
    margin: 0.5rem 0 1.5rem;
    background-color: ${colors.dividerGray};
    height: 1px;
  }

  .plan-title {
    display: flex;
    align-items: baseline;

    h2 {
      margin: 0;
      font-size: ${fonts.size.xl};
      font-weight: ${fonts.weight.medium};
      line-height: 1em;
      color: ${props => (props.hasSubscription ? colors.plum : colors.black)};
    }

    .warning {
      display: flex;
      align-items: center;
      margin-left: 1.5rem;

      svg {
        fill: ${colors.roles.danger};
        stroke: ${colors.white};
        margin-right: 0.5rem;
      }

      h4 {
        font-size: ${fonts.size.s};
        line-height: 1em;
        color: ${colors.roles.danger};
      }
    }
  }

  .upgrade-header {
    margin-bottom: 2rem;

    h1 {
      margin: 0 0 1.5rem;
      font-size: 2rem;
      font-weight: 900;
    }
  }

  h5.subtitle {
    margin: 0.5rem 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    color: ${colors.text.darkGray};

    span {
      font-weight: ${fonts.weight.demi};
      color: ${colors.plum};
    }
  }

  .info {
    display: flex;
    flex-flow: row wrap;
    margin: 1rem 0 2rem;
    font-weight: ${fonts.weight.medium};

    .feature-list {
      ul {
        padding-left: 0;
        margin-top: 0;
        list-style: none;

        li {
          display: flex;
          align-items: center;
          padding: 1rem;

          &:first-of-type {
            padding-top: 0;
          }

          .feature-icon img {
            height: 2rem;
          }

          .feature-description {
            margin-left: 1rem;
            font-size: ${fonts.size.s};
            color: ${colors.text.darkGray};
            display: flex;
            flex-direction: column;

            h5 {
              margin: 0;
              line-height: 1.4;
              font-weight: ${fonts.weight.medium};
              color: ${colors.plum};
              font-size: ${fonts.size.md};
            }

            span {
              font-weight: ${fonts.weight.light};
            }
          }
        }
      }
    }

    .info-block:first-child {
      .value {
        margin-bottom: 0rem;
      }
    }

    .info-block {
      display: flex;
      flex-direction: column;
      margin-right: 4rem;

      .label {
        margin-bottom: 0.5rem;
        font-size: ${fonts.size.s};
        font-weight: ${fonts.weight.light};
        color: ${colors.text.gray};
      }

      .value {
        margin-bottom: 0.5rem;
        font-weight: ${fonts.weight.regular};
        line-height: 1.4;
      }

      .edit-link {
        padding-top: 0.5rem;
        font-size: ${fonts.size.s};

        &.link {
          padding: 0;
        }
      }

      @media (max-width: ${breakpoints.mobile}) {
        margin: 0 0 1rem 0;

        .edit-link {
          margin-bottom: 1rem;
        }
      }
    }

    @media (max-width: ${breakpoints.mobile}) {
      flex-direction: column;
      margin-bottom: 1rem;
    }
  }

  .table-container {
    margin-bottom: 1.5rem;
  }

  .actions {
    display: flex;
    align-items: center;

    button {
      cursor: pointer;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .actions {
      width: 100%;
      flex-direction: column;

      button {
        width: 100%;

        &:first-child {
          margin-bottom: 1rem;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
`;

// *********************************************** //

const UserSubscriptionCard = ({
  handleShowCancelModal,
  handleShowSubscriptionModal,
  handleShowUpdateBillingCycle,
  handleShowUpdatePaymentInfo,
  hasAndroidSub,
  hasIosSub,
  subscription,
  isLoading,
}) => {
  const planLabel = (() => {
    if (subscription?.plan === 'month') {
      return 'Professional - Monthly';
    }
    if (subscription?.plan === 'quarter') {
      return 'Professional - Quarterly';
    }
    return 'N/A';
  })();

  const [showProPitch, setShowProPitch] = useState(false);
  const [hasInAppSub, setHasInAppSub] = useState(false);

  useEffect(() => {
    if ((isEmpty(subscription) && !hasInAppSub) || subscription?.gift) {
      setShowProPitch(true);
    } else {
      setShowProPitch(false);
    }
  }, [subscription, hasInAppSub]);

  useEffect(() => {
    if (hasIosSub || hasAndroidSub) {
      setHasInAppSub(true);
    } else {
      setHasInAppSub(false);
    }
  }, [hasIosSub, hasAndroidSub]);

  const buttonText = (() => {
    if (!showProPitch && !subscription?.autoRenew) {
      return 'Renew Subscription';
    }

    if (!showProPitch && subscription?.autoRenew) {
      return 'Update Payment Method';
    }

    return 'Upgrade Now';
  })();

  return (
    <Card width="100%" padding="32px" data-testid="user-subscription-card">
      {isLoading ? (
        <LoadingIndicator dark />
      ) : (
        <CardContent hasSubscription={subscription?.subscriptionStatus}>
          {!showProPitch ? (
            <>
              <h4 className="section-title">My Subscription</h4>
              <div className="divider" />
              <div className="plan-title">
                <h2>PRO Account</h2>
                {!subscription?.autoRenew &&
                  !!subscription?.endDate &&
                  moment(subscription?.endDate).isSameOrAfter(
                    moment(),
                    'day'
                  ) && (
                    <div className="warning">
                      <AlertCircle size={20} />
                      <h4>
                        (Expires{' '}
                        {moment(subscription?.endDate).format('MM/DD/YYYY')})
                      </h4>
                    </div>
                  )}
              </div>
            </>
          ) : (
            <div className="upgrade-header">
              <h1>
                {subscription?.gift
                  ? 'Keep Your PRO Subscription'
                  : 'Upgrade to PRO'}
              </h1>
              <h5 className="subtitle">
                {subscription?.gift ? (
                  <p>
                    You currently have a gift subscription that ends on{' '}
                    <span>
                      {moment(subscription?.endDate).format('MMMM DD, YYYY')}
                    </span>
                    . If you don't set up a <span>PRO subscription</span> by
                    then, you'll lose access to all these amazing features:
                  </p>
                ) : (
                  `With a PRO Subscription, you can take your business
                to the next level. We’ll tell you what to do and when to do it
                so you can focus on your members.`
                )}
              </h5>
            </div>
          )}
          <div className="info">
            {showProPitch ? (
              <ProFeatureLinks singleColumnOnMobile />
            ) : (
              <>
                {hasInAppSub ? (
                  <div className="info-block">
                    {hasIosSub && (
                      <span className="value">
                        Your subscription can be handled through the App Store
                        on your iPhone.
                      </span>
                    )}
                    {hasAndroidSub && (
                      <span className="value">
                        Your subscription can be handled through the Google Play
                        Store on your Android phone.
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="info-block">
                    <span className="label">Billing Plan</span>
                    <span className="value">
                      {planLabel}{' '}
                      {!subscription?.autoRenew &&
                        !subscription?.gift &&
                        '(canceled)'}
                    </span>
                    {subscription?.autoRenew && (
                      <TextButton
                        type="button"
                        className="edit-link"
                        onClick={handleShowUpdateBillingCycle}
                      >
                        Change
                      </TextButton>
                    )}
                  </div>
                )}
                {subscription?.autoRenew && (
                  <>
                    <div className="info-block">
                      <span className="label">Next Billing Date</span>
                      <span className="value">
                        {moment(subscription?.endDate).format('MMMM DD, YYYY')}
                      </span>
                      {/* eslint-disable-next-line */}
                      <a
                        href={subscription?.stripePortalUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <TextButton type="button" className="edit-link link">
                          View Payment History
                        </TextButton>
                      </a>
                    </div>
                    <div className="info-block">
                      <span className="label">Payment Method</span>
                      <span className="value">
                        xxxx-xxxx-xxxx-{subscription?.billingData?.cardLast4}
                      </span>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          {!hasInAppSub && (
            <div className="actions">
              <Button
                className="pro"
                type="button"
                onClick={
                  !showProPitch && subscription?.autoRenew
                    ? handleShowUpdatePaymentInfo
                    : handleShowSubscriptionModal
                }
              >
                {buttonText}
              </Button>
              {subscription?.autoRenew && (
                <DangerTextButton onClick={handleShowCancelModal}>
                  Cancel Subscription
                </DangerTextButton>
              )}
            </div>
          )}
        </CardContent>
      )}
    </Card>
  );
};

const mapStateToProps = ({ subscriptions }) => ({
  hasAndroidSub: subscriptions.appSubs.android,
  hasIosSub: subscriptions.appSubs.ios,
  subscription: subscriptions.userSub,
});

UserSubscriptionCard.propTypes = {
  handleShowCancelModal: PropTypes.func.isRequired,
  handleShowSubscriptionModal: PropTypes.func.isRequired,
  handleShowUpdateBillingCycle: PropTypes.func.isRequired,
  handleShowUpdatePaymentInfo: PropTypes.func.isRequired,
  hasAndroidSub: PropTypes.bool,
  hasIosSub: PropTypes.bool,
  subscription: PropTypes.shape({
    autoRenew: PropTypes.bool,
    billingData: PropTypes.shape({
      cardLast4: PropTypes.string,
    }),
    endDate: PropTypes.string,
    gift: PropTypes.bool,
    plan: PropTypes.string,
    subscriptionStatus: PropTypes.string,
    stripePortalUrl: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

UserSubscriptionCard.defaultProps = {
  hasAndroidSub: false,
  hasIosSub: false,
};

export default connect(mapStateToProps)(UserSubscriptionCard);
