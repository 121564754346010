import styled from 'styled-components';
import theme from '../themes';

const { breakpoints, colors, fonts } = theme;

const ModalContent = styled.div`
  padding: 1.5rem 0;

  .row {
    margin: 0;
    margin-top: 0.75rem;

    @media (max-width: ${breakpoints.mobile}) {
      margin: 0;
    }
  }

  .form-control {
    display: flex;
    text-align: left;
    flex-direction: column;

    label {
      margin: 0.5rem 0;
      padding: 0;
      color: ${colors.text.darkGray};
      font-weight: ${fonts.weight.regular};
      font-size: ${fonts.size.xs};
      line-height: 1.4;
      text-transform: uppercase;
    }

    input {
      padding: 1rem;
      font-size: ${fonts.size.s};
      border: 1px solid ${colors.darkerDividerGray};
      border-radius: 4px;
    }
  }

  .text-area-wrapper {
    font-size: ${fonts.size.xs};
    text-align: left;

    textarea {
      width: 100%;
      margin-top: 0.5rem;
      padding: 1rem;
      font-size: ${fonts.size.s};
      border: 1px solid ${colors.darkerDividerGray};
      border-radius: 4px;
      resize: none;

      &::placeholder {
        font-size: ${fonts.size.s};
        font-weight: ${fonts.weight.regular};
      }
    }
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-1 {
    flex: 1;
  }

  input {
    &.margin {
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .flex {
      justify-content: space-between;
    }
  }
`;

export default ModalContent;
