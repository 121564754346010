import React, { useState, useContext } from 'react';
import { ChevronDown, Menu } from 'react-feather';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router';

// Assets
import { logo } from '../../images';

// Utils
import { getFromLocalStorage } from '../../modules/localStorage';
import { logOut } from '../../actions';
import { MainContext } from '../../MainContext';

// Components
import MenuDrawer from '../MenuDrawer';
import UpgradeModal from '../UpgradeModal';

// Styled Components
import { Button } from '../styles/Buttons';
import theme from '../styles/themes';

// *********************************************** //

const Header = ({ logout, hasSubscription, showWebsiteLink }) => {
  const { dispatch } = useContext(MainContext);
  const history = useHistory();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [isMenuDrawerOpen, setisMenuDrawerOpen] = useState(false);
  const onClickLogout = () => {
    localStorage.clear();
    logout();
  };

  const onClickUpgrade = () => {
    history.push('/account/subscriptions/?referrer=upgrade_modal');
    setShowUpgradeModal(false);
  };

  const accessToken = getFromLocalStorage('accessToken');

  return (
    <Container isAuthenticated={!!accessToken}>
      <MenuDrawer
        open={isMenuDrawerOpen}
        showUpgrade={() => setShowUpgradeModal(true)}
        logout={onClickLogout}
        closeDrawer={() => {
          dispatch({ type: 'UPDATE_MODAL_IS_OPEN', payload: false });
          setisMenuDrawerOpen(false);
        }}
        hasSubscription={hasSubscription}
      />
      <div className="row justify-content-between">
        <div className="logo">
          <div className="box">
            <button
              type="button"
              className="hamburger"
              onClick={() => {
                dispatch({ type: 'UPDATE_MODAL_IS_OPEN', payload: true });
                setisMenuDrawerOpen(true);
              }}
            >
              <Menu />
            </button>
          </div>
          <div className="box">
            <Link to="/">
              <img src={logo} alt="Life Steps logo" />
            </Link>
          </div>
          <div className="box" />
        </div>
        {accessToken && (
          <>
            {!hasSubscription && (
              <UpgradeModal
                open={showUpgradeModal}
                cancelFunc={() => setShowUpgradeModal(false)}
                ctaFunc={onClickUpgrade}
              />
            )}
            <div className="row nav-menu-container">
              <nav className="nav-menu">
                <ul className="main-nav">
                  <li>
                    <NavLink to="/" exact>
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/resources">Resources</NavLink>
                  </li>
                  <li className="dropdown-trigger">
                    <NavLink to="/account/profile">My Account</NavLink>
                    <ChevronDown />
                    <div>
                      <ul className="dropdown">
                        <li className="dropdown-item">
                          <NavLink to="/account/profile">
                            Manage Profile
                          </NavLink>
                        </li>
                        <li className="dropdown-item">
                          <NavLink to="/account/subscriptions">
                            Manage Subscriptions
                          </NavLink>
                        </li>
                        {showWebsiteLink && (
                          <li className="dropdown-item">
                            <NavLink to="/account/site">Manage Website</NavLink>
                          </li>
                        )}
                        <li className="dropdown-item">
                          <button type="button" onClick={onClickLogout}>
                            Log out
                          </button>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </nav>
              {!hasSubscription && (
                <Button onClick={() => setShowUpgradeModal(true)}>
                  Upgrade to pro
                </Button>
              )}
            </div>
          </>
        )}
      </div>
      <section className="sub-header" />
    </Container>
  );
};

Header.propTypes = {
  hasSubscription: PropTypes.bool.isRequired,
  showWebsiteLink: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ subscriptions, user }) => ({
  hasSubscription:
    !isEmpty(subscriptions.userSub) ||
    subscriptions?.userSub?.subscriptionStatus !== 'active',
  showWebsiteLink: user.hasSubscription || user.onBabyStepsTeam,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logOut(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

// Styles
const { breakpoints, colors, fonts, shadows } = theme;

const Container = styled.header`
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
  font-size: ${fonts.size.md};
  background-color: ${props =>
    props.isAuthenticated ? colors.white : '#e4f1f6'};
  z-index: 10;

  .logo {
    display: flex;
    align-items: center;
    font-family: freight-display-pro, serif;
    font-size: ${fonts.size.xl};
    font-weight: ${fonts.weight.black};

    a {
      color: ${colors.black};
      text-decoration: none;
    }

    img {
      max-width: 8.5rem;
    }

    .hamburger {
      display: none;
    }

    @media (max-width: ${breakpoints.desktop}) {
      button.hamburger {
        display: block;
        margin-right: 1rem;
        padding: 0.2rem 0 0;
        background: none;
        border: none;
        cursor: pointer;
      }
    }

    @media (max-width: ${breakpoints.mobile}) {
      justify-content: center;
      width: 100%;

      button.hamburger {
        margin-right: auto;
        background: none;
        border: none;
      }

      .box {
        display: flex;
        justify-content: center;
        flex: 1;
      }

      .box:first-child {
        margin-right: auto;
      }

      .box:last-child {
        margin-left: auto;
      }
    }
  }

  .nav-menu-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .nav-menu {
      display: flex;
      align-items: center;

      ul.main-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0.5rem 0 0;
        padding: 0;
        list-style: none;

        li:first-of-type {
          margin-left: 0;
        }

        li {
          display: flex;
          align-items: center;
          margin-right: 1.5rem;
          line-height: 1em;

          a {
            padding: 0.25rem 0;
            font-size: ${fonts.size.s};
            font-weight: ${fonts.weight.regular};
            text-decoration: none;
            color: ${colors.black};
            border-bottom: 2px solid transparent;
          }

          a:hover,
          a.active {
            color: ${colors.green};
            text-decoration: none;
          }

          a.active {
            border-bottom: 2px solid ${colors.green};
          }

          div {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            background-color: ${colors.white};
            box-shadow: ${shadows.light};
            border-radius: 4px;
            z-index: 999;

            &:hover {
              display: block;
            }

            ul.dropdown {
              margin: 0;
              padding: 1.5rem 2rem;
              list-style: none;
              white-space: nowrap;

              li.dropdown-item {
                margin: 0;
                padding-bottom: 0.5rem;

                a {
                  font-weight: ${fonts.weight.light};
                }

                button {
                  padding: 0;
                  font-family: sofia-pro, sans-serif;
                  font-size: ${fonts.size.s};
                  font-weight: ${fonts.weight.light};
                  background: none;
                  border: none;

                  &:hover {
                    text-decoration: none;
                    color: ${colors.green};
                    cursor: pointer;
                  }
                }
              }
            }
          }

          svg {
            height: 18px;
            width: 18px;
            margin: 1px 0 0 4px;
          }

          &.dropdown-trigger {
            position: relative;

            &:hover div {
              display: block;
            }
          }
        }
      }
    }

    button {
      display: inline-block;
    }

    @media (max-width: ${breakpoints.desktop}) {
      .nav-menu {
        display: none;
      }
    }

    @media (max-width: ${breakpoints.mobile}) {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 1rem;
  }
`;
