import styled from 'styled-components';

const Scrim = styled.button`
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1000;
`;

export default Scrim;
