import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';

const RenderInput = ({
  id,
  fieldType,
  labelText,
  name,
  onChange,
  onClickRemove,
  pattern,
  placeholder,
  showRemoveButton,
  subLabel,
  value,
  withLabel = true,
}) => (
  <Input
    id={id}
    fieldType={fieldType}
    labelText={labelText}
    name={name}
    onChange={onChange}
    onClickRemove={onClickRemove}
    placeholder={placeholder}
    pattern={pattern}
    showRemoveButton={showRemoveButton}
    subLabel={subLabel}
    value={value}
    withLabel={withLabel}
  />
);

RenderInput.defaultProps = {
  id: null,
  fieldType: null,
  labelText: null,
  name: null,
  onChange: null,
  onClickRemove: () => null,
  pattern: null,
  placeholder: null,
  showRemoveButton: false,
  subLabel: null,
  value: '',
  withLabel: true,
};

RenderInput.propTypes = {
  id: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClickRemove: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  fieldType: PropTypes.oneOf([
    'text',
    'tel',
    'email',
    'url',
    'password',
    'number',
  ]),
  showRemoveButton: PropTypes.bool,
  subLabel: PropTypes.string,
  value: PropTypes.string,
  withLabel: PropTypes.bool,
};

export default RenderInput;
