import React from 'react';
import PropTypes from 'prop-types';
import { CheckSquare, CreditCard as CCIcon, Square } from 'react-feather';
import includes from 'lodash/includes';

// Assets
import {
  amex,
  discover,
  jcb,
  mastercard,
  unionpay,
  visa,
} from '../../../../images/creditCards';

// Styled Components
import { ActionButtons, CardInfo, CreditCardStyles } from './styles';
import { DangerTextButton } from '../../../styles/Buttons';
import theme from '../../../styles/themes';

const { colors } = theme;

// *********************************************** //

export default function CreditCard({
  card,
  onClick,
  onClickDelete,
  removeable,
  selected,
}) {
  const cardList = {
    'american express': amex,
    discover,
    jcb,
    mastercard,
    unionpay,
    visa,
  };
  const CardIcon = cardList[card.cardType?.toLowerCase()];

  // This boolean determines whether or not we have an icon for a given
  // cardType
  const unknownCardType = !includes(
    Object.keys(cardList),
    card.cardType?.toLowerCase()
  );

  return (
    <CreditCardStyles selected={selected} onClick={() => onClick(card)}>
      <CardInfo>
        {selected ? <CheckSquare color={colors.plum} /> : <Square />}
        {unknownCardType ? (
          <CCIcon className="card-icon" />
        ) : (
          <img
            className="card-icon"
            src={CardIcon}
            alt={`${card.cardType} icon`}
          />
        )}
        <div className="card-number">xxxx-xxxx-xxxx-{card.cardLast4}</div>
      </CardInfo>
      {removeable && (
        <ActionButtons>
          <DangerTextButton
            style={{ padding: '0.5rem', cursor: 'pointer' }}
            onClick={onClickDelete}
          >
            Remove
          </DangerTextButton>
        </ActionButtons>
      )}
    </CreditCardStyles>
  );
}

CreditCard.propTypes = {
  card: PropTypes.shape({
    cardLast4: PropTypes.string.isRequired,
    cardType: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  removeable: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
};
