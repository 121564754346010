import React from 'react';
import { File } from 'react-feather';
import Image from 'react-image';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

// Components
import Card from '../Card';
import LoadingIndicator from '../utils/LoadingIndicator';

// Styled Components
import Divider from '../styles/Divider';
import theme from '../styles/themes';

// Styles
const { breakpoints, colors, fonts, shadows } = theme;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  background: ${colors.white};
  box-shadow: ${shadows.light};
  overflow: hidden;
  max-width: calc(
    (100% - ${props => props.cardsInRow - 1}rem) / ${props => props.cardsInRow}
  );
  border-radius: 10px;

  @media (max-width: ${breakpoints.desktop}) {
    max-width: calc((100% - 1rem) / 2);
  }

  @media (max-width: ${breakpoints.mobile}) {
    max-width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .card-content {
    margin-bottom: 0.5rem;
    padding: 1rem;
    flex: 1;

    .card-img {
      button {
        padding: 0;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
      }

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .card-header {
      margin-bottom: 0.5rem;

      .card-title {
        margin-top: 1.5rem;
        font-size: ${fonts.size.lg};
        font-weight: ${fonts.weight.demi};
        line-height: 1.4;
      }

      .card-subtitle {
        font-size: ${fonts.size.s};
        font-weight: ${fonts.weight.medium};
        color: ${colors.green};
      }
    }

    .card-description {
      text-align: center;
      font-size: ${fonts.size.s};
      font-weight: ${fonts.weight.regular};
      line-height: 1.4;
      color: ${colors.text.gray};
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;

    button:first-of-type {
      border-left: none;
    }

    button,
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${props => (props.secondaryButton ? '50%' : '100%')};
      padding: 1rem 0;
      font-size: ${fonts.size.s};
      font-weight: ${fonts.weight.regular};
      text-decoration: none;
      background-color: ${colors.white};
      color: ${colors.green};
      border: none;
      border-left: 1px solid ${colors.text.lightGray};
      border-radius: 0 0 10px 10px;
      cursor: pointer;

      svg {
        width: 18px;
        height: 18px;
        margin-left: 0.5rem;
      }

      &:hover {
        background: ${colors.nearlyWhite};
      }

      &:active {
        background: ${colors.lightestGreen};
      }
    }
  }
`;

export const ProBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 0;
  width: 120px;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  background: ${colors.plum};
  color: ${colors.white};
  box-shadow: ${shadows.light};
  transform: rotate(45deg) translate(32px, -10px);
`;

const ListCard = ({
  cardsInRow,
  explainProContent,
  primaryImageUrls,
  hasSubscription,
  paidContent,
  openViewResourceModal,
  padding,
  slug,
  subtitle,
  title,
}) => (
  <Wrapper cardsInRow={cardsInRow}>
    <Card maxWidth="100%" padding={padding}>
      <Container>
        {paidContent && <ProBadge>Pro</ProBadge>}
        <div className="card-content">
          <div className="card-img">
            <button
              type="button"
              onClick={
                paidContent && !hasSubscription
                  ? explainProContent
                  : () => openViewResourceModal(slug)
              }
            >
              <Image
                src={[
                  primaryImageUrls?.thumbUrl,
                  'https://support-stream-app-assets.s3.us-west-2.amazonaws.com/no_image.png',
                ]}
                alt={`${title} header`}
                loader={<LoadingIndicator />}
              />
            </button>
          </div>
          <div className="card-header">
            <div className="card-title">{title}</div>
            {subtitle && <span className="card-subtitle">{subtitle}</span>}
          </div>
        </div>
        <Divider />
        <div className="buttons">
          {paidContent && !hasSubscription ? (
            <button type="button" onClick={explainProContent}>
              How do I view Pro Content?
            </button>
          ) : (
            <button type="button" onClick={() => openViewResourceModal(slug)}>
              View Resource <File />
            </button>
          )}
        </div>
      </Container>
    </Card>
  </Wrapper>
);

ListCard.defaultProps = {
  cardsInRow: 2,
  openViewResourceModal: () => {},
  padding: 0,
  subtitle: '',
  explainProContent: () => {},
};

ListCard.propTypes = {
  cardsInRow: PropTypes.number,
  hasSubscription: PropTypes.bool.isRequired,
  primaryImageUrls: PropTypes.object.isRequired,
  openViewResourceModal: PropTypes.func,
  padding: PropTypes.string,
  slug: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  paidContent: PropTypes.bool.isRequired,
  explainProContent: PropTypes.func,
};

export default ListCard;
