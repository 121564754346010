import React, { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import map from 'lodash/map';
import { ChevronDown } from 'react-feather';
import uuidv4 from 'uuid/v4';

import { StyledSidebar } from '../styles/Sidebar';
import theme from '../styles/themes';
import { titleCase } from '../utils/helper-functions';

const Sidebar = ({ selectedItem, items, onClick, title }) => {
  const history = useHistory();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState();

  const dropdownOptions = map(items, item => {
    if (item.name && isEmpty(item.name.trim())) {
      return false;
    }

    return (
      <option
        key={item.id || uuidv4()}
        value={item.uuid || item.url || JSON.stringify(item)}
      >
        {titleCase(item.title || item.name || item.label)}
      </option>
    );
  });

  const listItems = useMemo(
    () =>
      map(items, (item, idx) => {
        if (item.name && isEmpty(item.name.trim())) {
          return false;
        }

        const className = (() => {
          if (selectedItem) {
            if (item.uuid === selectedItem) {
              return 'active';
            }
            if (item.id === selectedItem.id) {
              return 'active';
            }
          }

          return '';
        })();

        return (
          <li
            key={item.uuid || uuidv4()}
            onClick={() => {
              if (onClick) {
                onClick(item.uuid || item.url || item);
              } else {
                history.push(item.url);
              }
            }}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                onClick(item.uuid);
              }
            }}
            role="menuitem"
            tabIndex={idx}
            className={className}
          >
            {location.pathname.includes('/account') ? (
              <NavLink to={item.url}>{item.title}</NavLink>
            ) : (
              <span>{titleCase(item.title || item.name)}</span>
            )}
          </li>
        );
      }),
    [items, selectedItem, location.pathname, onClick, history]
  );

  return (
    <StyledSidebar>
      <div className="sidebar">
        <h1>{title}</h1>
        <List>{listItems}</List>
        <Flex>
          <ListSelect
            onChange={e => {
              if (onClick) {
                onClick(e.target.value);
                setSelectedOption(e.target.value);
              } else {
                setSelectedOption(e.target.value);
                history.push(e.target.value);
              }
            }}
            value={selectedOption}
          >
            {dropdownOptions}
          </ListSelect>
          <span className="icon">
            <ChevronDown />
          </span>
        </Flex>
      </div>
    </StyledSidebar>
  );
};

Sidebar.defaultProps = {
  selectedItem: null,
  onClick: null,
};

Sidebar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string }))
    .isRequired,
  onClick: PropTypes.func,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
};

export default Sidebar;

// Styles
const { breakpoints, colors, fonts } = theme;

const List = styled.ul`
  @media (max-width: ${breakpoints.desktop}) {
    display: none;
  }

  li {
    cursor: pointer;
    &.active {
      font-weight: ${fonts.weight.bold};
      color: ${colors.green};
    }

    &:focus {
      outline: none;
    }
  }
`;

const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${colors.darkerDividerGray};
  border-radius: 4px;

  .icon {
    position: absolute;
    right: 0.75rem;
    margin-top: 3px;
    color: ${colors.green};
    pointer-events: none;
  }

  @media (min-width: ${breakpoints.desktop}) {
    display: none;
  }

  @media (min-width: ${breakpoints.mobile}) {
    margin: 0 auto 1rem;
    max-width: 280px;
  }
`;

const ListSelect = styled.select`
  width: 100%;
  font-size: ${fonts.size.s};
  font-weight: ${fonts.weight.light};
  color: ${colors.darkGray};
  outline: none;
  background: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  @media (min-width: ${breakpoints.mobile}) {
    justify-self: flex-end;
  }
`;
