import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Utils
import { deleteSubscription } from '../../../actions';

// Assets
import cancelSubscriptionTitle from '../../../images/sub-cancel-subscription.svg';

// Components
import ProFeatureLinks from '../../ProFeatureLinks';
import SubscriptionModal from '../SubscriptionModal';

// Styled Components
import { Button, ModalCancelButton } from '../../styles/Buttons';
import { ButtonWrapper, Instructions } from '../SubscriptionModal/styles';

// *********************************************** //

const CancelSubscriptionModal = ({
  deleteUserSubscription,
  handleClose,
  nextStep,
}) => {
  // Cancel user sub and increment flow step
  const cancelUserSubscription = () => {
    deleteUserSubscription();
    nextStep();
  };

  return (
    <SubscriptionModal
      title="Cancel Subscription"
      titleImageSrc={cancelSubscriptionTitle}
    >
      <Instructions>
        You are about to <span>LOSE ACCESS</span> to all <span>PRO</span>{' '}
        features.
      </Instructions>
      <ProFeatureLinks />
      <Instructions>Are you sure you want to cancel?</Instructions>
      <ButtonWrapper>
        <Button danger onClick={cancelUserSubscription}>
          Cancel Subscription
        </Button>
        <ModalCancelButton onClick={handleClose}>Nevermind</ModalCancelButton>
      </ButtonWrapper>
    </SubscriptionModal>
  );
};

CancelSubscriptionModal.propTypes = {
  deleteUserSubscription: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user }) => ({
  hasSubscription: user.hasSubscription,
  user,
});

const mapDispatchToProps = dispatch => ({
  deleteUserSubscription: () => dispatch(deleteSubscription()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelSubscriptionModal);
