import { useState } from 'react';

export default function useSubscriptionFlow() {
  /**
   * Handle Flow Progression
   */
  const [step, setStep] = useState(0);

  // Select billing cycle value and function
  const [billingCycle, setBillingCycle] = useState('');

  // Function to move forward in the flow
  const incrementStep = () => {
    setStep(step + 1);
  };

  // Function to move backwards in the flow
  const decrementStep = () => {
    if (step === 0) {
      return;
    }

    setStep(step - 1);
  };

  // Function to move to payment info
  function continueToPaymentInfo(option) {
    incrementStep();
    setBillingCycle(option);
  }

  return {
    billingCycle,
    continueToPaymentInfo,
    decrementStep,
    incrementStep,
    setBillingCycle,
    setStep,
    step,
  };
}
