import React, { useEffect, useReducer } from 'react';
import { AlertCircle } from 'react-feather';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import size from 'lodash/size';

// Assets
import paymentInfoTitle from '../../../images/sub-payment-info.svg';

// Utils
import { clearSubscriptionError, createSubscription } from '../../../actions';
import LifeSteps from '../../../modules/LifeStepsAPI';

// Components
import CreditCard from './CreditCard';
import LoadingIndicator from '../../utils/LoadingIndicator';
import NewCreditCardForm from './NewCreditCardForm';
import SubscriptionModal from '../SubscriptionModal';

// Styled Components
import { Button, ModalCancelButton } from '../../styles/Buttons';
import { ButtonWrapper, Instructions } from '../SubscriptionModal/styles';
import { CardListStyles, Error, FormContent } from './styles';

// *********************************************** //

function reducer(state, action) {
  const { payload, type } = action;
  switch (type) {
    case 'SET_SELECTED_CARD':
      return {
        ...state,
        selectedCard: payload.card,
      };

    case 'UPDATE_VALUE':
      return {
        ...state,
        [payload.name]: payload.value,
      };

    default:
      return state;
  }
}

const EnterCreditCardInfoModal = ({
  clearError,
  giftRecipient,
  handleClose,
  nextStep,
  selectedPlan,
  submitCreateSubscriptionRequest,
  subscriptions: { error, user: userSub },
  user,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [state, dispatch] = useReducer(reducer, {
    firstName: '',
    lastName: '',
    zipCode: '',
    country: '',
    loading: false,
    paymentMethods: [],
    errorMsg: null,
    selectedCard: {},
    useExistingCard: true,
  });

  const {
    firstName,
    lastName,
    zipCode,
    country,
    loading,
    paymentMethods,
    errorMsg,
    useExistingCard,
    selectedCard,
  } = state;

  const lsapi = new LifeSteps();

  function updateValue(name, value) {
    return dispatch({
      type: 'UPDATE_VALUE',
      payload: {
        name,
        value,
      },
    });
  }

  function selectCard(card) {
    dispatch({
      type: 'SET_SELECTED_CARD',
      payload: {
        card,
      },
    });
  }

  // *********************************************** //
  // ************ Fetch Payment Methods ************ //
  // *********************************************** //
  async function fetchPaymentMethods() {
    // Show loading indicator and disable buttons
    updateValue('loading', true);
    try {
      // Fetch list of payment methods
      const { data } = await lsapi.get({ url: lsapi.urls.PAYMENT_METHODS });

      return data;
    } catch (e) {
      updateValue('errorMsg', e?.response?.data?.message);
    } finally {
      updateValue('loading', false);
    }
  }

  async function getAndUpdatePaymentMethods() {
    // Get list of payment methods
    const { paymentMethods: pm } = await fetchPaymentMethods();
    // Update them in the state
    updateValue('paymentMethods', pm);
    // Default to using an existing method if one exists
    updateValue('useExistingCard', !!size(pm));
  }

  async function deletePaymentMethod(method) {
    // Show loading indcator and disable buttons
    updateValue('loading', true);

    try {
      // Make delete call
      await lsapi.delete({
        url: `${lsapi.urls.PAYMENT_METHODS}/${method.paymentMethodId}`,
      });
      // Fetch and update payment methods to update the list
      await getAndUpdatePaymentMethods();
    } catch (error) {
      // Update the state with the error message
      updateValue('errorMsg', error?.response?.data?.message);
    } finally {
      // Turn off the loading indicator
      updateValue('loading', false);
    }
  }

  // If there's an error from the subscription saga, update the local error
  // message state
  useEffect(() => {
    if (error) {
      updateValue('errorMsg', error);
      updateValue('loading', false);
    }
  }, [error]);

  // Get payment methods when the modal mounts
  useEffect(() => {
    getAndUpdatePaymentMethods();
    // eslint-disable-next-line
  }, []);

  // Make sure the error values are null when the modal mounts the first time
  useEffect(
    () => () => {
      updateValue('errorMsg', null);
      clearError();
    },
    // eslint-disable-next-line
    []
  );

  // Get a payment method ID for a new card
  async function getPaymentMethodId() {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    try {
      // Grab the card from the form
      const card = elements.getElement(CardNumberElement);
      // Call Stripe's createPaymentMethod API
      const {
        error,
        paymentMethod: { id },
      } = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name: `${firstName} ${lastName}`,
          email: user.email,
          address: {
            postal_code: zipCode,
            country: country.value,
          },
        },
      });

      // Stripe is unclear about how this method works. It seems that it returns
      // and object with an error key in it, which indicates that they don't
      // return an error http code. That, in turn, means that the call to create
      // the payment method will not throw an error to catch. So we have to do
      // it for them.
      if (error) {
        throw Error(error);
      }

      return id;
    } catch (err) {
      // Because this error isn't coming from axios, we don't have to do
      // the same deconstruction
      updateValue('errorMsg', err);
    }
  }

  async function submitPayment(e) {
    e.preventDefault();
    // Turn loading indicator on
    updateValue('loading', true);

    // If they haven't selected a card, then that means they want to create a
    // new one
    if (isEmpty(selectedCard)) {
      // Get a token back from Stripe
      const token = await getPaymentMethodId();

      // Construct the subscription call body based on whether it's a gift or
      // not, etc.
      const constructSubscriptionCallBody = (() => {
        const baseSub = {
          planFrequency: selectedPlan,
          paymentMethod: token,
        };
        // If this is a gift, we need to set:
        // 1. the `gift` key to true
        // 2. the member_id of the recipient
        // 3. Auto-renew to false
        if (giftRecipient) {
          return {
            ...baseSub,
            autoRenew: false,
            gift: true,
            memberId: giftRecipient.memberId,
          };
        }

        // If it's not a gift:
        // 1. We don't need the member_id or gift keys
        // 2. Auto-renew should be true
        return {
          ...baseSub,
          autoRenew: true,
        };
      })();

      // Trigger the saga to submit the request to the API
      submitCreateSubscriptionRequest(constructSubscriptionCallBody, nextStep);
      // If they HAVE selected a card, but it's for a gift subscription
      // we have to include some addtional info in the post body
    } else if (giftRecipient) {
      submitCreateSubscriptionRequest(
        {
          autoRenew: false,
          gift: true,
          memberId: giftRecipient.memberId,
          planFrequency: selectedPlan,
          paymentMethod: selectedCard.paymentMethodId,
        },
        nextStep
      );
      // If they HAVE selected a card and it's NOT for a gift
    } else {
      submitCreateSubscriptionRequest(
        {
          autoRenew: true,
          planFrequency: selectedPlan,
          paymentMethod: selectedCard.paymentMethodId,
        },
        nextStep
      );
    }
  }

  function closeModal() {
    // 1. Remove error message from reducer state
    updateValue('errorMsg', null);
    // 2. Remove error message from redux store
    clearError();
    // 3. Close the modal
    handleClose();
  }

  const showNewCardForm = !useExistingCard;

  return (
    <SubscriptionModal title="Payment Info" titleImageSrc={paymentInfoTitle}>
      <FormContent>
        {showNewCardForm ? (
          <>
            <Instructions>
              Enter payment info for your <span>PRO</span> subscription.
            </Instructions>
            {errorMsg && (
              <Error>
                <AlertCircle />
                <div>{errorMsg}</div>
              </Error>
            )}
            <NewCreditCardForm state={state} updateValue={updateValue} />
          </>
        ) : (
          <>
            <Instructions>
              Select a credit card or click the button to add a new one.
            </Instructions>
            {errorMsg && (
              <Error>
                <AlertCircle />
                <div>{errorMsg}</div>
              </Error>
            )}
            <CardListStyles>
              {map(paymentMethods, card => (
                <CreditCard
                  card={card}
                  onClick={selectCard}
                  onClickDelete={() => deletePaymentMethod(card)}
                  key={card.paymentMethodId}
                  selected={
                    selectedCard.paymentMethodId === card.paymentMethodId
                  }
                  removeable={
                    size(paymentMethods) > 1 &&
                    userSub?.subscriptionStatus === 'active'
                  }
                />
              ))}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '1.5rem',
                }}
              >
                <Button
                  className="inverse-pro"
                  onClick={() => {
                    updateValue('useExistingCard', false);
                    selectCard({});
                  }}
                >
                  Add New Card
                </Button>
              </div>
            </CardListStyles>
          </>
        )}
      </FormContent>
      <ButtonWrapper>
        <Button
          className="pro"
          onClick={submitPayment}
          type="button"
          disabled={loading || (useExistingCard && isEmpty(selectedCard))}
          styles={{
            minWidth: 200,
          }}
        >
          {loading ? <LoadingIndicator /> : 'Submit Payment'}
        </Button>
        <ModalCancelButton onClick={closeModal} type="button">
          Cancel
        </ModalCancelButton>
      </ButtonWrapper>
    </SubscriptionModal>
  );
};

EnterCreditCardInfoModal.propTypes = {
  clearError: PropTypes.func.isRequired,
  giftRecipient: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  submitCreateSubscriptionRequest: PropTypes.func.isRequired,
  subscriptions: PropTypes.shape({
    error: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    user: PropTypes.object,
  }),
  user: PropTypes.object.isRequired,
};

EnterCreditCardInfoModal.defaultProps = {
  giftRecipient: null,
  subscriptions: {
    error: null,
  },
};

const mapStateToProps = ({ subscriptions, user }) => ({
  user,
  subscriptions,
});

const mapDispatchToProps = dispatch => ({
  submitCreateSubscriptionRequest: (body, cb) =>
    dispatch(createSubscription(body, cb)),
  clearError: () => dispatch(clearSubscriptionError()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterCreditCardInfoModal);
