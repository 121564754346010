import React, { useState } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import PropTypes from 'prop-types';

// Utils
import LifeSteps from '../../../modules/LifeStepsAPI';

// Assets
import cancelSubscriptionSuccessTitle from '../../../images/sub-cancel-subscription-success.svg';

// Components
import ErrorMessage from '../../ErrorMessage';
import LoadingIndicator from '../../utils/LoadingIndicator';
import SubscriptionModal from '../SubscriptionModal';

// Styled Components
import { Button, ModalCancelButton } from '../../styles/Buttons';
import { ButtonWrapper } from '../SubscriptionModal/styles';
import { FeedbackTextArea, Note, Option } from './styles';

// *********************************************** //

const CancellationFeedbackModal = ({ handleClose, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cancellationReason, setCancellationReason] = useState(null);
  const [otherReason, setOtherReason] = useState('');
  const [showError, setShowError] = useState(false);

  const lsapi = new LifeSteps();

  const submitFeedback = async () => {
    setShowError(false);
    setIsLoading(true);

    const { memberId, firstName, lastName } = user;

    let reason = cancellationReason;

    if (cancellationReason === 'Other') {
      reason = `Other: ${otherReason}`;
    }

    const feedback = {
      memberId,
      name: `${firstName} ${lastName}`,
      reason,
    };

    try {
      await lsapi.sendCancellationFeedbackToAirtable(feedback);
      await lsapi.sendCancellationFeedbackToSlack(feedback);
      setIsLoading(false);
      handleClose();
    } catch (error) {
      setShowError(true);
    }
  };

  // TODO - Get these from somewhere else ...
  const reasons = [
    'I am no longer building a Young Living business.',
    'Life Steps PRO is too expensive.',
    'Life Steps is too confusing.',
    'I do not find Life Steps content useful.',
    'Other',
  ];

  return (
    <SubscriptionModal
      title="Cancel Subscription"
      titleImageSrc={cancelSubscriptionSuccessTitle}
    >
      <Note>
        Before you go, would you please tell us the reason why you cancelled
        your subscription? This helps us improve our products and services to
        provide more value to our customers.
      </Note>
      <div style={{ marginBottom: '2rem' }}>
        {map(reasons, reason => (
          <Option
            type="button"
            onClick={() => setCancellationReason(reason)}
            className={reason === cancellationReason && 'active'}
            key={reason}
          >
            {reason}
          </Option>
        ))}
        {cancellationReason === 'Other' && (
          <FeedbackTextArea
            autoFocus
            onChange={e => setOtherReason(e.target.value)}
            placeholder="Please enter your feedback here ..."
            rows={5}
            value={otherReason}
          />
        )}
        {showError && (
          <ErrorMessage message="Something went wrong. Please try again." />
        )}
      </div>
      <ButtonWrapper>
        <Button
          disabled={!cancellationReason || isLoading}
          onClick={submitFeedback}
        >
          {isLoading ? <LoadingIndicator /> : 'Submit Feedback'}
        </Button>
        <ModalCancelButton onClick={handleClose}>Skip</ModalCancelButton>
      </ButtonWrapper>
    </SubscriptionModal>
  );
};

CancellationFeedbackModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, null)(CancellationFeedbackModal);
