import styled from 'styled-components';

// Styled Components
import { BaseButton } from '../../styles/Buttons';
import theme from '../../styles/themes';

const { breakpoints, colors } = theme;

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  font-weight: 500;
  color: ${colors.roles.danger};

  p {
    margin: 0 0 0 0.5rem;
    font-size: 1rem;
  }
`;

export const FeedbackTextArea = styled.textarea`
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  box-shadow: none;
  outline: none;
  border: 2px solid ${colors.lightGray};
  border-radius: 5px;
  color: ${colors.darkGray};
  font-weight: 400;

  ::placeholder {
    font-weight: 300;
    color: ${colors.gray};
  }

  &:focus {
    border: 2px solid ${colors.green};
  }
`;

export const Note = styled.p`
  margin: 2rem 0;
  font-size: 1.125rem;
  font-weight: 200;
  text-align: center;
  color: ${colors.darkGray};

  @media (max-width: ${breakpoints.mobile}) {
    text-align: left;
  }
`;

export const Option = styled(BaseButton)`
  width: 100%;
  margin: 0 0 0.5rem;
  padding: 1rem 1rem 1.1rem;
  font-size: 1rem;
  font-weight: 300;
  color: ${colors.darkGray};
  border: 2px solid ${colors.lightGray};
  border-radius: 5px;
  transition: all 150ms ease-in-out;

  &:hover {
    background: ${colors.lightestGreen};
  }

  &.active {
    background: ${colors.green};
    color: ${colors.white};
    border-color: ${colors.green};
  }
`;
