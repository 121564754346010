import styled from 'styled-components';

// Styles Components
import theme from '../styles/themes';

const { breakpoints, colors } = theme;

// eslint-disable-next-line import/prefer-default-export
export const ErrorMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  font-weight: 500;
  color: ${colors.roles.danger};

  p {
    margin: 0 0 0 0.5rem;
    font-size: 1rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    align-items: flex-start;
    padding: 0 1rem;
  }
`;
