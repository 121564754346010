import styled from 'styled-components/macro';
import theme from '../../styles/themes';

const { colors, fonts } = theme;

export const ButtonContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GiftRecipientStyles = styled.div`
  padding: 2rem 2rem;

  p {
    margin: 0;
  }

  h1 {
    color: ${colors.green};
    font-family: ${fonts.family.sans};
    font-weight: ${fonts.weight.medium};
    line-height: 1.2;
    margin: 0.5rem;
  }

  .member-id {
    padding: 0.5rem;
    color: ${colors.text.darkGray};
    font-weight: ${fonts.weight.medium};
  }
`;

export const ErrorMsg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fonts.size.s};
  line-height: 1em;
  color: ${colors.roles.danger};

  svg {
    fill: ${colors.roles.danger};
    stroke: ${colors.white};
    margin-right: 0.5rem;
  }
`;
