import React from 'react';
import PropTypes from 'prop-types';
import { ResourceActionButton } from '../styles/Buttons';
import getIcon from '../../images/icons';

// *********************************************** //

const ButtonWithIcon = ({ buttonText, handleClick, icon, isInverted }) => (
  <ResourceActionButton
    className={isInverted && 'inverse'}
    onClick={handleClick}
  >
    {icon && <img src={getIcon(icon, !isInverted)} alt={buttonText} />}
    <span>{buttonText}</span>
  </ResourceActionButton>
);

ButtonWithIcon.propTypes = {
  buttonText: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  icon: PropTypes.string,
  isInverted: PropTypes.bool,
};

ButtonWithIcon.defaultProps = {
  handleClick: () => {},
  icon: null,
  isInverted: false,
};

export default ButtonWithIcon;
