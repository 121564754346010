import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

// Components
import Modal from '../Modal';
import UpgradeFeatures from '../UpgradeFeatures';

// Styled Components
import theme from '../styles/themes';

const { breakpoints, colors } = theme;

// Styles
const Wrapper = styled.div`
  h1 {
    margin: 0.5rem 1rem;
    line-height: 1.4;
    font-weight: 900;
  }

  p {
    margin: 0.5rem 0 0;
    padding: 0 2rem;
    line-height: 1.4;
  }

  .feature-wrapper {
    margin: 2rem 2rem 1.5rem;
    padding: 1rem;
    border: 1px solid ${colors.plum};
    border-radius: 4px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    p {
      padding: 0;
    }

    .feature-wrapper {
      margin: 2rem 0 1.5rem;
    }
  }
`;

// *********************************************** //

const UpgradeModal = ({ cancelFunc, ctaFunc, open }) => (
  <>
    <Modal
      buttonClassName="pro"
      cancelFunc={cancelFunc}
      cancelText="No Thanks"
      ctaFunc={ctaFunc}
      ctaText="Upgrade Now"
      open={open}
      padding="2rem"
      showDivider={false}
    >
      <Wrapper>
        <h1>Upgrade to PRO</h1>
        <p>
          With a PRO Subscription, you can take your business to the next level.
          We’ll tell you what to do and when to do it so you can focus on your
          members.
        </p>
        <div className="feature-wrapper">
          <UpgradeFeatures />
        </div>
      </Wrapper>
    </Modal>
  </>
);

UpgradeModal.propTypes = {
  cancelFunc: PropTypes.func.isRequired,
  ctaFunc: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default UpgradeModal;
