import React, { useState } from 'react';
import { AlertCircle, X } from 'react-feather';
import PropTypes from 'prop-types';

// Modules
import LifeSteps from '../../modules/LifeStepsAPI';

// Components
import Input from '../utils/Input';

// Styled Components
import {
  ButtonWrapper,
  GetHelp,
  Instructions,
  ModalWrapper,
  Scrim,
} from './styles';
// import { Button } from '../_styles/Button';
import Error from '../styles/Error';
import { Button } from '../styles/Buttons';

// *********************************************** //

const PasswordResetModal = ({ handleClose, memberNumber }) => {
  const [memberNumberValue, setMemberNumberValue] = useState(memberNumber);
  const [requestSent, setRequestSent] = useState(false);
  const [showError, setShowError] = useState(false);

  const resetPassword = async () => {
    const lsapi = new LifeSteps();

    try {
      await lsapi.resetPassword(memberNumberValue);
      setRequestSent(true);
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <>
      <Scrim onClick={handleClose} />
      <ModalWrapper>
        {!requestSent ? (
          <>
            <button type="button" onClick={handleClose} className="close">
              <X />
            </button>
            <h1>Life Steps Password</h1>
            <Instructions>
              You can use your Young Living password to sign into your Life
              Steps Account. (The one you use in your Virtual Office)
            </Instructions>
            <Instructions>
              If you&apos;ve signed in before, you can create a separate
              password for Life Steps by entering your Young Living Member
              Number below and we will email you a new password.{' '}
              <a
                href="https://help.lifestepsapp.com/content/my-password-isnt-working"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
              {showError && (
                <Error>
                  <AlertCircle />
                  <div>
                    Something went wrong. Please try again later or contact
                    customer support.
                  </div>
                </Error>
              )}
            </Instructions>
            <Input
              withLabel
              labelText="YL Member No."
              fieldType="tel"
              onChange={e => setMemberNumberValue(e.target.value)}
              placeholder="Enter your YL Member Number."
              value={memberNumberValue}
            />
            <ButtonWrapper>
              <Button onClick={resetPassword}>Get New Password</Button>
            </ButtonWrapper>
            <GetHelp>
              Need Help?{` `}
              <a href="mailto:support@lifestepsapp.com">
                Contact Customer Support
              </a>
            </GetHelp>
          </>
        ) : (
          <>
            <h1>Password Sent</h1>
            <Instructions className="center">
              If you already have a user account with us, your new temporary
              Life Steps password has been sent to your email on record.
            </Instructions>
            <ButtonWrapper>
              <Button onClick={handleClose}>Close</Button>
            </ButtonWrapper>
          </>
        )}
      </ModalWrapper>
    </>
  );
};

PasswordResetModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  memberNumber: PropTypes.string,
};

PasswordResetModal.defaultProps = {
  memberNumber: '',
};

export default PasswordResetModal;
