import React, { useEffect, useReducer, useState } from 'react';
import { Calendar, Clock } from 'react-feather';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import styled from 'styled-components/macro';

// Utils
import useUserEvents from '../../utils/Hooks/useUserEvents';

// Components
import Modal from '../../Modal';
import RenderInput from '../../utils/RenderInput';

// Styled Components
import Divider from '../../styles/Divider';
import theme from '../../styles/themes';

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'UPDATE_VALUE':
      return {
        ...state,
        [payload.key]: payload.value,
      };

    case 'RECEIVE_EVENT_TO_EDIT':
      return {
        ...state,
        ...action.payload,
      };

    default:
      throw new Error("You shouldn't be seeing this");
  }
};

const AddEventModal = ({ open, cancelFunc, editMode, eventToEdit }) => {
  moment.locale('en');
  momentLocalizer();
  const [toggleDateDropdown, setToggleDateDropdown] = useState(false);
  const [toggleTimeDropdown, setToggleTimeDropdown] = useState(false);
  const [event, dispatch] = useReducer(reducer, {
    eventAt: eventToEdit ? eventToEdit.eventAt : '',
    eventLink: eventToEdit ? eventToEdit.eventLink : '',
    location: eventToEdit ? eventToEdit.location : '',
    title: eventToEdit ? eventToEdit.title : '',
    id: eventToEdit ? eventToEdit.id : null,
  });

  const { createEventMutation, updateEventMutation } = useUserEvents();

  useEffect(() => {
    if (eventToEdit) {
      dispatch({ type: 'RECEIVE_EVENT_TO_EDIT', payload: eventToEdit });
    }
  }, [eventToEdit]);

  const onClickCta = () => {
    if (editMode) {
      updateEventMutation.mutate({ id: event.id, body: event });
    } else {
      createEventMutation.mutate(event);
    }
    cancelFunc();
  };

  const onClickRemoveEventLink = () => {
    dispatch({
      type: 'UPDATE_VALUE',
      payload: { key: 'eventLink', value: '' },
    });
  };

  return (
    <Modal
      cancelFunc={cancelFunc}
      ctaFunc={onClickCta}
      open={open}
      padding="2rem"
    >
      <h1 className="modal-title">Add Event</h1>
      <Divider />
      <div style={{ paddingBottom: '1.5rem' }}>
        <div className="row">
          <div className="col-sm-12 px-0">
            {RenderInput({
              fieldType: 'text',
              labelText: 'Class Title',
              onChange: e =>
                dispatch({
                  type: 'UPDATE_VALUE',
                  payload: { key: 'title', value: e.target.value },
                }),
              placeholder: 'Intro to Essential Oils',
              value: event.title,
            })}
          </div>
          <div className="col-sm-12 px-0">
            {RenderInput({
              fieldType: 'text',
              labelText: 'Location',
              onChange: e =>
                dispatch({
                  type: 'UPDATE_VALUE',
                  payload: { key: 'location', value: e.target.value },
                }),
              placeholder: '1234 Main St. Dallas, TX, 75287',
              value: event.location,
            })}
          </div>
        </div>
        <div className="row">
          <DateTimePickerContainer className="col-sm-12 col-md-6 pl-0">
            <label htmlFor="datepicker">Date</label>
            <DateTimePicker
              dateIcon={<Calendar height={20} />}
              value={event.eventAt ? moment(event.eventAt).toDate() : null}
              open={toggleDateDropdown}
              onBlur={() => setToggleDateDropdown(false)}
              onChange={date =>
                dispatch({
                  type: 'UPDATE_VALUE',
                  payload: { key: 'eventAt', value: date },
                })
              }
              onFocus={() => setToggleDateDropdown('date')}
              onToggle={setToggleDateDropdown}
              placeholder="Choose a date..."
              time={false}
              id="datepicker"
            />
          </DateTimePickerContainer>
          <DateTimePickerContainer className="col-sm-12 col-md-6 pr-0">
            <label htmlFor="timepicker">Time</label>
            <DateTimePicker
              date={false}
              // value={moment().toDate()}
              value={event.eventAt ? moment(event.eventAt).toDate() : null}
              open={toggleTimeDropdown}
              onBlur={() => setToggleTimeDropdown(false)}
              onChange={date => {
                dispatch({
                  type: 'UPDATE_VALUE',
                  payload: { key: 'eventAt', value: date },
                });
              }}
              onFocus={() => setToggleTimeDropdown('time')}
              onToggle={setToggleTimeDropdown}
              placeholder="Choose a time..."
              step={15}
              timeIcon={<Clock height={20} />}
              id="timepicker"
            />
          </DateTimePickerContainer>
        </div>
        <div className="row">
          {RenderInput({
            fieldType: 'url',
            labelText: 'Event Link',
            onChange: e =>
              dispatch({
                type: 'UPDATE_VALUE',
                payload: { key: 'eventLink', value: e.target.value },
              }),
            onClickRemove: onClickRemoveEventLink,
            showRemoveButton: size(event.eventLink) > 0,
            subLabel: 'Add a link to more information or a Facebook event.',
            placeholder: 'Enter a link to more information (optional)',
            value: event.eventLink,
          })}
        </div>
      </div>
    </Modal>
  );
};

AddEventModal.propTypes = {
  cancelFunc: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  eventToEdit: PropTypes.shape({
    date: PropTypes.string,
    eventAt: PropTypes.string,
    eventLink: PropTypes.string,
    location: PropTypes.string,
    time: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.number,
  }),
  open: PropTypes.bool.isRequired,
};

export default AddEventModal;

// Styles
const { breakpoints, colors, fonts } = theme;

const DateTimePickerContainer = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;

  label {
    margin: 0.5rem 0;
    padding: 0;
    color: ${colors.text.darkGray};
    font-weight: ${fonts.weight.regular};
    font-size: ${fonts.size.xs};
    line-height: 1.4;
    text-transform: uppercase;
  }

  #datepicker_input,
  #datepicker_input::placeholder,
  #timepicker_input,
  #timepicker_input::placeholder {
    font-size: ${fonts.size.s};
    font-weight: ${fonts.weight.medium};
    width: 75%;
  }

  #datepicker_input,
  #timepicker_input {
    color: ${colors.text.black};
  }

  #datepicker_input::placeholder,
  #timepicker_input::placeholder {
    color: ${colors.text.gray};
  }

  @media (max-width: ${breakpoints.mobile}) {
    .col-sm-12 {
      margin: 0.5rem 0;
    }
  }
`;
