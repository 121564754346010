import styled from 'styled-components/macro';

import theme from '../../styles/themes';

const { breakpoints, fonts, colors } = theme;

export const CardContent = styled.section`
  text-align: left;

  h4 {
    margin: 0;
    font-size: ${fonts.size.s};
    font-weight: ${fonts.weight.demi};
    text-transform: uppercase;
  }

  .divider {
    margin: 0.5rem 0 1.5rem;
    background-color: ${colors.dividerGray};
    height: 1px;
  }

  h2 {
    margin: 0 0 1.5rem;
    font-size: ${fonts.size.xl};
    font-weight: ${fonts.weight.medium};
    line-height: 1em;
    color: ${props => (props.hasSubscription ? colors.green : colors.black)};
  }

  h5.subtitle {
    margin: 0.5rem 0;
    padding: 0;
    font-size: ${fonts.size.s};
    font-weight: ${fonts.weight.regular};
    line-height: 1.4;
    color: ${colors.text.gray};
  }

  .info {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 2rem;
    font-weight: ${fonts.weight.medium};

    .info-block:first-child {
      .value {
        margin-bottom: 0rem;
      }
    }

    .info-block {
      display: flex;
      flex-direction: column;
      margin-right: 4rem;

      .label {
        margin-bottom: 0.5rem;
        font-size: ${fonts.size.s};
        font-weight: ${fonts.weight.light};
        color: ${colors.text.gray};
      }

      .value {
        margin-bottom: 0.5rem;
        font-weight: ${fonts.weight.regular};
        line-height: 1.4;
      }

      .edit-link {
        padding-top: 0.5rem;
        font-size: ${fonts.size.s};
      }

      @media (max-width: ${breakpoints.mobile}) {
        margin: 0 0 1rem 0;

        .edit-link {
          margin-bottom: 1rem;
        }
      }
    }

    @media (max-width: ${breakpoints.mobile}) {
      flex-direction: column;
      margin-bottom: 1rem;
    }
  }

  .table-container {
    margin-bottom: 1.5rem;
  }

  .actions {
    display: flex;
    align-items: center;

    button {
      cursor: pointer;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .actions {
      width: 100%;
      flex-direction: column;

      button {
        width: 100%;

        &:first-child {
          margin-bottom: 1rem;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
`;

export const GiftSubscription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 1.5rem;
  background: ${colors.nearlyWhite};
  border: 1px solid ${colors.darkerDividerGray};
  border-radius: 4px;

  .column {
    width: 50%;

    &:last-child {
      display: flex;
      /*
        TODO: set justify-content to "space-between" when
        gift subscription extend/renew buttons are enabled
      */
      justify-content: flex-end;
      align-items: center;
      padding-right: 1rem;
    }
  }

  p {
    margin: 0;
    color: ${colors.black};
    font-weight: ${fonts.weight.light};

    &.expired {
      color: ${colors.roles.danger};
    }
  }

  .member {
    h4 {
      margin: 0 0 0.5rem;
      font-size: ${fonts.size.lg};
      font-weight: ${fonts.weight.medium};
      text-transform: none;
    }

    p {
      color: ${colors.darkGray};
      font-size: ${fonts.size.s};
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    text-align: center;
    padding: 1.5rem 1rem;

    .column {
      width: 100%;

      &:last-child {
        flex-direction: column;
        margin-top: 1rem;
        padding: 0;
      }
    }
    .member {
      h4 {
        font-size: ${fonts.size.xl};
      }
    }
  }
`;

export const RenewButton = styled.button`
  font-size: ${fonts.size.md};
  font-weight: ${fonts.weight.medium};
  background-color: transparent;
  color: ${colors.green};
  border: none;

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    margin-top: 1rem;
    padding: 0.6rem 2rem 0.75rem;
    border: 1px solid ${colors.green};
    border-radius: 4px;
  }
`;
