import React from 'react';
import FeatherIcon from 'feather-icons-react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styled Components
import { LargeTitle } from '../styles/Text';
import { BaseButton } from '../styles/Buttons';
import theme from '../styles/themes';

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const Title = styled(LargeTitle)`
  padding-top: 2rem;
  font-size: 2rem;
  border-top: 1px solid ${theme.colors.lightGray};

  @media (max-width: ${theme.breakpoints.mobile}) {
    font-size: 1.5rem;
  }
`;

const LinkItem = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  p {
    margin: 0 0 0 0.5rem;
    color: ${theme.colors.black};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const LinkItemButton = styled(BaseButton)`
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  margin-bottom: 1rem;

  p {
    margin: 0 0 0 0.5rem;
    text-align: left;
    font-size: 1rem;
    color: ${theme.colors.black};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Container = styled.div`
  display: inline-block;
  width: 50%;
  text-align: left;

  @media (max-width: ${theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const Header = styled.div`
  margin-bottom: 2rem;
  text-align: center;
`;

// *********************************************** //

export default function LinkList({ onPress, subtitle, title, links, setSlug }) {
  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        {subtitle && <p>{subtitle}</p>}
      </Header>
      {map(links, (link, index) => (
        <Container key={index}>
          {link.type === 'internal' ? (
            <LinkItemButton
              onClick={() => {
                setSlug(link.value);
                onPress();
              }}
            >
              <div style={{ height: 24, width: 24 }}>
                <FeatherIcon
                  icon={link.icon}
                  size={24}
                  color={theme.colors.main}
                />
              </div>
              <p>{link.label}</p>
            </LinkItemButton>
          ) : (
            <LinkItem
              href={link.value}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FeatherIcon
                icon={link.icon}
                size={24}
                color={theme.colors.main}
              />
              <p>{link.label}</p>
            </LinkItem>
          )}
        </Container>
      ))}
    </Wrapper>
  );
}

LinkList.propTypes = {
  onPress: PropTypes.func,
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  setSlug: PropTypes.func.isRequired,
  subtitle: PropTypes.string.isRequired,
};

LinkList.defaultProps = {
  onPress: () => {},
};
