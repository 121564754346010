import axios from 'axios';
import { getFromLocalStorage } from './localStorage';

let cancel;

class LifeSteps {
  constructor() {
    this.baseUrl = `${process.env.REACT_APP_API_URL}`;
    this.baseUrlV2 = `${process.env.REACT_APP_API_URL_V2}`;
    this.baseUrlV3 = `${process.env.REACT_APP_API_URL_V3}`;
    this.urls = {
      BROWSE_RESOURCES: `${this.baseUrlV3}/resources/browse`,
      PASSWORD_RESET: `${this.baseUrlV3}/reset_password`,
      PAYMENT_METHODS: `${this.baseUrlV3}/payment_methods`,
      RESOURCE_DETAILS: id =>
        `${this.baseUrlV3}/resources/${id}?context=dashboard`,
      RESOURCES: `${this.baseUrlV3}/resources?context=dashboard`,
      RESOURCES_BY_TAG: ({ slug, page }) =>
        `${this.baseUrlV3}/resources/tag/${slug}?page=${page}`,
      SEARCH_RESOURCES: ({ query, page }) =>
        `${this.baseUrlV3}/resources/search/${query}?context=dashboard&page=${page}`,
      SESSION: `${this.baseUrlV3}/sessions`,
      SUBSCRIPTION: `${this.baseUrlV3}/subscriptions`,
      USER: `${this.baseUrlV3}/user`,
      USER_EVENTS: id => `${this.baseUrlV3}/user_events${id ? `/${id}` : ''}`,
      UPDATE_USER: `${this.baseUrlV3}/users`,
    };

    const token = getFromLocalStorage('accessToken');

    this.reqHeaders = {
      'Content-Type': 'application/json',
      'Authentication-Token': token,
    };
  }

  get = ({ url, headers = this.reqHeaders }) => {
    // eslint-disable-next-line no-unused-expressions
    cancel && cancel();

    return axios.get(url, {
      headers,
      // cancelToken: new CancelToken(c => {
      //   cancel = c;
      // }),
    });
  };

  getWithReturn = async ({ url, headers = this.reqHeaders }) => {
    // eslint-disable-next-line no-unused-expressions
    cancel && cancel();

    const { data } = await axios.get(url, {
      headers,
      // cancelToken: new CancelToken(c => {
      //   cancel = c;
      // }),
    });

    return data;
  };

  put = ({ url, body, headers = this.reqHeaders }) =>
    axios.put(
      url,
      {
        ...body,
      },
      { headers }
    );

  patch = ({ url, body, headers = this.reqHeaders }) =>
    axios.patch(
      url,
      {
        ...body,
      },
      { headers }
    );

  post = ({ url, body, headers = this.reqHeaders }) =>
    axios.post(
      url,
      {
        ...body,
      },
      { headers }
    );

  delete = ({ url, headers = this.reqHeaders }) =>
    axios.delete(url, { headers });

  getAccessToken = ({ memberId, password }) => {
    const options = {
      url: this.urls.SESSION,
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        memberId,
        password,
      },
    };

    return this.post(options);
  };

  getResourceDetails = async id =>
    this.get({ url: this.urls.RESOURCE_DETAILS(id) });

  getResources = async () => this.getWithReturn({ url: this.urls.RESOURCES });

  getResourcesByTag = async ({ slug, page }) =>
    this.getWithReturn({ url: this.urls.RESOURCES_BY_TAG({ slug, page }) });

  getBrowseResources = async () =>
    this.get({ url: this.urls.BROWSE_RESOURCES });

  searchResources = async options =>
    this.getWithReturn({ url: this.urls.SEARCH_RESOURCES(options) });

  resetPassword = id => {
    this.post({
      url: this.urls.PASSWORD_RESET,
      body: {
        id,
      },
    });
  };

  getUser = () =>
    this.get({
      url: this.urls.USER,
    });

  updateUser = (body, headers) =>
    this.patch({
      headers,
      url: this.urls.UPDATE_USER,
      body,
    });

  getUserEvents = () => this.get({ url: this.urls.USER_EVENTS() });

  getUserEventDetails = async id =>
    this.get({ url: this.urls.USER_EVENTS(id) });

  createUserEvent = async body =>
    this.post({ url: this.urls.USER_EVENTS(), body });

  updateUserEvent = async (id, body) =>
    this.patch({ url: this.urls.USER_EVENTS(id), body });

  deleteUserEvent = async id => this.delete({ url: this.urls.USER_EVENTS(id) });

  createSubscription = body =>
    this.post({
      url: this.urls.SUBSCRIPTION,
      body,
    });

  sendCancellationFeedbackToAirtable = body =>
    this.post({
      url:
        'https://life-steps-airtable-api-prod.herokuapp.com/api/v1/cancellation-feedback',
      body,
    });

  sendCancellationFeedbackToSlack = ({ memberId, name, reason }) =>
    this.post({
      url:
        'https://hooks.slack.com/services/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',
      body: {
        type: 'mrkdwn',
        text: `*"${reason}"* \n - ${name}, ${memberId}`,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
}

export default LifeSteps;
