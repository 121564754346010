import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import theme from '../styles/themes';

const Card = ({ children, maxWidth, padding, width, ...rest }) => (
  <CardContainer
    maxWidth={maxWidth}
    padding={padding}
    cardWidth={width}
    {...rest}
  >
    {children}
  </CardContainer>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  padding: PropTypes.string,
  width: PropTypes.string,
};

Card.defaultProps = {
  maxWidth: null,
  padding: null,
  width: 'calc(100% - 1.5rem) / 3',
};

export default Card;

// Styles
const { breakpoints, colors, shadows } = theme;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
  height: 100%;
  max-width: ${props => props.maxWidth};
  margin: 0 0 1rem 0;
  padding: ${props => (props.padding ? props.padding : '1rem')};
  text-align: center;
  background-color: ${colors.white};
  border-radius: 10px;
  box-shadow: ${shadows.light};

  @media screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
    max-width: 100%;
    padding: ${props => (props.padding ? props.padding : '1rem')};
  }
`;
