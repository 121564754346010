import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Clipboard } from 'react-feather';
import { connect } from 'react-redux';
import includes from 'lodash/includes';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from 'react-query';

// Assets
import screenshot from '../../images/site-preview.jpg';

// Utils
import { hydrateUser, updateUser } from '../../actions';
import LifeSteps from '../../modules/LifeStepsAPI';

// Components
import CardGrid from '../../components/CardGrid';
import CatchModal from '../../components/CatchModal';
import ExampleBoundary from '../../components/utils/ErrorBoundary';
import LoadingIndicator from '../../components/utils/LoadingIndicator';
import UpgradeAd from '../../components/UpgradeAd';
import UpgradeModal from '../../components/UpgradeModal';
import VerifyInfoModal from '../../components/VerifyInfoModal';
import ViewResourceModal from '../../components/ViewResourceModal';

// Styled Components
import { Button } from '../../components/styles/Buttons';
import Divider from '../../components/styles/Divider';
import theme from '../../components/styles/themes';

const { breakpoints, colors, fonts, shadows } = theme;

// *********************************************** //

const DashboardContainer = ({
  fetchUser,
  hasSubscription,
  infoVerified,
  isOnBabySteps,
  websiteSubdomain,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { referrer } = queryString.parse(search);

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [isCatchModalVisible, setIsCatchModalVisible] = useState(false);
  const [copyLinkButtonText, setCopyLinkButtonText] = useState('Copy Link');
  const [referrers, setReferrers] = useState([]);
  const [resources, setResources] = useState(null);

  // Get API
  const lsapi = new LifeSteps();

  // Get Featured Resources
  const { data, isLoading } = useQuery('resources', lsapi.getResources);

  // Set Featured Resources in state
  useEffect(() => {
    if (data) {
      setResources(data?.Featured[1]?.resources);
    }
  }, [data]);

  // Re-hydrate the user on dashboard load
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    const fetchHeaderImage = async () => {
      const {
        data: { dashboard },
      } = await axios.get(process.env.REACT_APP_S3_URL);

      setReferrers(Object.keys(dashboard));
    };
    fetchHeaderImage();
  }, []);

  // Checks referrer and displays the catch modal if needed
  useEffect(() => {
    if (includes(referrers, referrer)) {
      setIsCatchModalVisible(true);
    }
  }, [referrer, referrers]);

  const onClickCopyLink = externalLink => {
    const el = document.createElement('textarea');
    el.value = externalLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setCopyLinkButtonText('Link Copied!');
    setTimeout(() => setCopyLinkButtonText('Copy Link'), 5000);
  };

  const { title, description } = useMemo(() => {
    // If the user has a subscription or is on baby steps
    if (isOnBabySteps || hasSubscription) {
      return {
        title: 'Share Your Website',
        description:
          'Send prospects to your website so they can join you in this journey to wellness.',
      };
    }
    // If the user doesn't have a subscription
    return {
      title: 'Get a Personalized Site',
      description:
        'With a PRO Account, you get tons of additional tools to help grow your business, including a website that will teach your prospects exactly why they should join you in this journey to wellness.',
    };
  }, [hasSubscription, isOnBabySteps]);

  const siteButton = useMemo(() => {
    // If the user has a subscription or is on baby steps
    if (hasSubscription || isOnBabySteps) {
      return (
        <>
          <Button
            onClick={() =>
              onClickCopyLink(`https://${websiteSubdomain}.lifestepseo.com`)
            }
          >
            {copyLinkButtonText}
            <Clipboard height={18} style={{ marginLeft: '8px' }} />
          </Button>
          <div className="edit-site">
            <Link to="/account/site">Edit Your Website Info</Link>
          </div>
        </>
      );
    }

    // If the user doesn't have a subscription,
    // show the upgrade button
    return (
      <Button className="pro" onClick={() => setShowUpgradeModal(true)}>
        Upgrade Now
      </Button>
    );
  }, [hasSubscription, isOnBabySteps, websiteSubdomain, copyLinkButtonText]);

  const onClickUpgrade = () => {
    history.push('/account/subscriptions/?referrer=upgrade_modal');
    setShowUpgradeModal(false);
  };

  const onClickTag = tag => {
    history.push(`/resources?tagTitle=${tag.title}`);
  };

  // Handle Viewing & Sharing a Resource
  // Functions passed down to CardGrid > ListCard to handle resources
  // card buttons
  const [isViewResourceModalVisible, setIsViewResourceModalVisible] = useState(
    false
  );
  const [resourceId, setResourceId] = useState(null);

  const openViewResourceModal = slug => {
    setResourceId(slug);
    setIsViewResourceModalVisible(true);
  };

  const closeViewResourceModal = () => {
    setIsViewResourceModalVisible(false);
    setResourceId(null);
  };

  return (
    <ExampleBoundary>
      <DashboardStyles>
        <CatchModal
          referrer={referrer || 'app_resource'}
          open={isCatchModalVisible}
          ctaFunc={() => {
            setIsCatchModalVisible(false);
            setShowUpgradeModal(true);
          }}
          cancelFunc={() => setIsCatchModalVisible(false)}
        />
        <UpgradeModal
          open={showUpgradeModal}
          cancelFunc={() => setShowUpgradeModal(false)}
          ctaFunc={onClickUpgrade}
        />
        {/* <VerifyInfoModal open={!infoVerified} /> */}
        {!hasSubscription && (
          <UpgradeAd handleUpgrade={() => setShowUpgradeModal(true)} />
        )}
        <div className="share-content content-section">
          <h1>{title}</h1>
          <p>{description}</p>
          <a
            href={
              hasSubscription || isOnBabySteps
                ? `https://${websiteSubdomain}.lifestepseo.com`
                : null
            }
          >
            <img
              src={screenshot}
              alt="Your Custom Website"
              className="share-screenshot"
            />
          </a>
          <div className="button-container">{siteButton}</div>
        </div>
        <Divider darker />
        <div className="wellness-resources content-section">
          <h1>Featured Resources</h1>
          <p>
            Check out the latest featured resources from the Life Steps Team.
          </p>
          <div className="wellness-box-list">
            {resources ? (
              <CardGrid
                items={resources}
                openViewResourceModal={openViewResourceModal}
                cardType="resource"
                hasSubscription={hasSubscription}
                explainProContent={() => setIsCatchModalVisible(true)}
                cardsInRow={3}
              />
            ) : (
              <>
                {isLoading ? (
                  <LoadingIndicator dark />
                ) : (
                  <p>There are no featured resources at this time.</p>
                )}
              </>
            )}
            <p>
              <Link to="/resources">View All Resources</Link>
            </p>
          </div>
        </div>
        <ViewResourceModal
          handleClickTag={onClickTag}
          open={isViewResourceModalVisible}
          slug={resourceId}
          closeModal={closeViewResourceModal}
          hasSubscription={hasSubscription}
        />
      </DashboardStyles>
    </ExampleBoundary>
  );
};

DashboardContainer.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  infoVerified: PropTypes.bool.isRequired,
  isOnBabySteps: PropTypes.bool.isRequired,
  websiteSubdomain: PropTypes.string.isRequired,
};

const mapStateToProps = ({ user }) => ({
  hasSubscription: user.hasSubscription,
  infoVerified: user.infoVerified,
  isOnBabySteps: user.onBabyStepsTeam,
  siteInfoChanges: user.siteInfoChanges,
  siteSetupComplete: user.siteSetupComplete,
  websiteSubdomain: user.websiteSubdomain,
  user,
});

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(hydrateUser()),
  submitSiteSetup: (user, cb) => dispatch(updateUser(user, cb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);

// Styles
const DashboardStyles = styled.div`
  text-align: center;

  .button-container {
    margin: auto;
    margin-top: 0.5rem;
  }

  h1 {
    margin: 0 0 1rem;
    font-weight: ${fonts.weight.black};
    line-height: 1em;
  }

  p {
    margin: 0 0 1rem;
    font-size: ${fonts.size.lg};
    color: ${colors.text.darkGray};
  }

  a {
    font-size: ${fonts.size.md};
    font-weight: ${fonts.weight.demi};
    letter-spacing: 0.4px;

    &:hover {
      opacity: 0.7;
    }
  }

  .edit-site {
    margin-top: 3rem;
  }

  .content-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
  }

  .share-content {
    img.share-screenshot {
      width: 100%;
      max-width: 512px;
      margin: 1.5rem auto 1rem;
      object-fit: cover;
      box-shadow: ${shadows.light};
    }

    p {
      margin: auto;
      max-width: 80%;
    }

    button {
      margin-top: 1rem;
    }
  }

  .wellness-box-list {
    margin: 1.5rem 0 0;

    p {
      margin-top: 1.5rem;
    }

    @media (max-width: ${breakpoints.desktop}) {
      p {
        margin: 1rem 0 0;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .content-section {
      padding: 3rem 0;
    }

    .share-content {
      padding-top: 1rem;

      p {
        max-width: 100%;
      }
    }
  }
`;
