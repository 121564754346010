export const FETCH_SUBSCRIPTION_START = 'FETCH_SUBSCRIPTION_START';
export const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS';
export const FETCH_SUBSCRIPTION_ERROR = 'FETCH_SUBSCRIPTION_ERROR';
export const CREATE_OR_UPDATE_SUBSCRIPTION_START =
  'CREATE_OR_UPDATE_SUBSCRIPTION_START';
export const CREATE_OR_UPDATE_SUBSCRIPTION_SUCCESS =
  'CREATE_OR_UPDATE_SUBSCRIPTION_SUCCESS';
export const CREATE_OR_UPDATE_SUBSCRIPTION_ERROR =
  'CREATE_OR_UPDATE_SUBSCRIPTION_ERROR';
export const CREATE_GIFT_SUBSCRIPTION_SUCCESS =
  'CREATE_GIFT_SUBSCRIPTION_SUCCESS';
export const CLEAR_SUBSCRIPTION_ERROR = 'CLEAR_SUBSCRIPTION_ERROR';
export const UPDATE_SUBSCRIPTION_START = 'UPDATE_SUBSCRIPTION_START';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_ERROR = 'UPDATE_SUBSCRIPTION_ERROR';
export const DELETE_SUBSCRIPTION_START = 'DELETE_SUBSCRIPTION_START';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_ERROR = 'DELETE_SUBSCRIPTION_ERROR';
