import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Utils
import { fetchSubscriptions } from '../../../actions';

// Components
import FindMemberModal from '../../SubscriptionModals/FindMemberModal';
import EnterCreditCardInfoModal from '../../SubscriptionModals/EnterCreditCardInfoModal';
import ChooseBillingCycleModal from '../../SubscriptionModals/ChooseBillingCycleModal';
import SuccessModal from '../../SubscriptionModals/SuccessModal';

// Styles
import Scrim from '../../styles/Scrim';

// *********************************************** //

const GiftSubscriptionFlow = ({ getSubscriptions, handleClose }) => {
  const [step, setStep] = useState(0);
  const [giftRecipient, setGiftRecipient] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('quarter');

  function incrementStep() {
    setStep(step + 1);
  }

  function decrementStep() {
    setStep(step - 1);
  }

  function closeModal() {
    setStep(0);
    handleClose(0);
  }

  // Refetch subs on success
  useEffect(() => {
    if (step === 3) {
      getSubscriptions();
    }
  }, [getSubscriptions, step]);

  return (
    <>
      <Scrim onClick={handleClose} />
      {step === 0 && (
        <FindMemberModal
          goToNextStep={incrementStep}
          goToPreviousStep={decrementStep}
          giftRecipient={giftRecipient}
          handleClose={closeModal}
          setGiftRecipient={setGiftRecipient}
        />
      )}
      {step === 1 && (
        <ChooseBillingCycleModal
          giftRecipient={giftRecipient}
          handleClose={decrementStep}
          nextStep={incrementStep}
          onSelectPlan={setSelectedPlan}
        />
      )}
      {step === 2 && (
        <EnterCreditCardInfoModal
          giftRecipient={giftRecipient}
          selectedPlan={selectedPlan}
          handleClose={handleClose}
          nextStep={incrementStep}
        />
      )}
      {step === 3 && (
        <SuccessModal handleClose={handleClose}>
          <p>
            All set! We'll send <span>{giftRecipient.name}</span> an email and
            let them know that they've received a{' '}
            <span>
              {selectedPlan === 'quarter' ? '3' : '1'} month gift subscription
            </span>
            !
          </p>
        </SuccessModal>
      )}
    </>
  );
};

GiftSubscriptionFlow.propTypes = {
  getSubscriptions: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getSubscriptions: () => dispatch(fetchSubscriptions()),
});

export default connect(null, mapDispatchToProps)(GiftSubscriptionFlow);
