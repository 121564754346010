import React, { useEffect, useState } from 'react';
import { AlertCircle } from 'react-feather';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';
import { useQuery } from 'react-query';

// Utils
import LifeSteps from '../../modules/LifeStepsAPI';
import useDebounce from '../../components/utils/Hooks/useDebounce';

// Components
import CardGrid from '../../components/CardGrid';
import CatchModal from '../../components/CatchModal';
import LoadingIndicator from '../../components/utils/LoadingIndicator';
import PaginationControls from '../../components/PaginationControls';
import ResourceSidebar from '../../components/ResourceSidebar';
import SearchBar from '../../components/SearchBar';
import { SidebarContainer } from '../../components/styles/Sidebar';
import UpgradeModal from '../../components/UpgradeModal';
import ViewResourceModal from '../../components/ViewResourceModal';

// Styled Components
import theme from '../../components/styles/themes';

// Styles
const { breakpoints, colors, fonts } = theme;

const Container = styled.main`
  padding-bottom: 4rem;

  .sidebar {
    padding: 0 1rem 0 0;
  }

  section.main-content {
    display: flex;
    flex-direction: column;
    padding: 0;

    .row {
      flex-direction: column;
    }
  }

  .search-bar-container {
    width: 100%;
    margin: 0 auto 1.5rem;
  }

  @media (max-width: ${breakpoints.desktop}) {
    .sidebar {
      padding: 0;
    }
  }
`;

const Error = styled.div`
  font-size: 0.875rem;
  font-weight: ${fonts.weight.regular};
  color: ${colors.roles.danger};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;

  svg {
    fill: ${colors.roles.danger};
    stroke: ${colors.white};
    margin-right: 0.5rem;
  }
`;

// *********************************************** //

const ResourcesContainer = ({ hasSubscription }) => {
  const location = useLocation();
  const queryString = qs.parse(location.search);

  // Store search term
  const [searchTerm, setSearchTerm] = useState(queryString.tagTitle || '');
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState(false);
  const [totalPages, setTotalPages] = useState(false);

  // Debounce search term
  const debouncedSearchTerm = useDebounce(searchTerm, 800).trim();

  // Handle Tags
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState(null);

  // Get API
  const lsapi = new LifeSteps();

  // Get Data
  const tagQuery = useQuery(
    'tags',
    () =>
      lsapi.searchResources({
        query: '',
        page: 1,
      }),
    {
      enabled: !isEmpty(queryString.tagTitle),
    }
  );

  const searchQuery = useQuery(
    ['search resources', `${debouncedSearchTerm}-page-${page}`],
    () =>
      lsapi.searchResources({
        query: debouncedSearchTerm,
        page,
      }),
    {
      enabled: isEmpty(tag),
      onSuccess: data => {
        setResources(data?.resources);
        setTotalPages(data?.pagy?.totalPages);
        setIsLoading(false);
      },
    }
  );

  // Refetch only if the debounced search term changes
  useEffect(() => {
    if (debouncedSearchTerm.length > 0) {
      setIsLoading(true);
      setTag(null);
      searchQuery.refetch();
    }

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  // Fetch with empty string on first load
  useEffect(() => {
    setIsLoading(true);
    searchQuery.refetch();
  }, []);

  // Reset page when the searchTerm changes. Uses searchTerm
  // instead of debouncedSearchTerm to beat race condition
  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  // Set loading to be true when the page changes
  useEffect(() => {
    setIsLoading(true);
  }, [page]);

  const tagListQuery = useQuery(
    ['resources by tag', `${tag?.slug}-page-${page}`],
    () =>
      lsapi.getResourcesByTag({
        slug: tag?.slug,
        page,
      }),
    {
      enabled: false,
      onSuccess: data => {
        setResources(data?.resources);
        setTotalPages(data?.pagy?.totalPages);
        setIsLoading(false);
      },
    }
  );

  // Only load tags into sidebar from the naked call
  useEffect(() => {
    if (searchQuery?.data?.tags && isEmpty(searchTerm)) {
      setTags(searchQuery?.data?.tags);
    }

    if (tagQuery?.data?.tags) {
      setTags(tagQuery?.data?.tags);
    }
  }, [searchQuery, searchTerm, tagQuery]);

  // When a tag is clicked, fetch resources
  useEffect(() => {
    if (tag) {
      setIsLoading(true);
      tagListQuery.refetch();
    }
  }, [tag, page]);

  // Modal State
  const [showCatchModal, setShowCatchModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showViewResourceModal, setShowViewResourceModal] = useState(false);

  // Resource slug for view modal
  const [resourceSlug, setResourceSlug] = useState('');

  const history = useHistory();

  const onClickUpgrade = () => {
    history.push('/account/subscriptions/?referrer=upgrade_modal');
    setShowUpgradeModal(false);
  };

  return (
    <Container>
      <CatchModal
        referrer="app_resource"
        open={showCatchModal}
        ctaFunc={() => {
          setShowCatchModal(false);
          setShowUpgradeModal(true);
        }}
        cancelFunc={() => setShowCatchModal(false)}
      />
      <UpgradeModal
        open={showUpgradeModal}
        cancelFunc={() => setShowUpgradeModal(false)}
        ctaFunc={onClickUpgrade}
      />
      <ViewResourceModal
        open={showViewResourceModal}
        slug={resourceSlug}
        closeModal={() => setShowViewResourceModal(false)}
        handleClickTag={val => {
          setTag(val);
          setShowViewResourceModal(false);
        }}
        hasSubscription={hasSubscription}
      />
      {searchQuery?.error && (
        <div className="row">
          <Error>
            <AlertCircle />
            <div>{searchQuery?.error}</div>
          </Error>
        </div>
      )}
      <div className="row">
        <div className="col-sm-12 col-lg-3 sidebar">
          <SidebarContainer>
            <ResourceSidebar
              title="Resources"
              items={tags}
              onClick={clickedTag => {
                setSearchTerm('');
                setPage(1);
                setTag(clickedTag);
              }}
              selectedItem={tag}
            />
          </SidebarContainer>
        </div>
        <section className="main-content col-sm-12 col-lg-9">
          <div className="row">
            <div className="search-bar-container">
              <SearchBar
                placeholder="Search Resources"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <LoadingIndicator dark offWhite size="3rem" />
              </div>
            ) : (
              <>
                {!isEmpty(resources) ? (
                  <>
                    <CardGrid
                      items={resources}
                      openViewResourceModal={slug => {
                        setResourceSlug(slug);
                        setShowViewResourceModal(true);
                      }}
                      cardType="resource"
                      hasSubscription={hasSubscription}
                      explainProContent={() => setShowCatchModal(true)}
                    />
                    <PaginationControls
                      activePage={page}
                      onClickPageNumber={chosenPage => {
                        setPage(chosenPage);
                        window.scrollTo(0, 0);
                      }}
                      totalPages={totalPages}
                    />
                  </>
                ) : (
                  <p style={{ textAlign: 'center' }}>
                    No resources found. Try searching for something else.
                  </p>
                )}
              </>
            )}
          </div>
        </section>
      </div>
    </Container>
  );
};

ResourcesContainer.propTypes = {
  hasSubscription: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ user }) => ({
  hasSubscription: user.hasSubscription,
  user,
});

export default connect(mapStateToProps)(ResourcesContainer);
