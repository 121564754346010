import { call, put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import {
  FETCH_SUBSCRIPTION_START,
  CREATE_OR_UPDATE_SUBSCRIPTION_START,
  DELETE_SUBSCRIPTION_START,
} from '../actiontypes';
import {
  hydrateSubscriptions,
  deleteSubscriptionSuccess,
  hydrateSubscriptionsAfterCreateOrUpdate,
  hydrateGiftSubscriptions,
  createSubscriptionError,
} from '../actions';
import LifeSteps from '../modules/LifeStepsAPI';

// const delay = ms => new Promise(res => setTimeout(res, ms));

// TODO: Get user subscription and gift subs
export function* fetchSubscriptions() {
  const lsapi = new LifeSteps();

  try {
    const { data } = yield call(axios.get, lsapi.urls.SUBSCRIPTION, {
      headers: lsapi.reqHeaders,
    });
    yield put(hydrateSubscriptions(data));
  } catch (err) {
    yield put(createSubscriptionError(err.response.data.message));
    yield call(console.log, err.response);
  }
}

export function* createSubscriptionsSaga({ payload, cb }) {
  const lsapi = new LifeSteps();
  const [activeSubscription, id] = yield select(state => [
    state.subscriptions.userSub.subscriptionStatus === 'active',
    state.subscriptions.userSub.id,
  ]);

  const url = (() => {
    if ((!activeSubscription && !payload.gift) || payload.gift) {
      return lsapi.urls.SUBSCRIPTION;
    }

    return `${lsapi.urls.SUBSCRIPTION}/${id}`;
  })();
  try {
    const { data } = yield call(axios, {
      method:
        (!activeSubscription && !payload.gift) || payload.gift
          ? 'POST'
          : 'PATCH',
      url,
      headers: lsapi.reqHeaders,
      data: payload,
    });

    yield call(cb);
    if (payload.gift) {
      yield put(hydrateGiftSubscriptions(data));
    } else {
      yield put(hydrateSubscriptionsAfterCreateOrUpdate(data));
    }
  } catch (err) {
    yield put(createSubscriptionError(err.response.data.message));
    yield call(console.log, err);
  }
}

export function* deleteSubscriptionsSaga() {
  const lsapi = new LifeSteps();
  try {
    const id = yield select(state => state.subscriptions.userSub.id);
    const url = `${lsapi.urls.SUBSCRIPTION}/${id}`;

    yield call(axios.delete, url, {
      headers: lsapi.reqHeaders,
    });

    yield put(deleteSubscriptionSuccess());
  } catch (err) {
    yield call(console.log, err);
  }
}

export const subscriptionSagas = [
  takeLatest(FETCH_SUBSCRIPTION_START, fetchSubscriptions),
  takeLatest(CREATE_OR_UPDATE_SUBSCRIPTION_START, createSubscriptionsSaga),
  takeLatest(DELETE_SUBSCRIPTION_START, deleteSubscriptionsSaga),
];
