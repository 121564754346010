import React, { useState } from 'react';
import map from 'lodash/map';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import uuid from 'uuid';

// Components
import Card from '../../Card';
import GiftSubscriptionFlow from '../../Flows/GiftSubscriptionFlow';

// Styled Components
import { Button } from '../../styles/Buttons';
import { CardContent, GiftSubscription, RenewButton } from './styles';

const GiftSubscriptionsCard = ({ subscriptions }) => {
  const [
    isGiftSubscriptionFlowVisible,
    setIsGiftSubscriptionFlowVisible,
  ] = useState(false);

  const hideGiftSubscriptionFlow = () => {
    setIsGiftSubscriptionFlowVisible(false);
  };

  return (
    <>
      <Card width="100%" padding="32px">
        <CardContent>
          <h4 className="section-title">Gift Subscriptions</h4>
          <h5 className="subtitle">
            Manage subscriptions that you have gifted to other members.
          </h5>
          <div className="divider" />
          <div className="table-container">
            {subscriptions &&
              map(
                orderBy(
                  subscriptions,
                  [o => moment(o.endDate).isBefore(moment()), 'end_date'],
                  ['asc', 'asc']
                ),
                item => {
                  const isActive = !moment(item.endDate).isBefore(moment());

                  return (
                    <GiftSubscription active={isActive} key={uuid()}>
                      <div className="column">
                        <div className="member">
                          <h4>{item.recipient.name}</h4>
                          <p>{`Member No. ${item.recipient.memberId}`}</p>
                        </div>
                      </div>
                      <div className="column">
                        <p className={!isActive ? 'expired' : ''}>
                          {`${!isActive ? 'Expired' : 'Ending'} ${moment(
                            item.endDate
                          ).format('MM/DD/YYYY')}`}
                        </p>
                        {/* TODO: Enable gift subscription extend/renew until after launch */}
                        {false && (
                          <RenewButton>
                            {isActive ? 'Extend' : 'Renew'}
                          </RenewButton>
                        )}
                      </div>
                    </GiftSubscription>
                  );
                }
              )}
          </div>
          <div className="actions">
            <Button
              className="pro"
              type="button"
              onClick={() => setIsGiftSubscriptionFlowVisible(true)}
            >
              New Gift Subscription
            </Button>
          </div>
        </CardContent>
      </Card>
      {isGiftSubscriptionFlowVisible &&
        ReactDOM.createPortal(
          <GiftSubscriptionFlow handleClose={hideGiftSubscriptionFlow} />,
          document.body
        )}
    </>
  );
};

GiftSubscriptionsCard.defaultProps = {
  subscriptions: [],
};

GiftSubscriptionsCard.propTypes = {
  subscriptions: PropTypes.array,
};

export default GiftSubscriptionsCard;
