import React, { useRef, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { ExternalLink, AlertCircle } from 'react-feather';
import Image from 'react-image';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router';

// Assets
import {
  defaultProfilePhoto,
  facebook,
  twitter,
  pinterest,
  instagram,
  sitePreview,
} from '../../images';

// Utils
import { getFromLocalStorage } from '../../modules/localStorage';
import LifeSteps from '../../modules/LifeStepsAPI';
import { showName } from '../utils/helper-functions';
import { updateUser, setUser } from '../../actions';

// Components
import Card from '../Card';
import EventsCard from './EventsCard';
import Modal from '../Modal';
import LoadingIndicator from '../utils/LoadingIndicator';
import UpdateBioModal from './UpdateBioModal';
import UpdateSiteTitleModal from './UpdateSiteTitleModal';
import UpdateYlLinkModal from './UpdateYlLinkModal';
import UpdateSocialProfilesModal from './UpdateSocialProfilesModal';
import UpdateSubdomainModal from './UpdateSubdomainModal';
import UpdateSubtitleModal from './UpdateSubtitleModal';
import UpgradeModal from '../UpgradeModal';

// Styled Components
import { Button } from '../styles/Buttons';
import Divider from '../styles/Divider';
import Error from '../styles/Error';
import theme from '../styles/themes';

// *********************************************** //

const AccountSite = ({ submitUserUpdate, updateUserFromApi, user }) => {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showUpdateSiteTitleModal, setShowUpdateSiteTitleModal] = useState(
    false
  );
  const [showUpdateYlLinkModal, setShowUpdateYlLinkModal] = useState(false);
  const [showUpdateSubtitleModal, setShowUpdateSubtitleModal] = useState(false);
  const [showUpdateBioModal, setShowUpdateBioModal] = useState(false);
  const [
    showUpdateSocialProfilesModal,
    setShowUpdateSocialProfilesModal,
  ] = useState(false);
  const [showUpdateSubdomainModal, setShowUpdateSubdomainModal] = useState(
    false
  );
  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
  ] = useState(false);
  const [infoToDelete, setInfoToDelete] = useState('');
  const [error, setError] = useState(null);
  const history = useHistory();
  const [photoIsLoading, setPhotoIsLoading] = useState(false);

  const {
    hasSubscription,
    onBabyStepsTeam: isBabySteps,
    websiteSubdomain: subdomain,
  } = user;

  const handleRestoreDefault = async keyname => {
    await submitUserUpdate({ [keyname]: 'true' });
    setInfoToDelete('');
  };

  const onClickRestoreDefault = keyname => {
    setInfoToDelete(keyname);
    setShowDeleteConfirmationModal(true);
  };

  const onClickCancelRestoreDefault = () => {
    setInfoToDelete('');
    setShowDeleteConfirmationModal(false);
  };

  const onConfirmRestoreDefault = () => {
    handleRestoreDefault(infoToDelete);
    setShowDeleteConfirmationModal(false);
  };

  const canHaveWebsite = () => {
    if (isBabySteps || hasSubscription) {
      return true;
    }
    return false;
  };

  const photoInput = useRef(null);

  const onSelectFile = async e => {
    e.preventDefault();
    const lsapi = new LifeSteps();
    const accessToken = getFromLocalStorage('accessToken');

    // Use FileReader to read the file so we can see a preview
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    let file;

    reader.onloadend = () => {
      // Sets the file in state to the results of the FileReader read
      file = reader.result;
    };

    if (file !== null) {
      URL.revokeObjectURL(file);
    }

    const headers = {
      'Authentication-Token': accessToken,
      'Content-Type': 'multipart/form-data',
    };

    const url = lsapi.urls.UPDATE_USER;
    const data = new FormData();

    data.append('profileImage', e.target.files[0]);

    try {
      setPhotoIsLoading(true);
      const response = await axios.patch(url, data, { headers });

      if (response) {
        updateUserFromApi(response.data);
        setPhotoIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError(err.response.data.message);
      setTimeout(setError(null), 5000);
    }
  };

  const ConfirmDeleteModal = () => {
    const text = {
      deleteProfileImage:
        'This will remove your profile picture from your website.',
      deleteSiteTitle: `This will reset your site title to "${
        user.websiteDefaults?.siteTitle || showName(user, true)
      }".`,
      resetTagline: `This will reset your site title to: "${user.websiteDefaults?.tagline}"`,
      resetBio: `This will reset your bio to: "${user.websiteDefaults?.bio}`,
    };

    return (
      <Modal
        ctaFunc={onConfirmRestoreDefault}
        ctaText="Reset"
        cancelFunc={onClickCancelRestoreDefault}
        open={showDeleteConfirmationModal}
        danger
      >
        <h1>Are you sure?</h1>
        <div>
          <p>{text[infoToDelete]}</p>
        </div>
      </Modal>
    );
  };

  return (
    <Wrapper data-testid="account-site-wrapper">
      {ConfirmDeleteModal()}
      <Card width="100%" padding="32px">
        {canHaveWebsite() ? (
          <CardContent>
            <h4 className="section-title">Website Information</h4>
            <h5 className="subtitle">
              View or update the personal information that you have chosen to
              display on your personal website at anytime and it will update
              your website automatically.
            </h5>
            <div className="divider" />
            <div className="profile-info">
              <InfoItem>
                <div className="title">
                  <span className="info-label">Profile Photo</span>
                  <div className="actions">
                    <button
                      type="button"
                      onClick={() => photoInput.current.click()}
                    >
                      <input
                        type="file"
                        ref={photoInput}
                        accept="image/png,image/jpeg"
                        onChange={onSelectFile}
                      />
                      {user?.profileImage?.square ? 'Update' : 'Add'}
                    </button>
                    {!isEmpty(user.profileImage.square) && (
                      <>
                        <div className="divider" />
                        <button
                          className="delete"
                          type="button"
                          onClick={() =>
                            onClickRestoreDefault('deleteProfileImage')
                          }
                        >
                          Remove
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <div className="image-wrapper">
                  {photoIsLoading ? (
                    <LoadingIndicator dark />
                  ) : (
                    <Image
                      alt={user && `${user.firstName} ${user.lastName} header`}
                      loader={<LoadingIndicator dark />}
                      src={[user.profileImage.square, defaultProfilePhoto]}
                    />
                  )}
                </div>
                {error && (
                  <StyledError>
                    <AlertCircle />
                    <div>{error}</div>
                  </StyledError>
                )}
              </InfoItem>
              <div className="divider" />
              <InfoItem>
                <div className="title">
                  <span className="info-label">Web Address</span>
                  <button
                    type="button"
                    onClick={() => setShowUpdateSubdomainModal(true)}
                  >
                    Edit
                  </button>
                </div>
                <span className="custom-url">
                  <a
                    href={`https://${user.websiteSubdomain}.lifestepseo.com`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{ marginRight: '8px' }}
                    >{`${subdomain}.lifestepseo.com`}</span>
                    <ExternalLink size={16} />
                  </a>
                </span>
              </InfoItem>
              <div className="divider" />
              <InfoItem>
                <div className="title">
                  <span className="info-label">YL Link</span>
                  <div className="actions">
                    <button
                      type="button"
                      onClick={() => setShowUpdateYlLinkModal(true)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <p>{user?.urls.ylLink}</p>
              </InfoItem>
              <div className="divider" />
              <InfoItem>
                <div className="title">
                  <span className="info-label">Site Title</span>
                  <div className="actions">
                    <button
                      type="button"
                      onClick={() => setShowUpdateSiteTitleModal(true)}
                    >
                      Edit
                    </button>
                    {!isEmpty(user.siteTitle) &&
                      user.siteTitle !== user.websiteDefaults?.siteTitle && (
                        <button
                          type="button"
                          className="delete"
                          onClick={() =>
                            onClickRestoreDefault('deleteSiteTitle')
                          }
                        >
                          Reset
                        </button>
                      )}
                  </div>
                </div>
                <p>{showName(user)}</p>
              </InfoItem>
              <div className="divider" />
              <InfoItem>
                <div className="title">
                  <span className="info-label">Subtitle</span>
                  <div className="actions">
                    <button
                      type="button"
                      onClick={() => setShowUpdateSubtitleModal(true)}
                    >
                      Edit
                    </button>
                    {user.websiteDefaults.tagline !== user.tagline && (
                      <button
                        type="button"
                        className="delete"
                        onClick={() => onClickRestoreDefault('resetTagline')}
                      >
                        Reset
                      </button>
                    )}
                  </div>
                </div>
                <p>{user.tagline}</p>
              </InfoItem>
              <div className="divider" />
              <InfoItem>
                <div className="title">
                  <span className="info-label">Bio</span>
                  <div className="actions">
                    <button
                      type="button"
                      onClick={() => setShowUpdateBioModal(true)}
                    >
                      Edit
                    </button>
                    {user.websiteDefaults.bio !== user.bio && (
                      <button
                        type="button"
                        className="delete"
                        onClick={() => onClickRestoreDefault('resetBio')}
                      >
                        Reset
                      </button>
                    )}
                  </div>
                </div>
                <p>{user.bio}</p>
              </InfoItem>
              <div className="divider" />
              <InfoItem>
                <div className="title">
                  <span className="info-label social">Social Profiles</span>
                  <button
                    type="button"
                    onClick={() => setShowUpdateSocialProfilesModal(true)}
                  >
                    {user.facebookUsername ||
                    user.twitterUsername ||
                    user.instagramUsername ||
                    user.pinterestUsername
                      ? 'Edit'
                      : 'Add'}
                  </button>
                </div>
                <div className="social-profiles">
                  {user.facebookUsername && (
                    <div className="username-block">
                      <div className="social-username">
                        <img src={facebook} alt="facebook icon" />
                        <span>{user.facebookUsername}</span>
                      </div>
                    </div>
                  )}
                  {user.twitterUsername && (
                    <div className="username-block">
                      <div className="social-username">
                        <img src={twitter} alt="twitter icon" />
                        <span>{user.twitterUsername}</span>
                      </div>
                    </div>
                  )}
                  {user.instagramUsername && (
                    <div className="username-block">
                      <div className="social-username">
                        <img src={instagram} alt="instagram icon" />
                        <span>{user.instagramUsername}</span>
                      </div>
                    </div>
                  )}
                  {user.pinterestUsername && (
                    <div className="username-block">
                      <div className="social-username">
                        <img src={pinterest} alt="pinterest icon" />
                        <span>{user.pinterestUsername}</span>
                      </div>
                    </div>
                  )}
                </div>
              </InfoItem>
            </div>
          </CardContent>
        ) : (
          <SetupWebsite>
            <h1>Upgrade to Pro</h1>
            <Divider />
            <img src={sitePreview} alt="website titles" />
            <p>
              {`
              As a ${
                isBabySteps ? `Baby Steps Team Member` : `Paid Subscriber`
              }, you get a personalized website to use for
              your Young Living Business. Use this website to tell people about
              yourself, teach new prospects, display any events you are hosting,
              and share hundreds of amazing resources.
              `}
            </p>
            <Divider />
            <Button onClick={() => setShowUpgradeModal(true)}>
              Upgrade Now
            </Button>
          </SetupWebsite>
        )}
      </Card>
      {canHaveWebsite() && <EventsCard />}
      {/* MODALS */}
      <UpdateSubdomainModal
        open={showUpdateSubdomainModal}
        afterSave={() => setShowUpdateSubdomainModal(false)}
        cancelFunc={() => setShowUpdateSubdomainModal(false)}
      />
      <UpdateSiteTitleModal
        open={showUpdateSiteTitleModal}
        afterSave={() => setShowUpdateSiteTitleModal(false)}
        cancelFunc={() => setShowUpdateSiteTitleModal(false)}
      />
      <UpdateYlLinkModal
        open={showUpdateYlLinkModal}
        afterSave={() => setShowUpdateYlLinkModal(false)}
        cancelFunc={() => setShowUpdateYlLinkModal(false)}
      />
      <UpdateSubtitleModal
        open={showUpdateSubtitleModal}
        afterSave={() => setShowUpdateSubtitleModal(false)}
        cancelFunc={() => setShowUpdateSubtitleModal(false)}
      />
      <UpdateBioModal
        open={showUpdateBioModal}
        afterSave={() => setShowUpdateBioModal(false)}
        cancelFunc={() => setShowUpdateBioModal(false)}
      />
      <UpdateSocialProfilesModal
        open={showUpdateSocialProfilesModal}
        afterSave={() => setShowUpdateSocialProfilesModal(false)}
        cancelFunc={() => setShowUpdateSocialProfilesModal(false)}
      />
      <UpgradeModal
        open={showUpgradeModal}
        cancelFunc={() => setShowUpgradeModal(false)}
        ctaFunc={() => {
          history.push('/account/subscriptions/?referrer=upgrade_modal');
          setShowUpgradeModal(false);
        }}
      />
    </Wrapper>
  );
};

AccountSite.propTypes = {
  submitUserUpdate: PropTypes.func.isRequired,
  updateUserFromApi: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = dispatch => ({
  submitUserUpdate: (user, cb) => dispatch(updateUser(user, cb)),
  updateUserFromApi: userInfo => dispatch(setUser(userInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSite);

// Styles
const { breakpoints, colors, fonts } = theme;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SetupWebsite = styled.div`
  h1 {
    margin-bottom: 1.5rem;
    font-family: ${fonts.family.sans};
    font-weight: ${fonts.weight.medium};
    color: ${colors.black};
  }

  p {
    margin: 1.5rem 0;
    font-weight: ${fonts.weight.lighter};
    text-align: left;
  }

  button {
    margin: 1.5rem auto 0;
  }

  img {
    margin-top: 1.5rem;
    max-width: 80%;
  }
`;

const CardContent = styled.section`
  text-align: left;

  h4 {
    margin: 0 0 0.5rem;
    font-size: ${fonts.size.s};
    font-weight: ${fonts.weight.demi};
    text-transform: uppercase;
  }

  h5.subtitle {
    margin: 0.5rem 0 1.5rem;
    padding: 0;
    font-size: ${fonts.size.lg};
    font-weight: ${fonts.weight.lighter};
    line-height: 1.4;
    color: ${colors.text.darkGray};
  }

  .divider {
    height: 1px;
    margin: 1.5rem 0;
    background-color: ${colors.dividerGray};
  }

  .events {
    margin: 1.5rem 0;
  }

  @media (max-width: ${breakpoints.mobile}) {
    button {
      width: 100%;
    }
  }
`;

const InfoItem = styled.div`
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .info-label {
      font-size: ${fonts.size.s};
      font-weight: ${fonts.weight.demi};
      color: ${colors.text.black};
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    input[type='file'] {
      position: relative;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
      display: none;
    }

    button {
      width: auto;
    }
  }

  .image-wrapper {
    width: 100px;
    height: 100px;

    img {
      align-self: flex-start;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  p {
    margin: 0;
    font-size: ${fonts.size.lg};
    font-weight: ${fonts.weight.lighter};
  }

  .actions {
    display: flex;
  }

  button {
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    font-size: ${fonts.size.md};
    font-weight: ${fonts.weight.regular};
    color: ${colors.green};
    background: none;
    border: none;

    &:hover {
      text-decoration: underline;
      text-decoration-color: ${colors.green};
      cursor: pointer;
      opacity: 0.7;
    }

    span {
      margin-right: 0.5rem;
    }
  }

  .divider {
    height: 100%;
    width: 1px;
    background-color: ${colors.dividerGray};
  }

  button.delete {
    color: ${colors.roles.danger};
    text-decoration-color: ${colors.roles.danger};
  }

  .custom-url {
    a {
      display: flex;
      align-items: flex-end;
      font-size: ${fonts.size.lg};
      font-weight: ${fonts.weight.regular};
      color: ${colors.darkGreen};
      text-decoration: none;

      .domain-name {
        margin-right: 0.5rem;
      }

      &:hover {
        text-decoration: underline;
        text-decoration-color: ${colors.darkGreen};
        cursor: pointer;
      }
    }
  }

  .social-profiles {
    h4 {
      font-size: ${fonts.size.s};
      font-weight: ${fonts.weight.regular};
      color: ${colors.text.gray};
      padding: 1rem 0 0.5rem;
    }

    .username-block {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .social-username {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        font-size: ${fonts.size.lg};
        font-weight: ${fonts.weight.lighter};

        img {
          margin: 0 1rem 0 0;
          height: 2rem;
          width: 2rem;
        }

        .domain {
          margin-left: 1rem;
          font-weight: ${fonts.weight.lighter};

          @media (max-width: ${breakpoints.mobile}) {
            margin-left: 0.5rem;
          }
        }

        @media (max-width: ${breakpoints.mobile}) {
          font-size: ${fonts.size.md};
          font-weight: ${fonts.weight.light};

          .domain {
            font-weight: ${fonts.weight.light};
          }

          img {
            height: 1rem;
            width: 1rem;
          }
        }
      }
    }
  }
`;

const StyledError = styled(Error)`
  justify-content: flex-start;
`;
