import React, { createContext, useReducer } from 'react';

const MainContext = createContext();

const initialState = {
  isModalOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_MODAL_IS_OPEN':
      return { ...state, modalIsOpen: action.payload };
    default:
      return state;
  }
};

const MainContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  const { children } = props;

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};

const MainContextConsumer = MainContext.Consumer;

export { MainContext, MainContextProvider, MainContextConsumer };
