const customStyles = {
  option: provided => ({
    ...provided,
    color: '#2C2D30',
    padding: 16,
    fontSize: '0.875rem',
    fontFamily: 'sofia-pro, san-serif',
    fontWeight: 300,
  }),
  container: () => ({
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  control: provided => ({
    ...provided,
    padding: 12,
    border: '2px solid #E4E4E4',
    borderRadius: '4px',
    width: '100%',
    fontSize: '0.875rem',
    fontWeight: '300',
    fontFamily: 'sofia-pro, san-serif',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #E4E4E',
      boxShadow: 'none',
    },
    '&:focus': {
      border: '1px solid #E4E4E',
      boxShadow: 'none',
    },
    '&:active': {
      border: '1px solid #E4E4E',
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: provided => ({
    ...provided,
    padding: 0,
    fontSize: '0.875rem',
    fontWeight: '300',
    fontFamily: 'sofia-pro, san-serif',
    borderRadius: '4px',
    width: '100%',
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: '0.875rem',
    fontWeight: '300',
    fontFamily: 'sofia-pro, san-serif',
    color: '#969696',
  }),
  dropdownIndicator: () => ({
    color: '#218CBC',
  }),
};

export default customStyles;
