import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';
import rootSaga from './sagas';
import {
  loadStateFromLocalStorage,
  saveStateToLocalStorage,
} from './modules/localStorage';

// Saga middleware
const sagaMiddleware = createSagaMiddleware();

// Grab state from localStorage
const persistedState = loadStateFromLocalStorage();

const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

// Save state changes to localStorage
store.subscribe(() => {
  const state = store.getState();
  const { user } = state;
  saveStateToLocalStorage({ state: { user } });
});

sagaMiddleware.run(rootSaga);

export default store;
