import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// Assets
import subBillingCycle from '../../../images/sub-billing-cycle.svg';
import subGiftSubscription from '../../../images/sub-gift-sub.svg';

// Components
import SubscriptionModal from '../SubscriptionModal';

// Styled Components
import { BillingCycleOption, ButtonRow } from './styles';
import { Button, ModalCancelButton } from '../../styles/Buttons';
import { ButtonWrapper, Instructions } from '../SubscriptionModal/styles';

// *********************************************** //

const ChooseBillingCycleModal = ({
  subscription,
  giftRecipient,
  handleClose,
  nextStep,
  onSelectPlan,
}) => {
  const defaultPlan = (() => {
    if (giftRecipient) {
      return 'quarter';
    }

    if (isEmpty(subscription)) {
      return 'quarter';
    }

    return subscription?.plan;
  })();

  const [selectedPlan, setSelectedPlan] = useState(defaultPlan);

  function selectPlan(plan) {
    onSelectPlan(plan);
    setSelectedPlan(plan);
  }

  function confirmSelection() {
    nextStep(selectedPlan);
  }

  return (
    <SubscriptionModal
      title={giftRecipient ? 'Gift Subscription' : 'Billing Cycle'}
      titleImageSrc={giftRecipient ? subGiftSubscription : subBillingCycle}
    >
      <Instructions>
        <p>
          {(!subscription || giftRecipient) &&
            `Choose a ${giftRecipient ? 'length' : 'billing cycle'} for this ${
              giftRecipient ? 'gift' : ''
            } subscription.`}
        </p>
      </Instructions>
      <ButtonRow>
        <BillingCycleOption
          className={selectedPlan === 'month' ? 'active' : ''}
          onClick={() => selectPlan('month')}
        >
          <h1>$8.99</h1>
          <div>
            <p>{giftRecipient ? 'One Month' : 'Charged Monthly'}</p>
          </div>
        </BillingCycleOption>
        <BillingCycleOption
          className={selectedPlan === 'quarter' ? 'active' : ''}
          onClick={() => selectPlan('quarter')}
        >
          <h1>$24.00</h1>
          <div>
            <p>{giftRecipient ? 'Three Months' : 'Charged Quarterly (3mos)'}</p>
          </div>
          <div className="discount-percentage">9% Discount</div>
        </BillingCycleOption>
      </ButtonRow>
      <ButtonWrapper>
        <Button className="pro" onClick={confirmSelection} type="button">
          Continue to Payment Info
        </Button>
        <ModalCancelButton onClick={handleClose} type="button">
          Cancel
        </ModalCancelButton>
      </ButtonWrapper>
    </SubscriptionModal>
  );
};

ChooseBillingCycleModal.propTypes = {
  giftRecipient: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  onSelectPlan: PropTypes.func.isRequired,
  subscription: PropTypes.object,
};

const mapStateToProps = ({ subscriptions }) => ({
  subscription: subscriptions.userSub,
});

export default connect(mapStateToProps)(ChooseBillingCycleModal);
