import React, { useEffect, useState } from 'react';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import styled from 'styled-components';

// Components
import FeatureItem from './FeatureItem';

// Styled Components
import theme from '../styles/themes';

const { fonts } = theme;

// Styles
const Wrapper = styled.div`
  p.more {
    margin: 2rem 0 0;
    font-weight: ${fonts.weight.regular};
  }
`;

// *********************************************** //

const UpgradeFeatures = () => {
  /**
   * Set features from Features JSON
   */
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const fetchFeatures = async () => {
      const { data } = await axios.get(process.env.REACT_APP_S3_FEATURES_URL);

      setFeatures(data.upgrade_modal);
    };

    fetchFeatures();
  }, []);

  return (
    <Wrapper>
      {!isEmpty(features) && (
        <>
          <div>
            {map(features, (item, idx) => (
              <FeatureItem item={item} key={idx} />
            ))}
          </div>
          <p className="more">
            Plus PRO Content and{' '}
            <a
              href="https://www.lifestepsapp.com/pricing"
              target="_blank"
              rel="noopener noreferrer"
            >
              much more
            </a>
            !
          </p>
        </>
      )}
    </Wrapper>
  );
};

export default UpgradeFeatures;
