import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Utils
import { updateUser } from '../../../actions';

// Components
import Input from '../../utils/Input';
import Modal from '../../Modal';
import ModalContent from '../../styles/ModalContent';

// Styled Components
import theme from '../../styles/themes';
import Divider from '../../styles/Divider';

// *********************************************** //

const UpdateSubdomainModal = ({
  afterSave,
  cancelFunc,
  open,
  updateSubdomain,
  user,
}) => {
  const [subdomain, setSubdomain] = useState(user.websiteSubdomain);

  const onClickSaveChanges = () => {
    updateSubdomain(subdomain);
    afterSave();
  };

  return (
    <Modal cancelFunc={cancelFunc} ctaFunc={onClickSaveChanges} open={open}>
      <h1 className="modal-title">Update Your Web Address</h1>
      <Divider />
      <ModalContent>
        <div className="flex">
          <div className="flex-1">
            <Input
              onChange={e => setSubdomain(e.target.value)}
              placeholder="subdomain"
              fieldType="text"
              withLabel={false}
              value={subdomain}
            />
          </div>
          <DomainName>.lifestepseo.com</DomainName>
        </div>
      </ModalContent>
    </Modal>
  );
};

UpdateSubdomainModal.defaultProps = {
  afterSave: null,
};

UpdateSubdomainModal.propTypes = {
  afterSave: PropTypes.func,
  cancelFunc: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updateSubdomain: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = dispatch => ({
  updateSubdomain: subdomain =>
    dispatch(updateUser({ websiteSubdomain: subdomain })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateSubdomainModal);

// Styles
const { breakpoints, fonts } = theme;

const DomainName = styled.div`
  padding: 0 1.5rem;
  font-size: ${fonts.size.lg};
  font-weight: ${fonts.weight.regular};
  letter-spacing: 0.4px;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fonts.size.md};
    padding: 0 0 0 0.5rem;
  }
`;
