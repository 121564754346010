import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ChevronLeft, ChevronRight } from 'react-feather';
import theme from '../styles/themes';

// Styles
const { colors, fonts } = theme;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  max-width: 100%;
  flex-wrap: wrap;

  button {
    display: flex;
    align-items: center;
    margin: 0 0.25rem;
    font-size: ${fonts.size.md};
    font-weight: ${fonts.weight.medium};
    text-transform: uppercase;
    background-color: transparent;
    color: ${colors.text.gray};
    border: none;

    svg {
      height: 18px;
      width: 18px;
    }

    &.text-button {
      color: ${colors.green};

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }

      &:active {
        opacity: 0.9;
        cursor: pointer;
      }
    }

    &:hover {
      color: ${colors.green};
      cursor: pointer;
    }

    &:active,
    &:focus {
      color: ${colors.green};
    }
  }
`;

const range = (from, to, step = 1) => {
  let i = from;
  const numRange = [];

  while (i <= to) {
    numRange.push(i);
    i += step;
  }

  return numRange;
};

const PaginationControls = ({ activePage, onClickPageNumber, totalPages }) => {
  const fetchPageNumbers = () => {
    const pageNeighbours = 4;
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and
     * right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(1, activePage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, activePage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [...pages];
          break;
        }
      }

      return pages;
    }

    return range(1, totalPages);
  };

  const pages = fetchPageNumbers();
  const renderButtons = () =>
    pages.map(page => (
      <button
        className={activePage === page ? 'text-button' : ''}
        onClick={() => {
          onClickPageNumber(page);
        }}
        type="button"
        key={page}
      >
        {page}
      </button>
    ));
  return (
    <Container>
      {activePage !== 1 && (
        <button
          className="text-button"
          type="button"
          onClick={() => {
            onClickPageNumber(activePage - 1);
          }}
        >
          <ChevronLeft />
          Back
        </button>
      )}
      {pages.length > 1 && renderButtons()}
      {activePage !== totalPages && (
        <button
          className="text-button"
          type="button"
          onClick={() => {
            onClickPageNumber(activePage + 1);
          }}
        >
          Next <ChevronRight />
        </button>
      )}
    </Container>
  );
};

PaginationControls.defaultProps = {
  activePage: 1,
  totalPages: 1,
};

PaginationControls.propTypes = {
  activePage: PropTypes.number,
  onClickPageNumber: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
};

export default PaginationControls;
