import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Smartphone } from 'react-feather';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import uuid from 'uuid';

// Modules and Utils
import { downloadSingleFile, downloadZipFile } from '../utils/helper-functions';
import LifeSteps from '../../modules/LifeStepsAPI';
import { SentryErrorHandler } from '../utils/SentryErrorHandler';

// Components
import ImageThumbnail from './ImageThumbnail';
import LinkList from '../LinkList';
import LoadingIndicator from '../utils/LoadingIndicator';
import Markdown from '../Markdown';
import ResourceButtons from './ResourceButtons';
import Tags from './Tags';

// Styled components
import { Button } from '../styles/Buttons';
import { LargeTitle } from '../styles/Text';
import theme from '../styles/themes';

// Styles
const { breakpoints, colors, fonts } = theme;

const Wrapper = styled.div`
  width: 100%;

  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: 60vh;

    @media (max-width: ${breakpoints.mobile}) {
      width: 100%;
    }
  }

  .unselectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  a.no-decoration {
    text-decoration: none;

    @media (max-width: ${breakpoints.mobile}) {
      width: 100%;
    }
  }
`;

export const BigTitle = styled.h1`
  margin: 0 0 0.5rem;
  font-family: ${fonts.family.serif};
  font-size: ${fonts.size.xxl};
  font-weight: ${fonts.weight.bold};
  line-height: 1em;
  color: ${colors.black};
  text-align: center;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fonts.size.xxl};
  }
`;

const ViewTitle = styled(BigTitle)`
  margin-bottom: 2rem;
`;

const ResourceCard = styled.div`
  margin: 0 auto 1rem;

  .video-wrapper {
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 2rem;

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .header-image {
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buttons {
    button {
      width: 256px;
      margin-bottom: 0.5rem;

      @media (max-width: ${breakpoints.mobile}) {
        width: 100%;
        max-width: 100%;

        button,
        a {
          width: 100%;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    @media (max-width: ${breakpoints.mobile}) {
      width: 100%;
    }
  }
`;

const Description = styled.div`
  margin-bottom: 2rem;
  padding-top: 1rem;
  text-align: left;
  border-top: 1px solid ${colors.lightGray};
  white-space: pre-wrap;
`;

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  /* This fixes the last row if it isn't full */
  ::after {
    content: '';
    flex: 0 0 32%;
  }

  .download-image {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    width: calc((100% - 1rem) / 2);

    img {
      height: 12rem;
      object-fit: cover;
      object-position: center;
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: ${breakpoints.desktop}) {
    .download-image {
      width: calc((100% - 2rem) / 2);
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .download-image {
      width: 100%;
    }
  }
`;

const StyledLink = styled.a`
  min-width: 256px;
  padding: 0.75rem 2rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  background: ${colors.white};
  color: ${colors.green};
  border: none;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &.bordered {
    display: block;
    max-width: 320px;
    margin: 0 auto 0.5rem;
    border: 1px solid ${colors.green};

    @media (max-width: ${breakpoints.mobile}) {
      max-width: 100%;
    }
  }

  &.solid {
    background: ${colors.green};
    color: ${colors.white};
    border: none;
  }
`;

const ProgressBarStyles = styled.div`
  height: 20px;
  width: 100%;
  background-color: ${colors.lightGray};
  border-radius: 5px;

  .progress-bar {
    height: 100%;
    width: ${props => `${props.completed}%`};
    background-color: ${colors.green};
    border-radius: inherit;
    text-align: right;
    transition: width 2s;
  }

  .label {
    padding: 5px;
    color: white;
    font-weight: ${fonts.weight.bold};
  }
`;

const ErrorStyles = styled.div`
  color: ${colors.roles.danger};
  font-weight: ${fonts.weight.bold};
`;

const Header = styled.div`
  margin-bottom: 2rem;
  text-align: center;
`;

const Title = styled(LargeTitle)`
  padding-top: 2rem;
  font-size: 2rem;
  border-top: 1px solid ${theme.colors.lightGray};

  @media (max-width: ${theme.breakpoints.mobile}) {
    font-size: 1.5rem;
  }
`;

const Resource = ({
  handleClickTag,
  hasSubscription,
  initialSlug,
  scrollWrapperToTop,
}) => {
  const [slug, setSlug] = useState(initialSlug);
  const [resource, setResource] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [error, setError] = useState(null);

  // Get API
  const lsapi = new LifeSteps();

  // Get Resource Details based on slug
  const { data } = useQuery(
    ['resource details', slug],
    () => lsapi.getResourceDetails(slug),
    {
      staleTime: 60000,
    }
  );

  // Update resource if data changes
  useEffect(() => {
    if (data) {
      // When a new resource is set, scroll the modal to the top
      setResource(data.data);
    }
  }, [data, scrollWrapperToTop]);

  function handleProgressUpdate(metadata) {
    // Update the download progress
    setDownloadProgress(metadata.percent || 0);
    // When the download is complete, set downloading to false
    if (JSON.stringify(metadata.percent) === '100') {
      setDownloading(false);
    }
  }

  function handleError(e) {
    // Set downloading to false
    setDownloading(false);
    // Stringify the error to display
    const stringifiedError = JSON.stringify(e);
    // Set the error key to the stringified error value
    setError(stringifiedError);
    // Report the error to Sentry
    SentryErrorHandler(e);
  }

  function downloadAllImages() {
    // If trying the download again, we don't want an error to show.
    setError(null);
    // Hide the download button and show the progress bar
    setDownloading(true);
    // Zip and download the file(s)
    downloadZipFile({
      folderName: resource.title,
      fileUrls: resource.downloadableImages,
      handleProgress: handleProgressUpdate,
      handleError,
    });
  }

  function downloadFile(url) {
    // If there's only one file, download it.
    downloadSingleFile(url, last(url.split('/')));
  }

  return (
    <Wrapper>
      {!resource ? (
        <div className="loader-container">
          <LoadingIndicator dark size="4rem" />
        </div>
      ) : (
        <>
          <ViewTitle>{resource.title}</ViewTitle>
          <ResourceCard>
            {resource.videoUrl ? (
              <div className="video-wrapper">
                <ReactPlayer
                  className="react-player"
                  url={resource.videoUrl}
                  controls
                  width="100%"
                  height="100%"
                />
              </div>
            ) : (
              <div className="header-image">
                <ImageThumbnail
                  fullsize
                  fullSrc={resource.primaryImageUrls.fullUrl}
                  largeSrc={resource.primaryImageUrls.largeUrl}
                  thumbSrc={resource.primaryImageUrls.thumbUrl}
                />
              </div>
            )}
            {!isEmpty(resource.tags) && (
              <Tags handleClick={handleClickTag} tags={resource.tags} onCard />
            )}
            <div className="buttons">
              <ResourceButtons resource={resource} />
              {!isEmpty(resource.openInAppUrl) && (
                <a
                  href={resource.openInAppUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="no-decoration"
                >
                  <Button className="inverse">
                    <Smartphone style={{ marginRight: 8 }} />
                    View In App
                  </Button>
                </a>
              )}
              {resource.downloadableImages.length > 0 && !downloading && (
                <Button className="inverse" onClick={downloadAllImages}>
                  Download All Files
                </Button>
              )}
              {downloading && (
                <ProgressBarStyles completed={downloadProgress}>
                  <div
                    className="progress-bar"
                    aria-valuenow={downloadProgress}
                  >
                    <span className="label">{`${downloadProgress}%`}</span>
                  </div>
                </ProgressBarStyles>
              )}
              {error && (
                <ErrorStyles>
                  <span>{JSON.stringify(error)}</span>
                </ErrorStyles>
              )}
            </div>
          </ResourceCard>
          {resource.body && (
            <Description className={!hasSubscription && 'unselectable'}>
              <Markdown input={resource.body} />
            </Description>
          )}
          {!isEmpty(resource.linkLists) &&
            map(resource.linkLists, (item, index) => (
              <LinkList
                key={index}
                links={item.links}
                onPress={scrollWrapperToTop}
                subtitle={item.subtitle}
                title={item.title}
                setSlug={setSlug}
              />
            ))}
          {!isEmpty(resource?.downloadableImages) && (
            <Header>
              <Title>Downloadable Images</Title>
            </Header>
          )}
          <Images>
            {resource.downloadableImages.map(image => (
              <div key={uuid()} className="download-image">
                <img src={image.thumbUrl} alt="" />
                <StyledLink
                  onClick={() => downloadFile(image.fullUrl)}
                  href={image.fullUrl}
                  target="_system"
                  rel="noopener noreferrer"
                  download
                >
                  Download Image
                </StyledLink>
              </div>
            ))}
          </Images>
        </>
      )}
    </Wrapper>
  );
};

Resource.propTypes = {
  handleClickTag: (props, propName, componentName) => {
    const { type, ...rest } = props;
    if (type === 'resource' && !rest[propName]) {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'. When '${componentName} has 'type' set to 'resource', '${propName}' cannot be ${rest[propName]}.`
      );
    }

    return null;
  },
  hasSubscription: PropTypes.bool.isRequired,
  initialSlug: PropTypes.string.isRequired,
  scrollWrapperToTop: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user }) => ({
  hasSubscription: user.hasSubscription,
  websiteSubdomain: user.websiteSubdomain,
});

export default connect(mapStateToProps)(Resource);
