import React from 'react';

// Assets
import appStoreBadgeSrc from '../../images/app-store-button.svg';
import playStoreBadgeSrc from '../../images/play-store-button.svg';

// Styled Components
import Wrapper from './styles';

// *********************************************** //

export default function AppStoreLinks() {
  return (
    <Wrapper>
      <a
        href="https://apps.apple.com/us/app/life-steps-app/id1570504952"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={appStoreBadgeSrc} alt="Download for iPhone" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.supportstream.lifesteps"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={playStoreBadgeSrc} alt="Download for Android" />
      </a>
    </Wrapper>
  );
}
