import styled from 'styled-components';

// Styled Components
import theme from '../styles/themes';

const { breakpoints } = theme;

// Styles
const Wrapper = styled.div`
  padding: 1rem 0 2rem;

  a {
    &:first-child {
      margin-right: 1rem;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  img {
    height: 3.5rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    img {
      height: 2.5rem;
    }
  }
`;

export default Wrapper;
