import { call, put, takeLatest } from 'redux-saga/effects';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';

import LifeSteps from '../modules/LifeStepsAPI';
import {
  setUserAuthentication,
  userIsLoading,
  setUser,
  signInError,
  resetInProgressChange,
  updateUserError,
} from '../actions';
import { SIGN_IN_USER, UPDATE_USER, HYDRATE_USER } from '../actiontypes';
import { saveStateToLocalStorage } from '../modules/localStorage';

const delay = ms => new Promise(res => setTimeout(res, ms));

export function* authenticateUser(action) {
  const lsapi = new LifeSteps();

  try {
    yield put(userIsLoading());
    const response = yield call(lsapi.getAccessToken, action.body);
    yield call(saveStateToLocalStorage, {
      key: 'accessToken',
      state: response.data.authToken,
    });
    yield put(setUser(response.data));
    yield put(setUserAuthentication(true));
  } catch (err) {
    yield put(signInError(err.response.data.message));
  }
}

export function* hydrateUser(action) {
  const lsapi = new LifeSteps();
  try {
    const response = yield call(lsapi.getUser, action.id);
    yield put(setUser(response.data));
  } catch (e) {
    console.log(e);
    yield put(
      updateUserError(e.response ? e.response.data.message : e.message)
    );
    yield call(delay, 5000);
    yield put(updateUserError(null));
  }
}

export function* updateUser(action) {
  const lsapi = new LifeSteps();

  const user = omitBy(action.user, isEmpty);
  try {
    yield put(userIsLoading());
    const response = yield call(lsapi.updateUser, user);
    yield put(setUser(response.data));
    if (action.cb) {
      action.cb();
    }
    yield put(resetInProgressChange());
  } catch (e) {
    yield put(updateUserError(e?.response?.data?.message));
    yield call(delay, 5000);
    yield put(updateUserError(null));
  }
}

export const userSagas = [
  takeLatest(SIGN_IN_USER, authenticateUser),
  takeLatest(HYDRATE_USER, hydrateUser),
  takeLatest(UPDATE_USER, updateUser),
];
