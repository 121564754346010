import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

// Modules & Utils
import { constructButtonUrl } from '../../utils/helper-functions';

// Components
import ButtonWithIcon from '../../ButtonWithIcon';
import CopyButton from './CopyButton';

// Styled Components
import theme from '../../styles/themes';

const { breakpoints } = theme;

const Wrapper = styled.div`
  margin: auto;
  max-width: 300px;

  @media (max-width: ${breakpoints.mobile}) {
    max-width: unset;
    width: 100%;
  }

  a {
    display: block;
    margin-bottom: 0.5rem;

    &,
    &:focus,
    &:hover,
    &:visited,
    &:active {
      text-decoration: none;
    }
  }

  > * {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

// *********************************************** //

const ResourceButtons = ({ isPro, resource, replacements, shortKey }) => {
  const { buttons } = resource;
  const renderButton = options => {
    const { label, isInverted, subType, type, value } = options;

    const buttonUrl = constructButtonUrl({
      replacements,
      shortKey,
      subType,
      url: value || '',
    });

    switch (type) {
      case 'share':
        return null;

      case 'copy':
        return (
          <CopyButton
            key={uuidv4()}
            isInverted={isInverted}
            isPro={isPro}
            label={label}
            subType={subType}
            url={buttonUrl}
          />
        );

      case 'external': {
        return (
          <a
            href={buttonUrl}
            key={uuidv4()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonWithIcon
              buttonText={isEmpty(label) ? 'Visit Site' : label}
              icon="externalLink"
              isInverted={isInverted}
            />
          </a>
        );
      }

      case 'internal':
        return (
          <Link to={value} key={uuidv4()}>
            <ButtonWithIcon buttonText={label} isInverted={isInverted} />
          </Link>
        );

      case 'modal':
        return (
          <ButtonWithIcon
            className="margin-bottom"
            key={uuidv4()}
            buttonText={label}
            isInverted={isInverted}
          />
        );

      default:
        return null;
    }
  };

  const renderedButtons = useMemo(
    () =>
      map(buttons, ({ label, subType, type, value }, index) => {
        const isInverted = index !== 0;
        return renderButton({
          label,
          isInverted,
          resourceId: resource.slug,
          subType,
          type,
          value,
        });
      }),
    // eslint-disable-next-line
    [buttons, resource]
  );

  return <Wrapper>{renderedButtons}</Wrapper>;
};

ResourceButtons.propTypes = {
  isPro: PropTypes.bool,
  replacements: PropTypes.object.isRequired,
  resource: PropTypes.object,
  shortKey: PropTypes.string.isRequired,
};

ResourceButtons.defaultProps = {
  isPro: false,
  resource: {},
};

const mapStateToProps = ({ user }) => ({
  replacements: user.replacements,
  shortKey: user.shortKey,
  isPro: user.hasSubscription,
});

export default connect(mapStateToProps)(ResourceButtons);
