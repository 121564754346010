import React, { useMemo, useState } from 'react';
import { ChevronDown } from 'react-feather';
import find from 'lodash/find';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import uuidv4 from 'uuid/v4';

// Ito;s
import { titleCase } from '../utils/helper-functions';

// Styled Components
import { StyledSidebar } from '../styles/Sidebar';
import theme from '../styles/themes';

// *********************************************** //

const ResourceSidebar = ({ selectedItem, items, onClick, title }) => {
  const [selectedOption, setSelectedOption] = useState();

  const dropdownOptions = map(items, item => (
    <option key={item.slug} value={item.slug}>
      {titleCase(item.title)}
    </option>
  ));

  const listItems = useMemo(
    () =>
      map(items, (item, idx) => {
        const className = (() => {
          if (selectedItem) {
            if (item.slug === selectedItem) {
              return 'active';
            }
            if (item.slug === selectedItem.slug) {
              return 'active';
            }
          }

          return '';
        })();

        return (
          <li
            key={item.slug || uuidv4()}
            onClick={() => {
              onClick(item);
              window.scrollTo(0, 0);
            }}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                onClick(item);
                window.scrollTo(0, 0);
              }
            }}
            role="menuitem"
            tabIndex={idx}
            className={className}
          >
            <span>{titleCase(item.title)}</span>
          </li>
        );
      }),
    [items, selectedItem, onClick]
  );

  return (
    <StyledSidebar>
      <div className="sidebar">
        <h1>{title}</h1>
        <List>{listItems}</List>
        <Flex>
          <ListSelect
            onChange={e => {
              onClick(find(items, { slug: e.target.value }));
              setSelectedOption(e.target.value);
              window.scrollTo(0, 0);
            }}
            value={selectedOption}
          >
            {dropdownOptions}
          </ListSelect>
          <span className="icon">
            <ChevronDown />
          </span>
        </Flex>
      </div>
    </StyledSidebar>
  );
};

ResourceSidebar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string }))
    .isRequired,
  onClick: PropTypes.func,
  selectedItem: PropTypes.object,
  title: PropTypes.string.isRequired,
};

export default ResourceSidebar;

// Styles
const { breakpoints, colors, fonts } = theme;

const List = styled.ul`
  @media (max-width: ${breakpoints.desktop}) {
    display: none;
  }

  li {
    cursor: pointer;
    &.active {
      font-weight: ${fonts.weight.bold};
      color: ${colors.green};
    }

    &:focus {
      outline: none;
    }
  }
`;

const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${colors.darkerDividerGray};
  border-radius: 4px;

  .icon {
    position: absolute;
    right: 0.75rem;
    margin-top: 3px;
    color: ${colors.green};
    pointer-events: none;
  }

  @media (min-width: ${breakpoints.desktop}) {
    display: none;
  }

  @media (min-width: ${breakpoints.mobile}) {
    margin: 0 auto 1rem;
    max-width: 280px;
  }
`;

const ListSelect = styled.select`
  width: 100%;
  font-size: ${fonts.size.s};
  font-weight: ${fonts.weight.light};
  color: ${colors.darkGray};
  outline: none;
  background: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  @media (min-width: ${breakpoints.mobile}) {
    justify-self: flex-end;
  }
`;
