import styled from 'styled-components/macro';

// Styled Components
import theme from '../../styles/themes';

const { breakpoints, colors } = theme;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button:first-child {
    margin-bottom: 0.5rem;
  }
`;

export const Instructions = styled.div`
  margin: 2rem 0;
  font-size: 1.125rem;
  line-height: 1.125;
  text-align: center;
  color: ${colors.darkGray};

  p {
    margin: 0;
    text-align: center;
    line-height: 1.4;
  }

  span {
    font-weight: 600;
    color: ${colors.plum};
  }

  @media (max-width: ${breakpoints.mobile}) {
    max-width: 80%;
    margin: 1.5rem auto;
  }
`;

export const LightPlumBox = styled.div`
  margin: 2rem 0;
  padding: 2rem 0 0.5rem;
  text-align: center;
  background: ${colors.lightPlum};
  border-radius: 5px;

  p {
    margin: 0 0 0.5rem;
    font-size: 1.125rem;
    font-weight: 500;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-height: calc(100% - 2rem);
  padding: 2rem 2rem 3rem;
  background: ${colors.white};
  border-radius: 10px;
  box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.15);
  z-index: 1001;
  overflow-y: scroll;

  @media (max-width: ${breakpoints.mobile}) {
    width: calc(100% - 2rem);
    padding: 1rem 1rem 2rem;
  }
`;

export const TitleGraphic = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 168px;
  padding: 2.5rem 0 2rem;
  background: ${colors.plum};
  border-radius: 5px;
  overflow: hidden;

  img.leaves {
    position: absolute;
    max-width: 200px;
    top: 6%;
    left: -16%;
    z-index: 0;
  }

  img.title {
    z-index: 1;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    height: auto;

    img.title {
      width: 80%;
    }
  }
`;

export const Scrim = styled.div`
  position: fixed;
  background: #272727;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;
