import React from 'react';
import PropTypes from 'prop-types';

// Components
import ChooseBillingCycleModal from '../../SubscriptionModals/ChooseBillingCycleModal';
import EnterCreditCardInfoModal from '../../SubscriptionModals/EnterCreditCardInfoModal';
import SubscriptionSuccessModal from '../../SubscriptionModals/SubscriptionSuccessModal';

// Styled Components
import Scrim from '../../styles/Scrim';
import useSubscriptionFlow from '../../utils/Hooks/useSubscriptionFlow';

// *********************************************** //

const NewSubscriptionFlow = ({ handleClose }) => {
  const {
    billingCycle,
    continueToPaymentInfo,
    incrementStep,
    setBillingCycle,
    step,
  } = useSubscriptionFlow();

  return (
    <>
      <Scrim onClick={handleClose} />
      {step === 0 && (
        <ChooseBillingCycleModal
          handleClose={handleClose}
          onSelectPlan={setBillingCycle}
          nextStep={continueToPaymentInfo}
        />
      )}
      {step === 1 && (
        <EnterCreditCardInfoModal
          handleClose={handleClose}
          nextStep={incrementStep}
          selectedPlan={billingCycle}
        />
      )}
      {step === 2 && <SubscriptionSuccessModal handleClose={handleClose} />}
    </>
  );
};

NewSubscriptionFlow.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default NewSubscriptionFlow;
