import {
  FETCH_SUBSCRIPTION_START,
  FETCH_SUBSCRIPTION_SUCCESS,
  CREATE_OR_UPDATE_SUBSCRIPTION_START,
  CREATE_OR_UPDATE_SUBSCRIPTION_ERROR,
  CREATE_OR_UPDATE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_START,
  DELETE_SUBSCRIPTION_SUCCESS,
  CREATE_GIFT_SUBSCRIPTION_SUCCESS,
  CLEAR_SUBSCRIPTION_ERROR,
} from '../actiontypes';

export const fetchSubscriptions = () => ({
  type: FETCH_SUBSCRIPTION_START,
});

export const hydrateSubscriptions = subscriptions => ({
  type: FETCH_SUBSCRIPTION_SUCCESS,
  payload: subscriptions,
});

export const createSubscription = (body, cb) => ({
  type: CREATE_OR_UPDATE_SUBSCRIPTION_START,
  payload: body,
  cb,
});

export const hydrateSubscriptionsAfterCreateOrUpdate = subscription => ({
  type: CREATE_OR_UPDATE_SUBSCRIPTION_SUCCESS,
  payload: subscription,
});

export const hydrateGiftSubscriptions = subscription => ({
  type: CREATE_GIFT_SUBSCRIPTION_SUCCESS,
  payload: subscription,
});

export const createSubscriptionError = error => ({
  type: CREATE_OR_UPDATE_SUBSCRIPTION_ERROR,
  payload: error,
});

export const clearSubscriptionError = () => ({
  type: CLEAR_SUBSCRIPTION_ERROR,
});

export const deleteSubscription = () => ({
  type: DELETE_SUBSCRIPTION_START,
});

export const deleteSubscriptionSuccess = () => ({
  type: DELETE_SUBSCRIPTION_SUCCESS,
});
