import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { X } from 'react-feather';

// Assets
import { facebook, twitter, instagram, pinterest } from '../../../images';

// Utils
import { updateUser } from '../../../actions';

// Components
import Modal from '../../Modal';
import SocialMediaInput from '../../utils/SocialMediaInput';
import ModalContent from '../../styles/ModalContent';

// Styled Components
import Divider from '../../styles/Divider';
import theme from '../../styles/themes';

// *********************************************** //

const UpdateSocialProfilesModal = ({
  afterSave,
  cancelFunc,
  open,
  updateSocialUsernames,
  user,
}) => {
  const [facebookUsername, setFacebookUsername] = useState('');
  const [twitterUsername, setTwitterUsername] = useState('');
  const [instagramUsername, setInstagramUsername] = useState('');
  const [pinterestUsername, setPinterestUsername] = useState('');
  const [deleteFacebook, setDeleteFacebook] = useState(false);
  const [deleteTwitter, setDeleteTwitter] = useState(false);
  const [deleteInstagram, setDeleteInstagram] = useState(false);
  const [deletePinterest, setDeletePinterest] = useState(false);

  useEffect(() => {
    setFacebookUsername(user.facebookUsername ? user.facebookUsername : '');
    setTwitterUsername(user.twitterUsername ? user.twitterUsername : '');
    setInstagramUsername(user.instagramUsername ? user.instagramUsername : '');
    setPinterestUsername(user.pinterestUsername ? user.pinterestUsername : '');
  }, [user]);

  const clearDeletes = () => {
    setDeleteFacebook(false);
    setDeleteTwitter(false);
    setDeleteInstagram(false);
    setDeletePinterest(false);
  };

  const onClickSaveChanges = () => {
    updateSocialUsernames(
      facebookUsername,
      twitterUsername,
      instagramUsername,
      pinterestUsername,
      deleteFacebook,
      deleteInstagram,
      deleteTwitter,
      deletePinterest
    );
    afterSave();
    clearDeletes();
  };

  return (
    <Modal
      cancelFunc={() => {
        cancelFunc();
        clearDeletes();
      }}
      ctaFunc={onClickSaveChanges}
      open={open}
    >
      <h1 className="modal-title">Update Your Social Profiles</h1>
      <Divider />
      <ModalContent>
        {!deleteFacebook ? (
          <FlexWrapper>
            <SocialMediaInput
              icon={facebook}
              id="facebook"
              onChange={e => setFacebookUsername(e.target.value)}
              placeholder="Facebook Profile Address"
              value={facebookUsername}
            />
            {facebookUsername.length > 0 && (
              <RemoveButton onClick={() => setDeleteFacebook('true')}>
                <X />
              </RemoveButton>
            )}
          </FlexWrapper>
        ) : (
          <DeletedMessage>
            <p>Facebook profile removed.</p>
            <button type="button" onClick={() => setDeleteFacebook(false)}>
              Undo
            </button>
          </DeletedMessage>
        )}
        {!deleteTwitter ? (
          <FlexWrapper>
            <SocialMediaInput
              icon={twitter}
              id="twitter"
              onChange={e => setTwitterUsername(e.target.value)}
              placeholder="Twitter Profile Address"
              value={twitterUsername}
            />
            {twitterUsername.length > 0 && (
              <RemoveButton onClick={() => setDeleteTwitter('true')}>
                <X />
              </RemoveButton>
            )}
          </FlexWrapper>
        ) : (
          <DeletedMessage>
            <p>Twitter profile removed.</p>
            <button type="button" onClick={() => setDeleteTwitter(false)}>
              Undo
            </button>
          </DeletedMessage>
        )}
        {!deleteInstagram ? (
          <FlexWrapper>
            <SocialMediaInput
              icon={instagram}
              id="instagram"
              onChange={e => setInstagramUsername(e.target.value)}
              placeholder="Instagram Profile Address"
              value={instagramUsername}
            />
            {instagramUsername.length > 0 && (
              <RemoveButton onClick={() => setDeleteInstagram('true')}>
                <X />
              </RemoveButton>
            )}
          </FlexWrapper>
        ) : (
          <DeletedMessage>
            <p>Instagram profile removed.</p>
            <button type="button" onClick={() => setDeleteInstagram(false)}>
              Undo
            </button>
          </DeletedMessage>
        )}
        {!deletePinterest ? (
          <FlexWrapper>
            <SocialMediaInput
              icon={pinterest}
              id="pinterest"
              onChange={e => setPinterestUsername(e.target.value)}
              placeholder="Pinterest Profile Address"
              value={pinterestUsername}
            />
            {pinterestUsername.length > 0 && (
              <RemoveButton onClick={() => setDeletePinterest('true')}>
                <X />
              </RemoveButton>
            )}
          </FlexWrapper>
        ) : (
          <DeletedMessage>
            <p>Pinterest profile removed.</p>
            <button type="button" onClick={() => setDeletePinterest(false)}>
              Undo
            </button>
          </DeletedMessage>
        )}
      </ModalContent>
    </Modal>
  );
};

UpdateSocialProfilesModal.defaultProps = {
  afterSave: null,
};

UpdateSocialProfilesModal.propTypes = {
  afterSave: PropTypes.func,
  cancelFunc: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updateSocialUsernames: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = dispatch => ({
  updateSocialUsernames: (
    facebookUsername,
    twitterUsername,
    instagramUsername,
    pinterestUsername,
    deleteFacebook,
    deleteInstagram,
    deleteTwitter,
    deletePinterest
  ) =>
    dispatch(
      updateUser({
        facebookUsername,
        twitterUsername,
        instagramUsername,
        pinterestUsername,
        deleteFacebookUsername: deleteFacebook,
        deleteTwitterUsername: deleteTwitter,
        deleteInstagramUsername: deleteInstagram,
        deletePinterestUsername: deletePinterest,
      })
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateSocialProfilesModal);

// Styles
const { colors, fonts } = theme;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  :first-child {
    flex: 1;
    width: 100%;
  }
`;

const RemoveButton = styled.button`
  width: auto;
  margin: 0.5rem 0 0;
  padding: 0.5rem;
  color: ${colors.roles.danger};
  background: none;
  border: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${colors.roles.danger};
    cursor: pointer;
    opacity: 0.7;
  }

  span {
    margin-right: 0.5rem;
  }
`;

const DeletedMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;

  p {
    margin: 0;
    font-style: italic;
    text-align: left;
    color: ${colors.text.gray};
  }

  button {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-size: ${fonts.size.md};
    font-weight: ${fonts.weight.regular};
    color: ${colors.green};
    background: none;
    border: none;

    &:hover {
      text-decoration: underline;
      text-decoration-color: ${colors.green};
      cursor: pointer;
      opacity: 0.7;
    }
  }
`;
