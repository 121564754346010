import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import Modal from '../Modal';
import Divider from '../styles/Divider';
import LoadingIndicator from '../utils/LoadingIndicator';

const CatchModal = ({ open, ctaFunc, cancelFunc, referrer }) => {
  const [content, setContent] = useState({});
  useEffect(() => {
    const fetchHeaderImage = async () => {
      const {
        data: { signin, dashboard },
      } = await axios.get(process.env.REACT_APP_S3_URL);

      if (
        referrer &&
        !isEmpty(dashboard[referrer]) &&
        !isEmpty(signin[referrer])
      ) {
        setContent({
          image: signin[referrer].image,
          body: dashboard[referrer].body,
          title: dashboard[referrer].title,
        });
      }
    };

    fetchHeaderImage();
  }, [referrer]);

  return (
    <Modal
      buttonClassName="pro"
      open={open}
      ctaFunc={ctaFunc}
      ctaText="Tell Me More!"
      cancelFunc={cancelFunc}
      cancelText="Not Now"
      padding="2rem"
    >
      {!isEmpty(content) ? (
        <>
          <HeaderImage src={content.image} alt={content.title} />
          <Divider />
          <Body>{content.body}</Body>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </Modal>
  );
};

CatchModal.propTypes = {
  open: PropTypes.bool,
  ctaFunc: PropTypes.func.isRequired,
  cancelFunc: PropTypes.func.isRequired,
  referrer: PropTypes.string.isRequired,
};

CatchModal.defaultProps = {
  open: false,
};

export default CatchModal;

// Styles
const HeaderImage = styled.img`
  margin-bottom: 1.5rem;
`;

const Body = styled.p`
  margin: 1.5rem auto;
  font-weight: 200;
`;
