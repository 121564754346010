import {
  SET_USER_AUTHENTICATION,
  SIGN_IN_USER,
  USER_IS_LOADING,
  SET_USER,
  SIGN_IN_ERROR,
  UPDATE_USER,
  SET_USER_SITE_INFO,
  RESET_IN_PROGRESS_CHANGES,
  SUBMIT_NEW_USER_EVENT,
  SUBMIT_NEW_USER_EVENT_SUCCESS,
  DELETE_USER_EVENT,
  DELETE_USER_EVENT_SUCCESS,
  HYDRATE_USER,
  EDIT_USER_EVENT_SUCCESS,
  UPDATE_USER_ERROR,
  LOG_OUT,
  DELETE_USER_EVENT_LINK,
} from '../actiontypes';

export const hydrateUser = id => ({
  type: HYDRATE_USER,
  id,
});

export const userIsLoading = () => ({
  type: USER_IS_LOADING,
});

export const signInError = error => ({
  type: SIGN_IN_ERROR,
  error,
});

export const signInUser = body => ({
  type: SIGN_IN_USER,
  body,
});

export const setUserAuthentication = authenticated => ({
  type: SET_USER_AUTHENTICATION,
  authenticated,
});

export const logOut = () => ({
  type: LOG_OUT,
});

export const setUser = user => ({
  type: SET_USER,
  user,
});

export const setUserSiteInfo = changes => ({
  type: SET_USER_SITE_INFO,
  payload: changes,
});

export const updateUser = (user, cb = null) => ({
  type: UPDATE_USER,
  user,
  cb,
});

export const updateUserError = error => ({
  type: UPDATE_USER_ERROR,
  payload: error,
});

export const resetInProgressChange = () => ({
  type: RESET_IN_PROGRESS_CHANGES,
});

export const submitNewEvent = ({ body, edit }) => ({
  type: SUBMIT_NEW_USER_EVENT,
  body,
  edit,
});

export const deleteUserEventLink = id => ({
  type: DELETE_USER_EVENT_LINK,
  id,
});

export const addNewEventToState = event => ({
  type: SUBMIT_NEW_USER_EVENT_SUCCESS,
  event,
});

export const editUserEventSuccess = event => ({
  type: EDIT_USER_EVENT_SUCCESS,
  event,
});

export const deleteUserEvent = event => ({
  type: DELETE_USER_EVENT,
  event,
});

export const removeUserEventFromState = event => ({
  type: DELETE_USER_EVENT_SUCCESS,
  event,
});
