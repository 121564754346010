export const itemExists = key => {
  try {
    const item = localStorage.getItem(key);
    return [!!item, item];
  } catch (e) {
    return [false, null];
  }
};

export const getFromLocalStorage = key => {
  const [exists, item] = itemExists(key);
  if (exists) {
    return JSON.parse(item);
  }

  return undefined;
};

export const loadStateFromLocalStorage = (key = 'store') => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveStateToLocalStorage = ({ key = 'store', state }) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // Just ignoring errors for now
  }
};
