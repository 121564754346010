/**
 * @format
 */
import copy from './copy.svg';
import copyWhite from './copy-white.svg';
import download from './download.svg';
import downloadWhite from './download-white.svg';
import externalLink from './external-link.svg';
import externalLinkWhite from './external-link-white.svg';
import photo from './photo.svg';
import photoWhite from './photo-white.svg';
import resource from './resource.svg';
import video from './video.svg';
import videoWhite from './video-white.svg';

// *********************************************** //

const icons = {
  copy,
  copyWhite,
  download,
  downloadWhite,
  externalLink,
  externalLinkWhite,
  photo,
  photoWhite,
  resource,
  video,
  videoWhite,
};

const getIcon = (iconName, isWhite) => {
  let key = iconName;
  if (isWhite) key += 'White';
  return icons[key];
};

export default getIcon;
