import React, { useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { X } from 'react-feather';

// Components
import Resource from '../Resource';
import { MainContext } from '../../MainContext';

// Styles
import theme from '../styles/themes';

const { breakpoints, colors, shadows } = theme;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999;

  @media (max-width: ${breakpoints.mobile}) {
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: ${colors.white};
    z-index: 9999999;
    align-items: flex-start;
  }
`;

const Modal = styled.div`
  width: 100%;
  max-width: 768px;
  max-height: calc(100vh - 4rem);
  background: ${colors.white};
  border-radius: 4px;
  box-shadow: ${shadows.modal};
  overflow-y: scroll;
  margin: auto;

  .close-modal {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    max-width: 768px;
    width: 100%;
    margin-bottom: 1rem;
    background: ${colors.white};
    border-radius: 4px;

    button {
      padding: 1rem;
      background: ${colors.white};
      border: none;
      color: ${colors.text.gray};
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
    height: 100%;
    background: ${colors.white};
    z-index: 9999999;
    max-height: initial;
    box-shadow: none;
    max-width: initial;
  }
`;

const Content = styled.div`
  padding: 2rem;
  padding-top: 5rem;
`;

const Scrim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  cursor: pointer;
`;

const ViewResourceModal = ({
  closeModal,
  handleClickTag,
  open,
  slug,
  type,
}) => {
  const { dispatch } = useContext(MainContext);

  useEffect(() => {
    dispatch({ type: 'UPDATE_MODAL_IS_OPEN', payload: open });
  }, [open, dispatch]);

  // Pass this to resource to scroll the modal to top when a
  // new slug is set, showing a new resource
  const scrollWrapperRef = useRef();
  function scrollWrapperToTop() {
    scrollWrapperRef.current.scrollTop = 0;
  }

  return (
    open &&
    createPortal(
      <ModalContainer>
        <Scrim onClick={closeModal} />
        <Modal ref={scrollWrapperRef}>
          <div className="close-modal">
            <button type="button" onClick={closeModal}>
              <X />
            </button>
          </div>
          <Content>
            <Resource
              handleClickTag={handleClickTag}
              initialSlug={slug}
              type={type}
              scrollWrapperToTop={scrollWrapperToTop}
            />
          </Content>
        </Modal>
      </ModalContainer>,
      document.body
    )
  );
};

ViewResourceModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  slug: PropTypes.string,
  open: PropTypes.bool,
  type: PropTypes.string,
};

ViewResourceModal.defaultProps = {
  open: false,
  slug: '',
  type: 'resource',
};

export default ViewResourceModal;
