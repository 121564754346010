import styled from 'styled-components';
import theme from './themes';

const { breakpoints, colors } = theme;

// *********************************************** //

export const LargeTitle = styled.h1`
  margin: 0;
  font-family: freight-display-pro, serif;
  font-size: 4rem;
  font-weight: 900;
  line-height: 1.1em;
  color: ${colors.black};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: 3rem;
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-family: freight-display-pro, serif;
  font-size: 3.25rem;
  font-weight: 900;
  line-height: 1.1em;
  color: ${colors.black};
`;

export const Subtitle = styled.h3`
  margin: 0;
  font-family: sofia-pro, sans-serif;
  font-size: 1.5rem;
  font-weight: 200;
  line-height: 1.4;
  color: ${colors.darkGray};
`;

export const LargeSubtitle = styled(Subtitle)`
  font-size: 2rem;
`;

export const BodyText = styled.p`
  margin: 0;
  font-family: sofia-pro, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4;
  color: ${colors.darkGray};
`;

export const PageSubtitle = styled(Subtitle)`
  max-width: 800px;
  margin: 1rem auto;
`;
