import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { X } from 'react-feather';
import theme from '../styles/themes';
import { Button } from '../styles/Buttons';

const MenuDrawer = ({
  closeDrawer,
  logout,
  open,
  showUpgrade,
  hasSubscription,
}) => (
  <>
    <Drawer className={open && 'show'}>
      <button type="button" className="close" onClick={closeDrawer}>
        <X />
      </button>
      <h2>Member Dashboard</h2>
      <ul>
        <li>
          <StyledLink to="/" onClick={closeDrawer}>
            Home
          </StyledLink>
        </li>
        <li>
          <StyledLink to="/resources" onClick={closeDrawer}>
            Resources
          </StyledLink>
        </li>
      </ul>
      <div className="divider" />
      <h4>MY ACCOUNT</h4>
      <ul className="trim-top">
        <li>
          <StyledLink to="/account/profile" onClick={closeDrawer}>
            Manage Profile
          </StyledLink>
        </li>
        <li>
          <StyledLink to="/account/subscriptions" onClick={closeDrawer}>
            Manage Subscriptions
          </StyledLink>
        </li>
        <li className="trim-bottom">
          <StyledLink to="/account/site" onClick={closeDrawer}>
            Manage Website
          </StyledLink>
        </li>
        <li>
          <button type="button" onClick={() => logout()}>
            Logout
          </button>
        </li>
      </ul>
      <div className="divider" />
      {!hasSubscription && (
        <Button
          className="upgrade"
          onClick={() => {
            showUpgrade();
            closeDrawer();
          }}
        >
          Upgrade to Pro
        </Button>
      )}
    </Drawer>
    <Scrim onClick={closeDrawer} className={open && 'show'} />
  </>
);

MenuDrawer.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  open: PropTypes.bool,
  showUpgrade: PropTypes.func.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
};

MenuDrawer.defaultProps = {
  open: false,
};

export default MenuDrawer;

// Styles
const { breakpoints, colors, fonts } = theme;

const Drawer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 5rem 2rem 0 2rem;
  background: ${colors.white};
  z-index: 100;
  transform: translateX(-100%);
  transition: all 150ms ease-in-out 50ms;

  &.show {
    box-shadow: 4px 0 20px rgba(0, 0, 0, 0.15);
    transform: translateX(0);
  }

  h2 {
    margin: 0 1rem 1rem 0;
    font-family: freight-disp-pro, serif;
  }

  h4 {
    margin: 0 0 1rem;
    font-size: ${fonts.size.s};
  }

  ul {
    margin: 1.5rem 0;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 1rem;

      &.trim-bottom {
        margin-bottom: 0.75rem;
      }
    }

    &.trim-top {
      margin-top: 0;
    }

    button {
      padding: 0;
      font-size: ${fonts.size.md};
      font-weight: ${fonts.weight.light};
      background: ${colors.white};
      color: ${colors.green};
      border: none;
      outline: none;

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    margin-bottom: 1.5rem;
    background: ${colors.dividerGray};
  }

  button.close {
    position: fixed;
    top: 8px;
    left: 16px;
    padding: 1rem;
    background: none;
    border: none;
    cursor: pointer;
  }

  button.upgrade {
    width: 100%;
    margin-top: 1.5rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 100vw;
    height: 100vh;
    text-align: center;
    padding: 5rem 2rem 2rem;
    overflow-y: scroll;
    font-size: ${fonts.size.lg};

    button.close {
      top: 0;
      left: 0;
    }

    ul {
      margin: 2rem 0;

      li {
        padding: 0.25rem 0;
      }
    }

    .mobile-only {
      display: inline-block;
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.green};

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Scrim = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);

  &.show {
    display: block;
  }
`;
