import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import ButtonWithIcon from '../../ButtonWithIcon';

// *********************************************** //

const CopyButton = ({ isInverted, label, url, isPro }) => {
  const [buttonText, setButtonText] = useState(label);

  const copyText = () => {
    const el = document.createElement('textarea');

    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setButtonText('Link Copied!');
    setTimeout(() => setButtonText(label), 5000);
  };

  return (
    isPro && (
      <ButtonWithIcon
        buttonText={buttonText}
        handleClick={copyText}
        icon="copy"
        isInverted={isInverted}
      />
    )
  );
};

CopyButton.propTypes = {
  isInverted: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isPro: PropTypes.bool,
};

CopyButton.defaultProps = {
  isPro: false,
};

export default CopyButton;
