import styled from 'styled-components/macro';

import theme from '../../../styles/themes';

const { colors, fonts } = theme;

export const CreditCardStyles = styled.div`
  display: flex;
  border: 2px solid
    ${props => (props.selected ? colors.plum : colors.lightGray)};
  border-radius: 10px;
  padding: 1rem;
  font-family: ${fonts.family.sans};
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const CardInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fonts.size.s};
  font-weight: ${fonts.weight.medium};

  .card-icon {
    max-height: 1.5rem;
    margin-left: 1rem;
  }

  .card-number {
    margin-left: 1rem;
  }
`;

export const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;
