import filter from 'lodash/filter';
import concat from 'lodash/concat';
import find from 'lodash/find';
import without from 'lodash/without';

import {
  SET_USER_AUTHENTICATION,
  USER_IS_LOADING,
  SET_USER,
  SIGN_IN_ERROR,
  SET_USER_SITE_INFO,
  RESET_IN_PROGRESS_CHANGES,
  SUBMIT_NEW_USER_EVENT_SUCCESS,
  DELETE_USER_EVENT_SUCCESS,
  EDIT_USER_EVENT_SUCCESS,
  UPDATE_USER_ERROR,
  LOG_OUT,
} from '../actiontypes';
import { getFromLocalStorage } from '../modules/localStorage';

const initialState = {
  authenticated: !!getFromLocalStorage('accessToken'),
  error: null,
  hasSubscription: false,
  infoVerified: true,
  isLoading: false,
  profileImage: {
    full: null,
    thumb: null,
    square: null,
  },
  onBabyStepsTeam: false,
  replacements: {},
  siteSetupComplete: true,
  facebookUsername: '',
  twitterUsername: '',
  instagramUsername: '',
  pinterestUsername: '',
  websiteSubdomain: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case SIGN_IN_ERROR:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    case SET_USER:
      return {
        ...state,
        ...action.user,
        error: null,
        isLoading: false,
      };

    case UPDATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case SET_USER_AUTHENTICATION:
      return {
        ...state,
        authenticated: action.authenticated,
        isLoading: false,
      };

    case LOG_OUT:
      return {
        ...state,
        authenticated: false,
        accessToken: null,
        isLoading: false,
      };

    case SET_USER_SITE_INFO:
      return {
        ...state,
        siteInfoChanges: {
          ...state.siteInfoChanges,
          ...action.payload,
        },
      };

    case RESET_IN_PROGRESS_CHANGES:
      return {
        ...state,
        siteInfoChanges: {
          website_subdomain: state.website_subdomain,
          bio: state.bio,
          facebook_username: state.facebook_username,
          twitter_username: state.twitter_username,
          instagram_username: state.instagram_username,
          pinterest_username: state.pinterest_username,
        },
      };

    case SUBMIT_NEW_USER_EVENT_SUCCESS:
      return {
        ...state,
        user_events: [...state.user_events, action.event],
      };

    case EDIT_USER_EVENT_SUCCESS: {
      const oldEvent = find(state.user_events, ev => ev.id === action.event.id);
      const arrayWithoutOldEvent = without(state.user_events, oldEvent);

      return {
        ...state,
        user_events: concat(arrayWithoutOldEvent, action.event),
      };
    }

    case DELETE_USER_EVENT_SUCCESS:
      return {
        ...state,
        user_events: filter(state.user_events, ev => ev.id !== action.event),
      };

    default: {
      return state;
    }
  }
};

export default userReducer;
