import {
  FETCH_SUBSCRIPTION_START,
  FETCH_SUBSCRIPTION_SUCCESS,
  CREATE_OR_UPDATE_SUBSCRIPTION_START,
  CREATE_OR_UPDATE_SUBSCRIPTION_ERROR,
  CREATE_OR_UPDATE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_START,
  DELETE_SUBSCRIPTION_SUCCESS,
  CREATE_GIFT_SUBSCRIPTION_SUCCESS,
  CLEAR_SUBSCRIPTION_ERROR,
} from '../actiontypes';

const initialState = {
  error: null,
  giftSub: [],
  isLoading: false,
  userSub: {},
  appSubs: {},
};

const subscriptionsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SUBSCRIPTION_START:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        userSub: payload.userSub,
        appSubs: payload.appSubs,
        giftSubs: payload.giftSubs,
        isLoading: false,
      };

    case CREATE_OR_UPDATE_SUBSCRIPTION_START:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_OR_UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userSub: payload,
      };

    case CREATE_OR_UPDATE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case CREATE_GIFT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        gifts: [...state.giftSubs, payload],
        isLoading: false,
      };

    case CLEAR_SUBSCRIPTION_ERROR:
      return {
        ...state,
        error: null,
      };

    case DELETE_SUBSCRIPTION_START:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userSub: {
          ...state.userSub,
          autoRenew: false,
        },
      };

    default:
      return state;
  }
};

export default subscriptionsReducer;
