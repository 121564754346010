import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

// Components
import ButtonWithIcon from '../../ButtonWithIcon';
import ImageViewer from '../ImageViewer';

// Styled Components
import theme from '../../styles/themes';
import { ImageButton } from '../../styles/Buttons';

// Theme
const { colors } = theme;

// Styles
const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  img.thumbnail {
    width: 100%;
    max-height: ${props => (props.fullsize ? '100%' : '200px')};
    object-fit: cover;
    object-position: center;
  }

  .button-wrapper {
    padding: 1rem 0 2rem;
    border-bottom: 1px solid ${colors.lightGray};
  }
`;

// *********************************************** //

const ImageThumbnail = ({
  fullsize,
  fullSrc,
  isDownloadable,
  largeSrc,
  thumbSrc,
}) => {
  const [showImageViewer, setShowImageViewer] = useState(false);

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = fullSrc;
    link.download = 'image';
    link.target = '_blank';
    link.style.display = 'none';
    const evt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    document.body.appendChild(link);
    link.dispatchEvent(evt);
    document.body.removeChild(link);
  };

  return (
    <>
      <Wrapper fullsize={fullsize}>
        <ImageButton onClick={() => setShowImageViewer(true)}>
          <img src={thumbSrc} alt="Tap to view" className="thumbnail" />
        </ImageButton>
        {isDownloadable && (
          <div className="button-wrapper">
            <ButtonWithIcon
              buttonText="Download Image"
              icon="download"
              onClick={downloadImage}
            />
          </div>
        )}
      </Wrapper>
      {showImageViewer &&
        ReactDOM.createPortal(
          <ImageViewer
            imgSrc={largeSrc}
            handleClose={() => setShowImageViewer(false)}
            handleDownload={isDownloadable ? downloadImage : null}
          />,
          document.body
        )}
    </>
  );
};

ImageThumbnail.propTypes = {
  fullsize: PropTypes.bool,
  fullSrc: PropTypes.string.isRequired,
  isDownloadable: PropTypes.bool,
  largeSrc: PropTypes.string.isRequired,
  thumbSrc: PropTypes.string.isRequired,
};

ImageThumbnail.defaultProps = {
  fullsize: false,
  isDownloadable: false,
};

export default ImageThumbnail;
