import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

// Styled Components
import { CheckSquare } from 'react-feather';
import theme from '../../styles/themes';

const { colors } = theme;

// Styles
const FeatureItemStyles = styled.div`
  display: flex;
  margin-bottom: 1rem;

  .checkbox {
    padding-right: 1rem;
  }

  .feature-details {
    flex: 1;
    text-align: left;
    line-height: 1.4;

    .feature-title {
      font-size: 1.125rem;
      font-weight: ${props => props.theme.fonts.weight.demi};
    }

    .feature-description {
      font-size: 0.875rem;
      font-weight: ${props => props.theme.fonts.weight.light};
      color: ${props => props.theme.colors.darkGray};
    }
  }
`;

// *********************************************** //

const FeatureItem = ({ item }) => {
  const { title, description } = item;
  return (
    <FeatureItemStyles>
      <div className="checkbox">
        <CheckSquare color={colors.plum} />
      </div>
      <div className="feature-details">
        <div className="feature-title">{title}</div>
        <div className="feature-description">{description}</div>
      </div>
    </FeatureItemStyles>
  );
};

FeatureItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default FeatureItem;
