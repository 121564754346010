import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Search } from 'react-feather';
import theme from '../styles/themes';

const SearchBar = ({ onChange, placeholder, value }) => {
  return (
    <Container>
      <Search />
      <input
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </Container>
  );
};

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

SearchBar.defaultProps = {
  placeholder: '',
  value: '',
};

// Styles
const { colors, fonts, shadows } = theme;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0 1rem;
  background-color: ${colors.white};
  box-shadow: ${shadows.light};
  border-radius: 4px;

  svg {
    width: 16px;
    height: auto;
    margin-right: 0.5rem;
    color: ${colors.green};
  }

  input {
    display: block;
    width: 100%;
    background-clip: padding-box;

    &[type='search'],
    &[type='text'] {
      padding: 0.75rem 0;
      font-size: ${fonts.size.s};
      font-weight: ${fonts.weight.regular};
      font-style: normal;
      line-height: 0.875rem;
      background-color: ${colors.white};
      color: ${colors.text.gray};
      border: none;
      border-radius: 4px;
      -webkit-appearance: none;
    }

    &[type='search']:focus,
    &[type='text']:focus {
      color: ${colors.black};
      outline: 0;
      box-shadow: none;
    }

    &[type='search']::-webkit-input-placeholder,
    &[type='text']::-webkit-input-placeholder,
    &[type='search']::-webkit-input-placeholder,
    &[type='text']::-webkit-input-placeholder {
      color: ${colors.darkGray};
      font-style: normal;
      font-weight: ${fonts.weight.light};
    }

    &[type='search']::-moz-placeholder,
    &[type='text']::-moz-placeholder,
    &[type='search']::-moz-placeholder,
    &[type='text']::-moz-placeholder {
      opacity: 1;
      color: $base-gray;
      font-style: normal;
    }
  }
`;

export default SearchBar;
